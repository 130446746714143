var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "interaction-container" }, [
    _c("div", { staticClass: "video-content" }, [
      _c("div", { staticClass: "content remote-video" }, [
        _c("div", { staticClass: "avatar-img" }, [
          _c("img", { attrs: { src: this.teacherAvatar, alt: "" } }),
        ]),
        _c("div", { staticClass: "name-text" }, [
          _vm._v(_vm._s(this.teacherName)),
        ]),
      ]),
      _c("div", { staticClass: "content local-video" }, [
        _c("div", { staticClass: "avatar-img" }, [
          _c("img", { attrs: { src: this.handleImag, alt: "" } }),
        ]),
        _c("div", { staticClass: "name-text" }, [
          _vm._v(_vm._s(this.userInfo.userSetting.nickname)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }