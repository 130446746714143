var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "heartbeat_animation hover-opacity-soft dialog-bubble-speech",
        class: {
          size_small: _vm.size == "small",
          size_medium: _vm.size == "medium",
          size_large: _vm.size == "large",
        },
        on: {
          click: function ($event) {
            return _vm.clickBubble()
          },
        },
      },
      [
        _c("img", {
          staticClass: "dialog-bubble__img",
          attrs: {
            src: require("@/assets/img/16-GAMES/G01-hi/bubble-" +
              (_vm.direction == "right" ? "right-" : "") +
              "red-" +
              _vm.size +
              ".svg"),
            alt: "",
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "\n         conter-picture-left-word\n         hover-opacity-soft\n         dialog-bubble-speech__content\n       ",
          },
          [
            Array.isArray(_vm.question) && _vm.question.length > 0
              ? _c(
                  "span",
                  {
                    staticClass: "question question_multiple",
                    class: { isHidden: _vm.showBack },
                  },
                  _vm._l(_vm.question, function (item, index) {
                    return _c(
                      "span",
                      { key: index, staticClass: "wordWrapper" },
                      [
                        item.type !== "newline"
                          ? _c(
                              "span",
                              { staticClass: "txt-py font-pinyin-medium" },
                              [_vm._v(_vm._s(item.py))]
                            )
                          : _vm._e(),
                        item.type !== "newline"
                          ? _c(
                              "span",
                              { staticClass: "txt-hz font-hanzi-medium" },
                              [_vm._v(_vm._s(item.hz))]
                            )
                          : _vm._e(),
                        item.type == "newline"
                          ? _c("span", { staticClass: "newline" }, [_c("br")])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : !Array.isArray(_vm.question)
              ? _c(
                  "span",
                  {
                    staticClass:
                      "wordWrapper question question_single font-hanzi-medium",
                    class: { isHidden: _vm.showBack },
                  },
                  [_vm._v(" " + _vm._s(_vm.question) + " ")]
                )
              : _vm._e(),
            _c(
              "span",
              {
                ref: "answerSel",
                staticClass: "fadein answer font-face",
                class: { isHidden: !_vm.showBack },
              },
              _vm._l(_vm.answer, function (item, index) {
                return _c("span", { key: index, staticClass: "wordWrapper" }, [
                  item.type !== "newline"
                    ? _c("span", { staticClass: "txt-py font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.py)),
                      ])
                    : _vm._e(),
                  item.type !== "newline"
                    ? _c("span", { staticClass: "txt-hz font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hz)),
                      ])
                    : _vm._e(),
                  item.type == "newline"
                    ? _c("span", { staticClass: "newline" }, [_c("br")])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }