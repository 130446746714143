var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-item-container" },
    [
      _c("div", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm.formType === 1
        ? _c("el-input", {
            on: { input: _vm.handleInputChange },
            model: {
              value: _vm.simpleValue,
              callback: function ($$v) {
                _vm.simpleValue = $$v
              },
              expression: "simpleValue",
            },
          })
        : _vm._e(),
      _vm.formType === 2 || _vm.formType === 3
        ? _c(
            "div",
            { staticClass: "upload-item" },
            [
              _vm.formData.value
                ? _c("span", { staticClass: "file-name" }, [
                    _vm.formType === 3
                      ? _c("img", {
                          attrs: {
                            src: _vm.formData.value.includes("assets")
                              ? require("@/" + _vm.formData.value)
                              : _vm.formData.value,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm.formType === 2
                      ? _c("img", {
                          staticClass: "audio-icon",
                          attrs: { src: _vm.audioImg, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAudioClick(_vm.formData.value)
                            },
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "",
                    accept: _vm.formType === 2 ? "audio/*" : "image/*",
                    "http-request": _vm.handleUploadPicture,
                    "show-file-list": false,
                  },
                },
                [_c("span", { staticClass: "upload-btn" }, [_vm._v("上传")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formType === 4
        ? _c(
            "el-radio-group",
            {
              on: { input: _vm.handleInputChange },
              model: {
                value: _vm.simpleValue,
                callback: function ($$v) {
                  _vm.simpleValue = $$v
                },
                expression: "simpleValue",
              },
            },
            _vm._l(_vm.formData.options, function (item, index) {
              return _c(
                "el-radio",
                { key: index, attrs: { label: item.value } },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.formType === 5
        ? _c(
            "div",
            { staticClass: "syllabus-area" },
            [
              _c("div", { staticClass: "add-new" }, [
                _c("i", {
                  staticClass: "el-icon-circle-plus-outline",
                  on: {
                    click: function ($event) {
                      return _vm.handleAddNewSyllabusItem(null)
                    },
                  },
                }),
              ]),
              _vm._l(_vm.formData.value, function (item, index) {
                return _c(
                  "div",
                  { key: index + "level", staticClass: "syllabus-item" },
                  [
                    _vm.formData.value && _vm.formData.value.length > 1
                      ? _c("div", { staticClass: "delete-icon" }, [
                          _c("i", {
                            staticClass: "el-icon-circle-close",
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteNew(index)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._l(item, function (ele, indexSon) {
                      return _c("FormItem", {
                        key: indexSon + "son",
                        attrs: {
                          label: ele.label,
                          formType: ele.type,
                          formData: ele,
                          lessonType: _vm.lessonType,
                          selectIndex: index,
                        },
                        on: {
                          valueChangeEvent: _vm.handleValueChange,
                          getPinyinEvent: _vm.handleGetPinyin,
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.formType === 6
        ? _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: { change: _vm.handleInputChange },
              model: {
                value: _vm.simpleValue,
                callback: function ($$v) {
                  _vm.simpleValue = $$v
                },
                expression: "simpleValue",
              },
            },
            _vm._l(
              _vm.formData.options.filter(function (e) {
                return e.type === _vm.lessonType
              }),
              function (item) {
                return _c("el-option", {
                  key: item.type + "" + item.value,
                  attrs: { label: item.value, value: item.value },
                })
              }
            ),
            1
          )
        : _vm._e(),
      _vm.formType === 60
        ? _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: { change: _vm.handleInputChange },
              model: {
                value: _vm.simpleValue,
                callback: function ($$v) {
                  _vm.simpleValue = $$v
                },
                expression: "simpleValue",
              },
            },
            _vm._l(_vm.formData.options, function (item) {
              return _c("el-option", {
                key: item.type + "" + item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.formType === 61
        ? _c(
            "el-select",
            {
              attrs: {
                placeholder: "请选择",
                multiple: "",
                filterable: "",
                "allow-create": "",
                "default-first-option": "",
              },
              on: { change: _vm.handleInputChange },
              model: {
                value: _vm.simpleValue,
                callback: function ($$v) {
                  _vm.simpleValue = $$v
                },
                expression: "simpleValue",
              },
            },
            _vm._l(
              _vm.formData.options.filter(function (e) {
                return e.type === _vm.lessonType
              }),
              function (item) {
                return _c("el-option", {
                  key: item.type + "" + item.value,
                  attrs: { label: item.value, value: item.value },
                })
              }
            ),
            1
          )
        : _vm._e(),
      _vm.formType === 62
        ? _c(
            "el-select",
            {
              attrs: {
                placeholder: "请选择",
                multiple: "",
                filterable: "",
                "default-first-option": "",
              },
              on: { change: _vm.handleInputChange },
              model: {
                value: _vm.simpleValue,
                callback: function ($$v) {
                  _vm.simpleValue = $$v
                },
                expression: "simpleValue",
              },
            },
            _vm._l(_vm.formData.options, function (item) {
              return _c("el-option", {
                key: item.type + "" + item.value,
                attrs: { label: item.value, value: item.value },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.formType === 7
        ? _c("div", { staticClass: "syllabus" }, [
            _c(
              "div",
              { staticClass: "get-pinyin-area" },
              [
                _c("el-input", {
                  on: { change: _vm.getGetPinyin },
                  model: {
                    value: _vm.formData.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "value", $$v)
                    },
                    expression: "formData.value",
                  },
                }),
                _c(
                  "span",
                  { staticClass: "btn", on: { click: _vm.getGetPinyin } },
                  [_vm._v("分词")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "word-box" },
              _vm._l(_vm.formData.options, function (item, index) {
                return _c("div", { key: index, staticClass: "word-item" }, [
                  _c("div", { staticClass: "pinyin" }, [
                    _vm._v(" " + _vm._s(item.wordPy) + " "),
                  ]),
                  _c("div", { staticClass: "hanzi" }, [
                    _vm._v(" " + _vm._s(item.wordZh) + " "),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.formType === 8
        ? _c(
            "div",
            { staticClass: "upload-item" },
            [
              _vm.formData.value && _vm.formData.value.length
                ? _c(
                    "div",
                    { staticClass: "img-list" },
                    _vm._l(_vm.formData.value, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "img-item" },
                        [
                          item.value
                            ? _c("img", {
                                attrs: {
                                  src: item.value.includes("assets")
                                    ? require("@/" + item.value)
                                    : item.value,
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(item.label))]),
                          _c("i", {
                            staticClass: "el-icon-error",
                            on: {
                              click: function ($event) {
                                return _vm.handleDeletePicture(index)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "",
                    accept: "image/*",
                    multiple: "",
                    "http-request": _vm.handleUploadMorePicture,
                    "show-file-list": false,
                  },
                },
                [_c("span", { staticClass: "upload-btn" }, [_vm._v("上传")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formType === 9
        ? _c(
            "div",
            { staticClass: "syllabus-area" },
            [
              _c("div", { staticClass: "add-new" }, [
                _vm.formData.fieldName !== "btnContentList"
                  ? _c("i", {
                      staticClass: "el-icon-circle-plus-outline",
                      on: {
                        click: function ($event) {
                          return _vm.handleAddNewSyllabusItem(null)
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._l(_vm.formData.value, function (item, index) {
                return _c(
                  "div",
                  { key: index + "level", staticClass: "syllabus-item" },
                  [
                    _vm.formData.value && _vm.formData.value.length > 1
                      ? _c("div", { staticClass: "delete-icon" }, [
                          _c("i", {
                            staticClass: "el-icon-circle-close",
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteNew(index)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._l(item, function (ele, indexSon) {
                      return _c("FormItem", {
                        key: indexSon + "son",
                        attrs: {
                          label: ele.label,
                          formType: ele.type,
                          formData: ele,
                          lessonType: _vm.lessonType,
                          selectIndex: index,
                        },
                        on: {
                          valueChangeEvent: function (e) {
                            return _vm.handleDialogChange(e, _vm.selectIndex)
                          },
                          dialogChangeEvent: _vm.handleDialogChange,
                          uploadMorePicture: _vm.handleUploadMoreImage,
                          getPinyinEvent: _vm.handleGetPinyin,
                          deletePicture: _vm.handleDeleteMirrorPicture,
                          addNewSyllabusItemEvent: function ($event) {
                            return _vm.handleAddNewSyllabusItem(
                              index,
                              _vm.formData
                            )
                          },
                          deleteItemEvent: function (e) {
                            return _vm.handleDeleteNew(e, index)
                          },
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c("AudioPlayer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { isPlay: _vm.isStartPlaying, audioUrl: _vm.songUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }