var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c(
        "div",
        { staticClass: "mainCont" },
        [
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(_vm._s(_vm.titleInfo.hanzi)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.totalStars,
              currentIndex: _vm.answersfound,
            },
          }),
          _c(
            "div",
            {
              staticClass: "background",
              class: { backgroundBlue: _vm.background == "blue" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "game-content",
                  class: { "number-bg-color": !_vm.isNumberGame },
                },
                [
                  _c("div", { staticClass: "game-left-box" }, [
                    _c(
                      "div",
                      { staticClass: "game-aside-content" },
                      _vm._l(
                        _vm.asideElementInfo.leftList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item-content",
                              class: {
                                "item-content-number": _vm.isNumberGame,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item.id)
                                },
                              },
                            },
                            [
                              item.pinyin
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-pinyin-medium pinyin",
                                    },
                                    [_vm._v(_vm._s(item.pinyin))]
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                { staticClass: "font-hanzi-medium hanzi" },
                                [_vm._v(_vm._s(item.element))]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                  _vm._l(_vm.questionInfoList, function (item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.question === index,
                            expression: "question === index",
                          },
                        ],
                        key: index + "question",
                        staticClass: "game-center-box",
                      },
                      [
                        _c("img", {
                          staticClass: "objectDisplay fadeIn",
                          attrs: { src: item.bgImg },
                        }),
                        _vm.isNumberGame
                          ? _c("div", { staticClass: "number-area" }, [
                              _c(
                                "span",
                                { staticClass: "font-pinyin-large pinyin" },
                                [_vm._v(_vm._s(item.pinyin))]
                              ),
                              _c(
                                "span",
                                { staticClass: "font-hanzi-large hanzi" },
                                [_vm._v(_vm._s(item.hanzi))]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.lessonId !== 59,
                                expression: "lessonId !== 59",
                              },
                            ],
                            staticClass: "speaker-wrapper",
                            on: { click: _vm.playSoundWord },
                          },
                          [
                            _c("AudioPlayer", {
                              attrs: {
                                isPlay: _vm.isStartPlaying,
                                audioUrl: _vm.songUrl,
                                showAudioElement: index > 0 ? false : true,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c("div", { staticClass: "game-right-box" }, [
                    _c(
                      "div",
                      { staticClass: "game-aside-content" },
                      _vm._l(
                        _vm.asideElementInfo.rightList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item-content",
                              class: {
                                "item-content-number": _vm.isNumberGame,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item.id)
                                },
                              },
                            },
                            [
                              item.pinyin
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "font-pinyin-medium pinyin",
                                    },
                                    [_vm._v(_vm._s(item.pinyin))]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "font-hanzi-medium" }, [
                                _vm._v(_vm._s(item.element)),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }