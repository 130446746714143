var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-editor-container customScrollbar" },
    [
      _vm._l(
        _vm.curPageInfo.contents.filter(function (e) {
          return e.type
        }),
        function (item, index) {
          return _c("FormItem", {
            key: index,
            attrs: {
              label: item.label,
              formType: item.type,
              formData: item,
              lessonType: _vm.lessonType,
            },
            on: {
              valueChangeEvent: _vm.handleValueChange,
              selectChangeEvent: _vm.handleSyllabusSelcetChange,
              dialogChangeEvent: _vm.handleDialogChange,
              getPinyinChangeEvent: _vm.handleGetPinyinChange,
              addNewSyllabusItemEvent: _vm.handleAddNewItem,
              deleteItemEvent: _vm.handleDeleteItem,
              uploadMorePicture: _vm.handleUploadMorePicture,
              deletePicture: _vm.handleDeletePicture,
              uploadMirrorMorePicture: _vm.handleMirrorUploadMorePicture,
              deleteMirrorPicture: _vm.handleDeleteMirrorPicture,
            },
          })
        }
      ),
      _vm.curPageInfo.id
        ? _c("div", { staticClass: "save-btn" }, [
            _c(
              "span",
              {
                staticClass: "btn save",
                on: {
                  click: function ($event) {
                    return _vm.$emit("saveCoursewareEvent")
                  },
                },
              },
              [_vm._v("SAVE")]
            ),
            _c(
              "span",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancelEditAddEvent")
                  },
                },
              },
              [_vm._v("CANCEL")]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }