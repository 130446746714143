var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainCont position-relative" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _vm.showStar
        ? _c("Star", {
            attrs: {
              totalStarNumber: _vm.strokeImgList.length,
              currentIndex: _vm.fnum,
            },
          })
        : _vm._e(),
      _vm.title
        ? _c("div", { staticClass: "right-text" }, [
            _c(
              "span",
              { staticClass: "font-text pinyin txt-py font-pinyin-medium" },
              [_vm._v(_vm._s(_vm.title.pinyin))]
            ),
            _c("span", { staticClass: "txt-hz font-hanzi-medium" }, [
              _vm._v(_vm._s(_vm.title.hanzi)),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "background",
          class: [
            "game-" + _vm.gameIndex,
            (_vm.backgroundColor = "blue" ? "blueBackground" : ""),
          ],
        },
        [
          _c("div", { staticClass: "backgroundImg_n_Clicks" }, [
            _c(
              "div",
              {
                staticStyle: { position: "relative" },
                style: [
                  _vm.backgroundImgIsFullWidth == false
                    ? { height: "100%" }
                    : "",
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "strokesFoundBoxes" },
                  _vm._l(_vm.strokeImgList.length, function (item, index) {
                    return _c(
                      "div",
                      { key: index + "box", staticClass: "outerBox" },
                      [
                        _vm.answersfound > index && _vm.strockNotFoundImage
                          ? _c("div", { staticClass: "innerBoxFound" }, [
                              _c("img", {
                                staticClass: "strokesFoundimage",
                                attrs: { src: _vm.strockFoundImage },
                              }),
                              index === 0
                                ? _c("div", { staticClass: "star" }, [
                                    _c("img", {
                                      class: { animatedstar: _vm.answersfound },
                                      attrs: {
                                        src: require("@/assets/img/07-Stars/star-big.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.answersfound <= index && _vm.strockNotFoundImage
                          ? _c("div", { staticClass: "innerBox" }, [
                              _c("img", {
                                staticClass: "strokesFoundimage",
                                attrs: { src: _vm.strockNotFoundImage },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _c("img", {
                  attrs: { id: "puzzlebackground", src: _vm.bgImg },
                }),
                _vm._l(_vm.strokeImgList.length, function (item, index) {
                  return _c("div", {
                    key: index + "ref",
                    ref: "S" + item,
                    refInFor: true,
                    staticClass: "stroke",
                    class: "stroke" + item,
                    on: {
                      click: function ($event) {
                        return _vm.foundAnswer("S" + item)
                      },
                    },
                  })
                }),
                _vm._l(_vm.storkeNotFindList, function (item, index) {
                  return _c(
                    "div",
                    { key: index + "stroke", staticClass: "images" },
                    [
                      _c("img", {
                        staticClass: "imageFound 3",
                        class: "image" + index,
                        style: {
                          zIndex: 10 + _vm.imageFoundOrderHistory[index],
                        },
                        attrs: { src: item },
                      }),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }