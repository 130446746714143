var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-stroke-page-container layout-border" },
    [
      _c(
        "div",
        { staticClass: "stroke-page-box" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: {
              clickNext: _vm.nextPage,
              updateNumbers: function ($event) {
                return _vm.updateNumbers($event)
              },
            },
            nativeOn: {
              click: function ($event) {
                return _vm.startWritingStroke.apply(null, arguments)
              },
            },
          }),
          _vm.isWritePage
            ? _c(
                "div",
                {
                  staticClass: "write-stroke-page-eye-btn",
                  on: {
                    mouseover: function ($event) {
                      _vm.eyeIsHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.eyeIsHover = false
                    },
                    click: _vm.handleClickEyeButton,
                  },
                },
                [
                  !_vm.eyeIsHover
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"),
                          alt: "",
                        },
                      }),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "white-stroke-content" }, [
            _c("div", { staticClass: "white-stroke-content-left" }, [
              _c("div", { staticClass: "left-content-border" }, [
                _c("div", { staticClass: "left-content" }, [
                  _c(
                    "div",
                    { staticClass: "left-content-top" },
                    _vm._l(3, function (item, index) {
                      return _c("img", {
                        key: index + "flag",
                        attrs: {
                          src:
                            _vm.steps < 3 - index
                              ? _vm.flagImageObj.img
                              : _vm.flagImageObj.imgActive,
                          alt: "",
                        },
                      })
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "stroke-area",
                      on: {
                        mousedown: function ($event) {
                          return _vm.startDrawing()
                        },
                        mouseup: function ($event) {
                          return _vm.endDrawing($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "hanzi-img" },
                        [
                          _c("KidsWhiteBoard", { ref: "drawArea" }),
                          _vm.drawing
                            ? _c(
                                "div",
                                {
                                  staticClass: "WritingStrokeArea",
                                  on: { click: _vm.draggedOutOfStroke },
                                },
                                [
                                  _vm.isShu
                                    ? _c(
                                        "div",
                                        { staticClass: "shuDrawingGreenArea" },
                                        [
                                          _c("div", {
                                            staticClass: "checkPoint1",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.hitCurrentCheckpoint(
                                                  0
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.finishedCurrentCheckpoint(
                                                  0
                                                )
                                              },
                                            },
                                          }),
                                          _c("div", {
                                            staticClass: "checkPoint2",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.hitCurrentCheckpoint(
                                                  1
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.finishedCurrentCheckpoint(
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                          _c("div", {
                                            staticClass: "checkPoint3",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.hitCurrentCheckpoint(
                                                  2
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.hitRedArea()
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "hengDrawingGreenArea" },
                                        [
                                          _c("div", {
                                            staticClass: "checkPoint1",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.hitCurrentCheckpoint(
                                                  0
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.finishedCurrentCheckpoint(
                                                  0
                                                )
                                              },
                                            },
                                          }),
                                          _c("div", {
                                            staticClass: "checkPoint2",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.hitCurrentCheckpoint(
                                                  1
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.finishedCurrentCheckpoint(
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                          _c("div", {
                                            staticClass: "checkPoint3",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.hitCurrentCheckpoint(
                                                  2
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.hitRedArea()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                ]
                              )
                            : _vm._e(),
                          _vm._m(0),
                          _c("div", { staticClass: "hengIcon" }, [
                            _c("img", {
                              staticClass: "volume-button",
                              class: { shu: _vm.isShu },
                              attrs: { src: _vm.strokeImage, alt: "" },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "white-stroke-content-right" }, [
              _c(
                "div",
                { staticClass: "white-stroke-content-right_img" },
                _vm._l(_vm.backgroundImageList, function (item, index) {
                  return _c("div", { key: index + "bg", staticClass: "item" }, [
                    item.step === _vm.steps
                      ? _c("img", {
                          staticClass: "bgImg",
                          attrs: { src: item.img, alt: "" },
                        })
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "square" }, [
      _c("img", {
        staticClass: "volume-button",
        attrs: {
          src: require("@/assets/img/15-Hanzi-strokes/background.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }