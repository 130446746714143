var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "click-img-to-show-sentence-game layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + _vm.lessonNO },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.optionList.length,
              currentIndex: _vm.currentStarIndex,
            },
          }),
          _vm.titleInfo
            ? _c(
                "div",
                {
                  staticClass: "title-area",
                  class: [
                    _vm.titleInfo.size == "small" ? "small-title" : "",
                    "lesson-" + _vm.lessonNO,
                    _vm.titleInfo.position == "right" ? "isRightSide" : "",
                  ],
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "background-area" },
            [
              _c("img", { attrs: { src: _vm.bgImg } }),
              _vm._l(_vm.optionList, function (item, index) {
                return [
                  _c(
                    "transition",
                    { key: index + "bg", attrs: { name: "el-fade-in" } },
                    [
                      _c("img", {
                        staticClass: "bg-img-item",
                        class: {
                          fistImgOpacity:
                            (!item.isShowCorrect && _vm.noFirstImg) ||
                            (item.isShowCorrect && !_vm.noFirstImg),
                        },
                        attrs: {
                          src: item.isShowCorrect ? item.clickImg : item.bgImg,
                        },
                      }),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "click-area" },
            _vm._l(_vm.optionList, function (item, index) {
              return _c("div", {
                key: index + "click",
                class: "click-area-" + item.id,
                on: {
                  click: function ($event) {
                    return _vm.handleClickImg(item.id)
                  },
                },
              })
            }),
            0
          ),
          _vm.isShowMask
            ? _c(
                "div",
                {
                  staticClass: "mask-area",
                  on: { click: _vm.handleShowSentence },
                },
                _vm._l(_vm.maskImgList, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentId === item.id,
                          expression: "currentId === item.id",
                        },
                      ],
                      key: index + "mask",
                      staticClass: "mask-item",
                    },
                    [
                      _c("div", { staticClass: "mask-img" }, [
                        item.img
                          ? _c("img", { attrs: { src: item.img, alt: "" } })
                          : _vm._e(),
                      ]),
                      item.sentenceHanzi && _vm.isShowSentence
                        ? _c("div", { staticClass: "sentence" }, [
                            _c(
                              "p",
                              { staticClass: "pinyin font-pinyin-medium" },
                              [_vm._v(_vm._s(item.centencePinyin))]
                            ),
                            _c("p", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.sentenceHanzi)),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }