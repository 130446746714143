<template>
  <div class="ANIMATE_AUDIO">
    <!-- @click.ative="audioImgClick() -->
    <img :src="imgUrl" />
    <audio ref="audio" :src="audiourl" @ended="ended($event)"></audio>
  </div>
</template>

<script>
let png = require("@/assets/img/08-Device-check/gif-audio-new.gif");
let gif = require("@/assets/img/08-Device-check/gif-audio-new.gif");
export default {
  props: {
    audiourl: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      imgUrl: png,
    };
  },
  methods: {
    startGIF() {
      this.imgUrl = gif;
    },
    endGIF() {
      this.imgUrl = png;
    },
    audioImgClick() {
      console.log("1111111111111++++++++++++++++");
      if (this.audiourl) {
        let audio = this.$refs.audio;

        let a = audio.play();
        a.then(
          (resolve) => {
            this.imgUrl = gif;
          },
          (reject) => {
            this.imgUrl = gif;
          }
        );
      }
    },
    // 重新加载
    load() {
      this.imgUrl = png;
      let audio = this.$refs.audio;
      audio.load();
    },
    // 音频播放结束监听事件
    ended() {
      this.imgUrl = png;
    },
  },
};
</script>

<style scoped lang="scss">
.ANIMATE_AUDIO {
  cursor: pointer;
  > img {
    width: 100%;
  }
}
</style>