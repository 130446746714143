var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drag-game layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { ref: "moveGameBox", staticClass: "drag-game-content" },
        [
          _c("div", { staticClass: "contentImg" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 0,
                  expression: "step === 0",
                },
              ],
              attrs: {
                src: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/mask.svg"),
                alt: "",
              },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step != 0,
                  expression: "step != 0",
                },
              ],
              attrs: {
                src: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/background.svg"),
                alt: "",
              },
            }),
          ]),
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c("div", { staticClass: "dogImg", class: "step-" + _vm.step }, [
            _vm.step === 0
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm.step != 0
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog-01.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
          ]),
          _vm._l(_vm.allImgList, function (itemBox, indexBox) {
            return _vm.numBers === indexBox + 1
              ? _c("div", { key: indexBox, staticClass: "picture-envelope" }, [
                  _c(
                    "div",
                    { staticClass: "content-picture-switching" },
                    _vm._l(itemBox.imgList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "lesson-one",
                          class:
                            ({
                              notShow: _vm.showIndex === item.key,
                            },
                            "lesson" + item.key),
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function ($event) {
                              $event.stopPropagation()
                              return _vm.drag($event, item)
                            },
                            drop: function ($event) {
                              $event.stopPropagation()
                              return _vm.drop($event)
                            },
                            dragend: _vm.handleDragend,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "switchImg",
                            class:
                              ({
                                dispear: item.isDispear,
                                "drag-img-envelope-4": _vm.dragNum === 4,
                              },
                              "switchImg" + item.key),
                            attrs: { src: item.bgImg, alt: "" },
                          }),
                          _c("div", { staticClass: "pinYin" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title-pinyin pinyin font-pinyin-medium",
                              },
                              [_vm._v(_vm._s(item.pinYin))]
                            ),
                            _c(
                              "span",
                              { staticClass: "title-hanzi font-hanzi-medium" },
                              [_vm._v(_vm._s(item.hanZi))]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "audioUrl",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.playPinyinAudio(item)
                                },
                              },
                            },
                            [
                              _c("AudioPlayer", {
                                staticClass: "audioUrl_img",
                                attrs: {
                                  isPlay: item.isStartPlaying,
                                  audioUrl: _vm.songUrl,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e()
          }),
          _c("div", { staticClass: "picture-mailbox" }, [
            _c(
              "div",
              { staticClass: "content-picture-switching" },
              _vm._l(_vm.mailboxList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "lesson-one",
                    attrs: { draggable: "true" },
                    on: {
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.drop($event, item)
                      },
                      dragover: function ($event) {
                        $event.stopPropagation()
                        return _vm.allowDrop($event)
                      },
                    },
                  },
                  [
                    _c("span", {
                      class: {
                        "drag-img-mailbox-4": _vm.dragNum === 4,
                        xuXian: !item.isDispear,
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }