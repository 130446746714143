var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "liten-then-choose-words-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm.isImgPage
            ? _c("div", { staticClass: "choose-img-area" }, [
                _c("div", { staticClass: "img-area" }, [
                  _c(
                    "div",
                    { staticClass: "img-content" },
                    _vm._l(
                      _vm.allImgList[_vm.currentIndex],
                      function (item, index) {
                        return _c("img", {
                          key: index + "img",
                          staticClass: "img-item",
                          class: {
                            active: index === _vm.chooseImgIndex,
                            isCorrect:
                              item.id === _vm.answerList[_vm.currentIndex] &&
                              index === _vm.chooseImgIndex,
                          },
                          attrs: { src: item.bgImg, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleChooseImg(item.id, index)
                            },
                          },
                        })
                      }
                    ),
                    0
                  ),
                ]),
                _c("div", { staticClass: "trumpet-area-right" }, [
                  _c(
                    "span",
                    {
                      staticClass: "trumpet-area",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.playPinyinAudio.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("AudioPlayer", {
                        attrs: {
                          isPlay: _vm.isStartPlaying,
                          audioUrl: _vm.songUrl,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _c("div", { staticClass: "choose-hanzi-area" }, [
                _c("div", { staticClass: "answer-area" }, [
                  _c("div", { staticClass: "answer-img" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.chooseHanziList[_vm.currentIndex].bgImg,
                        alt: "",
                      },
                    }),
                  ]),
                  !_vm.isShowAllWords
                    ? _c(
                        "div",
                        { staticClass: "answer-hanzi-area" },
                        _vm._l(
                          _vm.chooseHanziList[_vm.currentIndex].hanziRightList,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index + "right",
                                staticClass: "hanzi-item",
                                class: {
                                  active: _vm.insertIndex === index,
                                  hasInserted:
                                    _vm.rightHanziIndexArr.indexOf(index + 1) >
                                    -1,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "pinyin font-pinyin-medium" },
                                  [_vm._v(_vm._s(item.pinyin))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-hanzi-medium" },
                                  [_vm._v(_vm._s(item.hanzi))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", { staticClass: "answer-right-hanzi-area" }, [
                        _c("div", { staticClass: "right-hanzi-box" }, [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-medium" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.chooseHanziList[_vm.currentIndex]
                                    .wordsPinyin
                                )
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(
                              _vm._s(
                                _vm.chooseHanziList[_vm.currentIndex].wordsHanzi
                              )
                            ),
                          ]),
                        ]),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "choose-hanzi-area" },
                  _vm._l(
                    _vm.chooseHanziList[_vm.currentIndex].hanziChooseList,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index + "choose",
                          staticClass: "hanzi-item",
                          class: {
                            dispear: _vm.choosedHanziArr.indexOf(index) > -1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleChooseHanzi(item.id, index)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-medium" },
                            [_vm._v(_vm._s(item.pinyin))]
                          ),
                          _c("span", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(_vm._s(item.hanzi)),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }