var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-link-up-container" }, [
    _c(
      "div",
      { staticClass: "game-link-up-content" },
      [
        _c("div", { staticClass: "game-picture-envelope" }, [
          _c(
            "div",
            { staticClass: "picture-switch" },
            _vm._l(_vm.envelopeList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lesson-one",
                  class:
                    ({
                      notShow: _vm.showIndex === item.key,
                    },
                    "envelope-" + item.key),
                },
                [
                  _c("img", {
                    staticClass: "switchImg",
                    class:
                      ({
                        dispear: item.isDispear,
                      },
                      "switchImg" + item.key),
                    attrs: {
                      src: item.picture ? item.picture : _vm.envelopImg,
                      alt: "",
                      draggable: "",
                    },
                    on: {
                      dragstart: function ($event) {
                        $event.stopPropagation()
                        return _vm.drag($event, item)
                      },
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.drop($event)
                      },
                      dragend: _vm.handleDragend,
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "game-picture-mailbox" }, [
          _c(
            "div",
            { staticClass: "mailbox-switch" },
            _vm._l(_vm.mailboxList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lesson-one",
                  on: {
                    drop: function ($event) {
                      $event.stopPropagation()
                      return _vm.drop($event, item)
                    },
                    dragover: function ($event) {
                      $event.stopPropagation()
                      return _vm.allowDrop($event)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
                      alt: "",
                      draggable: "true",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "word", class: "word" + item.value },
                    [
                      _c("span", { staticClass: "pinyin font-pinyin" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("span", { staticClass: "font-hanzi" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
        _c("CommonButton", {
          on: { shuffleClickEvent: _vm.handleShuffleClickEvent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }