var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "open-box-to-find-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "background-area" },
            [
              _c("img", { attrs: { src: _vm.bgImg } }),
              _vm._l(_vm.optionList, function (item, index) {
                return [
                  _c(
                    "transition",
                    { key: index + "bg", attrs: { name: "el-fade-in" } },
                    [
                      item.isShowImg
                        ? _c("img", {
                            staticClass: "bg-img-item",
                            attrs: { src: item.bgImg },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._l(_vm.optionList, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.idList.indexOf(item.id) === -1,
                    expression: "idList.indexOf(item.id) === -1",
                  },
                ],
                key: index + "click",
                staticClass: "click-area",
              },
              [
                _c("div", {
                  class: "click-area-" + item.id,
                  on: {
                    click: function ($event) {
                      return _vm.handleClickImg(item)
                    },
                  },
                }),
              ]
            )
          }),
          _vm.isShowSentence
            ? _c(
                "div",
                { staticClass: "sentence-area" },
                _vm._l(_vm.optionList, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentId === item.id,
                          expression: "currentId === item.id",
                        },
                      ],
                      key: index + "sentence",
                      staticClass: "title",
                    },
                    [
                      _c("div", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(" " + _vm._s(item.centencePinyin) + " "),
                      ]),
                      _c("div", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(" " + _vm._s(item.sentenceHanzi) + " "),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }