var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-container" }, [
    _c("div", { staticClass: "write-hanzi-game-container border-layout" }, [
      _c("div", { staticClass: "red-background-border" }),
      _c(
        "div",
        { staticClass: "write-hanzi-game-box" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
            nativeOn: {
              click: function ($event) {
                return _vm.handleWrite("socket")
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "write-hanzi-button",
              class: {
                btnEyeHover: _vm.isBtnEyeHover,
                btnRestartHover: _vm.isBtnRestartHover,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn-item eye-btn",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickEyeButton("socket")
                    },
                    mouseenter: function ($event) {
                      _vm.isBtnEyeHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.isBtnEyeHover = false
                    },
                  },
                },
                [
                  _vm.isShowTips
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-on.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-off.svg"),
                          alt: "",
                        },
                      }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "btn-item restart-btn",
                  on: {
                    click: function ($event) {
                      return _vm.restartGame("socket")
                    },
                    mouseenter: function ($event) {
                      _vm.isBtnRestartHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.isBtnRestartHover = false
                    },
                  },
                },
                [
                  !_vm.eyeIsHover
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"),
                          alt: "",
                        },
                      }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "write-hanzi-game-content layout-red-bgc" },
            [
              _c("SceneSwitchThumbnails", {
                attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
                on: { changeTheNumbers: _vm.changeTheNumbers },
              }),
              _c("div", { staticClass: "write-hanzi-game-content-container" }, [
                _c("div", { staticClass: "write-game-content-left-box" }, [
                  _c(
                    "div",
                    { staticClass: "steps-img-box" },
                    [
                      _vm._l(_vm.LeftImgList, function (item, index) {
                        return [
                          index === _vm.currentIndex
                            ? _c(
                                "div",
                                { key: index, staticClass: "img-item" },
                                [
                                  _c("img", {
                                    staticClass: "bg-tip-img",
                                    attrs: { alt: "", src: item },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "write-game-content-right-box" }, [
                  _c("div", { staticClass: "blue-background-border" }, [
                    _c("div", { staticClass: "write-game-right-content" }, [
                      _c("div", { staticClass: "hanzi-write-area" }, [
                        _c(
                          "span",
                          { staticClass: "pinyin font-pinyin-large" },
                          [_vm._v(_vm._s(_vm.currentHanziInfo.pinyin))]
                        ),
                        _c("div", { staticClass: "hengIcon" }, [
                          _c("img", {
                            staticClass: "stroke-square-box",
                            attrs: { src: _vm.currentHanziInfo.bgImg },
                          }),
                        ]),
                        _vm.isShowTips
                          ? _c(
                              "div",
                              { staticClass: "hengIcon-gif" },
                              _vm._l(
                                _vm.gifList[_vm.currentIndex],
                                function (item, index) {
                                  return _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          index + 1 === _vm.currentStrokeNum,
                                        expression:
                                          "index + 1 === currentStrokeNum",
                                      },
                                    ],
                                    key: index,
                                    staticClass: "stroke-square-box",
                                    attrs: { src: item },
                                  })
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "canvas-box",
                            on: {
                              click: function ($event) {
                                return _vm.handleSingleClick("socket")
                              },
                            },
                          },
                          [_vm.showCanvas ? _c("KidsWhiteBoard") : _vm._e()],
                          1
                        ),
                      ]),
                      _vm.isShowTips
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "hanzi-stroke-tips-area font-hanzi-medium",
                              class: {
                                hasSixItems:
                                  _vm.currentHanziInfo.tipsList.length % 6 == 0,
                              },
                            },
                            [
                              _vm._l(
                                _vm.currentHanziInfo.tipsList,
                                function (item, index) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        key: index + "tips",
                                        staticClass:
                                          "hanzi-blue font-hanzi-medium",
                                        class: {
                                          "hanzi-grey":
                                            index ===
                                            _vm.currentHanziInfo.tipsIndex,
                                        },
                                      },
                                      [_vm._v(_vm._s(item))]
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }