var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sing-page-container",
      class: { reviewPageStyle: _vm.isReviewPage },
    },
    [
      _c("audio", {
        ref: "audio",
        attrs: { src: _vm.songSrc },
        on: { canplay: _vm.getDuration, timeupdate: _vm.updateTime },
      }),
      _c(
        "div",
        { staticClass: "sing-page-content" },
        [
          !_vm.isReviewPage
            ? _c("PageButton", {
                attrs: {
                  bottomCornerButtonList: _vm.bottomCornerButtonList,
                  isLastStep: _vm.isLastStep,
                },
                on: { clickNext: _vm.nextPage },
              })
            : _vm._e(),
          _c("div", { staticClass: "customlesson-content-sing" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/16-GAMES/G07-ktv/ktv-background.svg"),
              },
            }),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isPlayWholeSong,
                  expression: "isPlayWholeSong",
                },
              ],
              staticClass: "audio-content",
            },
            [
              _c(
                "div",
                { staticClass: "content-text" },
                [
                  _c("div", { staticClass: "audio-img" }, [
                    _c("img", {
                      staticClass: "bg-img",
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G07-ktv/ktv-record-player.svg"),
                      },
                    }),
                    _c("img", {
                      staticClass: "animation-img",
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G07-ktv/ktv-record.gif"),
                      },
                    }),
                  ]),
                  _c("transition", { attrs: { name: "el-fade-in" } }, [
                    _c(
                      "div",
                      { staticClass: "sentence-box" },
                      [
                        _vm._l(_vm.singList.lyricsArr, function (item, index) {
                          return [
                            _vm.currentSentenceIndex === item.id ||
                            (_vm.currentSentenceIndex == 0 && index === 0)
                              ? _c(
                                  "div",
                                  {
                                    key: index + "lyrics",
                                    staticClass: "sentence-content",
                                    class: {
                                      isInvisible:
                                        _vm.currentSentenceIndex == 0,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "origin item" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-pinyin-medium pinyin center",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.spelling) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "font-hanzi-medium" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.chineseWords) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "animation item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-pinyin-medium pinyin center",
                                            class: {
                                              "singleSentence-yellow":
                                                item.id ===
                                                _vm.currentSentenceIndex,
                                            },
                                            style: {
                                              "background-position":
                                                _vm.distance + "px 0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.spelling) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "font-hanzi-medium",
                                            class: {
                                              "singleSentence-yellow":
                                                item.id ===
                                                _vm.currentSentenceIndex,
                                            },
                                            style: {
                                              "background-position":
                                                _vm.distance + "px 0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.chineseWords) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "customlesson-button-img" }, [
            _c(
              "div",
              {
                staticClass: "slow-button",
                on: {
                  click: function ($event) {
                    return _vm.handleSongVelocity(0)
                  },
                  mouseenter: function ($event) {
                    _vm.isSlowButtonHover = true
                  },
                  mouseleave: function ($event) {
                    _vm.isSlowButtonHover = false
                  },
                },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSlowButtonHover && !_vm.isChooseSlow,
                      expression: "!isSlowButtonHover && !isChooseSlow",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-slow.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSlowButtonHover || _vm.isChooseSlow,
                      expression: "isSlowButtonHover || isChooseSlow",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-slow-hover.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "quick-button",
                on: {
                  click: function ($event) {
                    return _vm.handleSongVelocity(1)
                  },
                  mouseenter: function ($event) {
                    _vm.isQuickButtonHover = true
                  },
                  mouseleave: function ($event) {
                    _vm.isQuickButtonHover = false
                  },
                },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isQuickButtonHover && !_vm.isChooseQuick,
                      expression: "!isQuickButtonHover && !isChooseQuick",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-fast.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isQuickButtonHover || _vm.isChooseQuick,
                      expression: "isQuickButtonHover || isChooseQuick",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-fast-hover.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "button-img",
                on: { click: _vm.handlePlayAndPauseAudio },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isPlaySong,
                      expression: "!isPlaySong",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-play.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPlaySong,
                      expression: "isPlaySong",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-lyrics.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "customlesson-content_foseOneImg",
                class: { lyricsClick: _vm.isLyricsClick },
                on: {
                  click: function ($event) {
                    return _vm.switchSentences()
                  },
                },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLyricsClick,
                      expression: "isLyricsClick",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-button-lyrics-active.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLyricsClick,
                      expression: "!isLyricsClick",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/yellow-all.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]),
          !_vm.isPlayWholeSong
            ? _c("div", { staticClass: "lyrics-box-demo" }, [
                _c("div", { staticClass: "lyrics-box" }, [
                  _c(
                    "div",
                    { staticClass: "lyrics-content" },
                    _vm._l(_vm.singList.lyricsArr, function (item, index) {
                      return _c(
                        "div",
                        { key: index + "lyrics", staticClass: "lyrics-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "font-pinyin-medium pinyin" },
                            [_vm._v(" " + _vm._s(item.spelling) + " ")]
                          ),
                          _c("div", { staticClass: "font-hanzi-medium" }, [
                            _vm._v(" " + _vm._s(item.chineseWords) + " "),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "lyrics-content animation-all" },
                    _vm._l(_vm.singList.lyricsArr, function (item, index) {
                      return _c(
                        "div",
                        { key: index + "lyrics-", staticClass: "lyrics-item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-pinyin-medium pinyin",
                              class: {
                                "singleSentence-blue":
                                  _vm.showBgcIndex === item.id,
                                redColor:
                                  _vm.showBgcIndex - 1 > index ||
                                  _vm.isFinishPlayingSong,
                              },
                              style: {
                                "background-position": _vm.distance + "px 0",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.spelling) + " ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "font-hanzi-medium",
                              class: {
                                "singleSentence-blue":
                                  _vm.showBgcIndex === item.id,
                                redColor:
                                  _vm.showBgcIndex - 1 > index ||
                                  _vm.isFinishPlayingSong,
                              },
                              style: {
                                "background-position": _vm.distance + "px 0",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.chineseWords) + " ")]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.isLyricsClick
            ? _c("div", { staticClass: "customlesson-content-img" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G07-ktv/ktv-record.svg"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }