var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-mirror-container" }, [
    _c(
      "div",
      { staticClass: "practice-content" },
      [
        _vm.sceneSwitchShow
          ? _c("SceneSwitchThumbnails", {
              attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
              on: { changeTheNumbers: _vm.changeTheNumbers },
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "practice-body" },
          [
            _vm.isShowCheckGif
              ? _c("div", {
                  directives: [
                    {
                      name: "checkgif",
                      rawName: "v-checkgif",
                      value: _vm.checkGif,
                      expression: "checkGif",
                    },
                  ],
                  staticClass: "checkGif",
                  class: { checkGifWrong: !_vm.checkGif },
                })
              : _vm._e(),
            _vm._l(_vm.practiceList, function (item, index) {
              return [
                _vm.numBers <= (index + 1) * 2 && _vm.numBers > index * 2
                  ? _c(
                      "div",
                      {
                        key: index + "choooseImg",
                        staticClass: "practice-page-content-item",
                      },
                      _vm._l(item.imgList, function (ele, indexSon) {
                        return _c(
                          "div",
                          {
                            key: indexSon + "img",
                            staticClass: "img-content-item",
                            class: { imgHover: _vm.hoverIndex === indexSon },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleCheck(
                                  ele,
                                  indexSon,
                                  false,
                                  index
                                )
                              },
                              mouseenter: function ($event) {
                                _vm.hoverIndex = indexSon
                              },
                              mouseleave: function ($event) {
                                _vm.hoverIndex = null
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "img-area" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "bg-img",
                                  on: {
                                    mouseenter: function ($event) {
                                      _vm.getWordsId(
                                        ele,
                                        (_vm.isHover = true),
                                        index
                                      )
                                    },
                                    mouseleave: function ($event) {
                                      _vm.getWordsId(
                                        ele,
                                        (_vm.isHover = false),
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "target-img",
                                    attrs: { src: ele.bgImg, alt: "" },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "targetSymbolTip",
                                  class: {
                                    cardSelected:
                                      _vm.curentClickIndex === indexSon,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "lesson-foseImgFree card-selection-text",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "pinyin font-pinyin-medium",
                                        },
                                        [_vm._v(_vm._s(item.pinyin))]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-hanzi-medium" },
                                        [_vm._v(_vm._s(item.hanzi))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }