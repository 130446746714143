var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "learn-review-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c("div", { staticClass: "learn-review-box" }, [
      _c("div", { staticClass: "learn-review-content layout-red-bgc" }, [
        _c("div", { staticClass: "resizer" }, [
          _c("div", { staticClass: "review-content" }, [
            _vm.lessonNum !== 20
              ? _c(
                  "div",
                  { staticClass: "content-characters" },
                  _vm._l(_vm.wordsList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "content-characters-words",
                        class: { showBox: !item.show },
                        on: {
                          drop: function ($event) {
                            $event.stopPropagation()
                            return _vm.dropChalk($event, item)
                          },
                          dragover: function ($event) {
                            $event.stopPropagation()
                            return _vm.allowDrop($event)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "pinyin font-pinyin-medium" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "span",
                          { staticClass: "characterWriting font-hanzi-medium" },
                          [_vm._v(_vm._s(item.namePin))]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  { staticClass: "content-characters" },
                  _vm._l(_vm.wordsList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "content-characters-words-20",
                        class: { showBox: !item.show },
                        on: {
                          drop: function ($event) {
                            $event.stopPropagation()
                            return _vm.dropChalk($event, item)
                          },
                          dragover: function ($event) {
                            $event.stopPropagation()
                            return _vm.allowDrop($event)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "pinyin font-pinyin-medium" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "span",
                          { staticClass: "characterWriting font-hanzi-medium" },
                          [_vm._v(_vm._s(item.namePin))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
            _c(
              "div",
              { staticClass: "content-characters" },
              _vm._l(_vm.sentenceList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "content-characters-sentence",
                    class: { showBox: !item.show },
                    on: {
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.dropChalk($event, item)
                      },
                      dragover: function ($event) {
                        $event.stopPropagation()
                        return _vm.allowDrop($event)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c(
                      "span",
                      { staticClass: "characterWriting font-hanzi-medium" },
                      [_vm._v(_vm._s(item.namePin))]
                    ),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "content-characters-bottom" },
              _vm._l(_vm.imgList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "content-characters-stroke",
                    class: { showBox: !item.show },
                    on: {
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.dropChalk($event, item)
                      },
                      dragover: function ($event) {
                        $event.stopPropagation()
                        return _vm.allowDrop($event)
                      },
                    },
                  },
                  [_c("img", { attrs: { src: item.imgUrl, alt: "" } })]
                )
              }),
              0
            ),
          ]),
          _c("img", {
            staticClass: "chalkImg",
            attrs: {
              src: require("@/assets/img/12-Review/chalk.svg"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "removerImg glow",
            attrs: {
              src: require("@/assets/img/12-Review/remove.svg"),
              draggable: "true",
            },
            on: {
              dragstart: function ($event) {
                $event.stopPropagation()
                return _vm.drag($event)
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }