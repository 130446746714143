<template>
  <div class="write-stroke-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
      @click.native="startDrawStroke"
    ></PageButton>
    <div class="stroke-page-box">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <!-- writing button -->

      <!-- 眼睛按钮 eye-->
      <div
        v-if="isShowEye"
        class="write-stroke-page-eye-btn"
        @mouseover="eyeIsHover = true"
        @mouseleave="eyeIsHover = false"
        @click="handleClickEyeButton"
      >
        <img
          v-if="!eyeIsHover"
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"
          alt=""
        />
        <img
          v-else
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"
          alt=""
        />
      </div>

      <div class="write-stroke-content">
        
        <transition name="el-fade-in">
          <div class="write-stroke-area" >
            <!-- left 左边 -->
            <div class="write-stroke-content-left">
              <div class="write-stroke-left-content">
                <div class="left-content-border"></div>
                <div class="left-content">
                
                  <span class="pinyin font-pinyin-large">{{
                    strokeArr[numBers-1].pinyin
                  }}</span>

                  <!-- default 默认左边背景 -->
                  <div
                    class="stroke-area"
                    @mousedown="startDrawing()"
                    @mouseup="endDrawing($event)"
                  >
                    <div class="hanzi-img">
                      <KidsWhiteBoard ref="drawArea" />
                      <div
                        v-show="drawing"
                        class="WritingStrokeArea"
                        @click="draggedOutOfStroke"
                      ></div>
                      <div class="square">
                        <img
                          class="stroke-square-img"
                          src="@/assets/img/15-Hanzi-strokes/background.svg"
                          alt=""
                        />
                      </div>

                      <div
                        class="hengIcon"
                      >
                        <img
                          class="stroke-square-img"
                          :src="strokeArr[numBers-1].img"
                          alt=""
                        />
                        <img
                          v-show="showArr.indexOf(index+1)!=-1"
                          v-for="(item,index) in strokeImgFill[numBers-1]"
                          :key="index"
                          class="stroke-square-img"
                          :src="item.image"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- right 右边 -->
            <div class="write-stroke-content-right">
              <div class="write-stroke-content-right_img">
                <div
                  class="item"
                  v-for="(item, index) in backgroundImageList"
                  :key="index + 'bg'"
                >
                  <!-- this img need be discussed /9-Game-write-heng/water.svg -->
                  <!-- <img src="" alt="" /> -->
                  <img
                    v-if="item.step === steps"
                    :src="item.img"
                    alt=""
                    class="bgImg"
                  />
                </div>
              </div>
            </div>
          
          </div>
        </transition>
         <div class="bottom-content">
          <!-- <div class="bottom-item" v-for="(item,index) in strokeImgFill[numBers-1]" :key="index">
            <img v-if="showArr.indexOf(index+1)==-1" :src="item.ButtomImage"/>
          </div> -->
          <div class="bottom-item" v-for="(item,index) in strokeImgFill[numBers-1]" :key="index">
            <img v-if="item.show" :src="item.ButtomImage" @mousedown="strokeImgClick(item,index)"/>
          </div>
        </div>
      </div>
          <!-- 底部 -->
      
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  props: {
    strokeArr: {
      type: Array,
      require: true,
    },
    buttonList:{
      type: Array,
      require: true,
    },
    strokeImgFill: {
      type: Array,
      require: true,
    },
    backgroundImageList: {
      type: Array,
      require: true,
    },
    
    lesson: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      buShu: null,
      step: 0,
      colorClick: false,
      characterCheckpoints: [
        {
          id: 1,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 2,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id: 3,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
      ],
      numBers: 1,
      isTipPage: true,
      isShowEye: false,
      isShowDrawButton: true,
      eyeIsHover: false,
      isFinishWriting: false,
      isShowHand: false,
      drawing: false,
      steps: 0,
      writeIndex:0,
      showArr:[],
      showStroke: false,

      isLastStep: false,
      handleStepsValue: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
      startDrawStrokeValue: false,
    };
  },
  components: {
    KidsWhiteBoard,
    PageButton,
    SceneSwitchThumbnails,
  },
  mounted() {
    this.startWritingStroke()
    this.$bus.$on("startDrawStroke", (val) => {
      if (!val) {
        this.startDrawStroke(1);
      } else {
        this.handleClickEyeButton(1);
      }
      this.startDrawStrokeValue = true;
    });
    this.$bus.$on("drawSingleStroke", () => {
      this.handleSteps(1);
    });
     this.$bus.$on("clickIntroAside", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("startDrawStroke");
    this.$bus.$off("drawSingleStroke");
    this.$bus.$off("backOrigin");
    this.$bus.$emit("startStrokePage", false);
  },
  watch: {
    isShowEye(value) {
      this.$bus.$emit("startStrokePage", value);
    },
    
    isTipPage(value) {
      if (!this.startDrawStrokeValue) {
        const data = {
          clickType: 2000301,
          data: { value },
          text: "测试writeStrokePage组件内drawButton事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      }
    },
    handleStepsValue() {
      if (this.isShowEye) {
        const data = {
          clickType: 2000302,
          data: { value: this.steps },
          text: "测试writeStrokePage组件内steps的socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      }
    },
  },
  methods: {
    // 图片点击
    strokeImgClick(item) {
      if(item.step === this.step) {
        item.show = false
        this.step++
        this.colorClick = true   
      }else {
        item.show = true
      }
    },
    startDrawStroke(type) {
      if (type != 1) {
        this.startDrawStrokeValue = false;
      } else {
        this.startDrawStrokeValue = true;
      }
      this.isTipPage = false;
      this.startWritingStroke();
    },
    changeTheNumbers(index, isFromSocket = false) {
      this.buShu = index
      this.step = 0
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      console.log(index);
      this.numBers = index;
      this.steps=0
      this.showArr=[]
      this.writeIndex=0
    },
    startDrawing() {},
    endDrawing() {
      this.finishedStroke();
    },

    finishedStroke() {
      if(this.colorClick) {
        this.showStroke = true;
        this.handleSteps();
      }
    },
    overShot() {
      console.log("over shot");
    },

    hitRedArea() {
      this.drawing = false;
      for (let index = 0; index < this.characterCheckpoints.length; index++) {
        this.characterCheckpoints[index].hitcheckpoint = false;
        this.characterCheckpoints[index].finishedCheckpoint = false;
      }
      setTimeout(() => {
        this.$bus.$emit("controlCanvas", "clear");
      }, 500);
    },
    draggedOutOfStroke() {
      console.log("dragged out");
    },
    checkPoint(point) {
      console.log("check point: ", point);
    },

    // 开始写笔画
    startWritingStroke() {
        this.isShowDrawButton = false;
        this.isShowEye = true;
        // this.steps = 0;
    },
    // 返回最原始page
    handleClickEyeButton(type) {
      if (type != 1) {
        this.startDrawStrokeValue = false;
      } else {
        this.startDrawStrokeValue = true;
      }
      this.isTipPage = true;
      // this.isLastStep = false;
      this.isShowEye = false;
      this.$bus.$emit("startStrokePage", this.isShowEye);
      this.showStroke = false;
      // this.steps = 0;
    },
    // 下一页
    nextPage() {
      this.$bus.$emit("startStrokePage", false);
      this.$bus.$emit("nextButton", true);
    },
    // 步数
    handleSteps(type) {
      if (type != 1) {
        this.handleStepsValue++;
      }
      if(this.steps<this.strokeImgFill[this.numBers-1].length){
      this.steps++;
      this.showArr.push(this.steps)
      this.showArr=[...new Set(this.showArr)]
      console.log(this.steps)
      }
      setTimeout(() => {
         if(this.buShu === null && this.step === 3) {
            this.changeTheNumbers(2, true)
          }else if(this.buShu === 2 && this.step === 4){
            this.changeTheNumbers(3, true)
          }
          this.$bus.$emit("controlCanvas", "clear");
        }, 500);
      this.writeIndex++
      this.colorClick = false
      if(this.writeIndex>this.strokeImgFill[this.numBers-1].length){
        return
      }
      if (this.steps === this.strokeImgFill[this.numBers-1].length&&this.numBers===this.buttonList.length) {
      //     if(this.steps == this.strokeImgFill[this.numBers-1].length){
      //   return
      // }
        this.isFinishWriting = true;
        this.isLastStep = true;
        this.isShowHand = false;
        startConfetti();
        playCorrectSound();
        
      } else {
        playCorrectSound(true, false);
        setTimeout(() => {
          this.showStroke = false;
        }, 1500);
        this.hitRedArea();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.WritingStrokeArea {
  width: 100% !important;
  // height: 100% !important;
  //  background-color: black;
  // position: relative !important;
}

.write-stroke-page-container {
  .stroke-page-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    position: relative;
    .write-stroke-content {
      background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
        no-repeat;
      // padding: 10px 10px 30px 10px;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 58px;
      // display: flex;
      position: relative;
   
        
      }
      .write-stroke-area {
        display: flex;
        position: relative;
        top: 50px;
        left: 0;
        height: 75%;
        width: 100%;
        .write-stroke-content-left {
          // position: absolute;
          position: relative;
          display: flex;
          width: 45%;
          height: 80%;
          justify-content: flex-end;
          align-items: center;
          .write-stroke-left-content {
            position: relative;
            width: 80%;
            height: 100%;
            .left-content-border {
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              position: absolute;
              right: 0;
              bottom: 0;
              background: #224e96;
              border-radius: 30px;
            }
            .left-content {
              color: #000;
              text-align: center;
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background: #fff;
              border-radius: 30px;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: default;
             
              .pinyin {
                color: #cd4c3f;
              }
              .stroke-area {
                width: 80%;
                // margin-top: 10%;
                display: flex;
                justify-content: center;
                height: 60%;
                flex: 6.5;
                align-items: flex-start;
                .hanzi-img {
                  position: relative;
                  width: 100%;
                  .hengIcon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    img {
                      width: 100%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      margin: auto;
                      -khtml-user-select: none;
                      -o-user-select: none;
                      -moz-user-select: none;
                      -webkit-user-select: none;
                      user-select: none;
                      -webkit-user-drag: none;
                    }
                    &.iconLarge {
                      img {
                        width: 90%;
                      }
                    }
                  }
                }
                .stroke-square-img {
                  cursor: pointer;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
                div {
                  width: 100%;
                  height: 0;
                  padding-bottom: 100%;
                  position: absolute;
                }
                img {
                  width: 100%;
                  -khtml-user-select: none;
                  -o-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                }
              
            }
          }
        }
       
      }
       .write-stroke-content-right {
          width: 40%;
          height: 80%;
          // position: absolute;
          // left: 70%;
          // display: flex;
              margin-left:100px;
          flex-direction: row;
          // justify-content: center;
          align-items: center;
          .write-stroke-content-right_img {
            .item {
              display: flex;
              // justify-content: center;
              align-items: center;
              img {
                display: block;
                width: 90%;
                max-height: 350px;
                // &:first-child {
                //   width: 30%;
                //   // left: 12%;
                //   // top: 20%;
                //   z-index: 1001;
                //   max-height: 150px;
                // }
              }
            }
          }
        }
    }
      .bottom-content{
        //  position: absolute;
          // text-align: center;
          width: 45%;
          height: 20%;
          background-image:url("./background.svg");
          // height: 20%;
          background-repeat: no-repeat;
          background-size:100%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-around;
          // padding:20px;
          // bottom: -10%;
          .bottom-item{
            // margin-top:-8% ;
            // margin: 10px;
            width: 16%;
            height: 55%;
            border-radius: 18px;
            background: #F4D737;
             img{
            width: 100%;
           }
          }
        }
    
    // button
    .write-stroke-page-eye-btn {
      position: absolute;
      right: 14px;
      bottom: 26%;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      text-align: right;
      padding-right: 3%;
      img {
        width: 42%;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // margin: auto;
      }
    }
    .button-area {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
  .shu {
    height: 80%;
  }
}
</style>
