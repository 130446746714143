<template>
  <div class="SELECTCAMERA">
    <!-- close -->
    <NewImg
      class="icon_close"
      :src="require('@/assets/img/19-Dashboard/icon-plus-blue.png')"
      @click.native="close()"
    ></NewImg>
    <!-- video -->
    <div id="modal_local" class="content_video"></div>
    <!-- title -->
    <p class="title font_two fontWeight_medium">
      Are you sure you want to change your camera?
    </p>
    <!-- switch camera -->
    <div class="font_one fontWeight_regular switchCamera">
      Current camera:
      <span @click="showSelectFun($event)">{{ selectCamera.value }}</span>
      <NewSelect2
        v-show="showSelect"
        ref="newSelect"
        :listData="cameraList"
        @selectClick="selectClick"
      ></NewSelect2>
    </div>
    <!-- button group -->
    <div class="buttonGroup">
      <div
        class="customButton gray_blue font_two fontWeight_bold"
        @click="close()"
      >
        CANCEL
      </div>
      <div
        class="customButton blue_white font_two fontWeight_bold"
        @click="confirm()"
      >
        CONFIRM
      </div>
    </div>
  </div>
</template>

<script>
import { deepCopy } from "@/utils/rejular";

let localStream = null;

export default {
  components: {
    NewImg: () => import("../NewImg"),
    NewSelect2: () => import("../NewSelect2"),
  },
  data() {
    return {
      selectCamera: {
        value: "",
        code: "",
      },
      // 摄像头列表
      cameraList: [],
      // 是否显示下拉框
      showSelect: false,
    };
  },
  methods: {
    // 关闭模态框
    close() {
      this.$emit("close");
    },
    // 摄像头检测
    cameraAllow() {
      TRTC.getCameras().then((result) => {
        console.log(result);
        this.cameraList = deepCopy(result);
        // console.log(this.cameraList)
        if (this.cameraList.length) {
          this.nocamera = false;
          this.cameraList.map((camera) => {
            camera.code = camera.deviceId;
            camera.value = camera.label;
          });
          let newSelect = this.$refs.newSelect;
          newSelect.selectClick(this.cameraList[0], 0);
        }
      });
    },
    // 获取本地视频流
    getLocalStream() {
      if (localStream) {
        localStream.close();
        localStream = null;
      }

      setTimeout(() => {
        let streamConfig = {
          audio: true,
          video: true,
        };
        if (this.selectCamera.deviceId) {
          streamConfig.cameraId = this.selectCamera.deviceId;
        }
        // console.log(streamConfig)

        localStream = TRTC.createStream(streamConfig);
        console.log(localStream);
        localStream
          .initialize()
          .then((res) => {
            // console.log(res)
            localStream.play("modal_local");
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1500);
    },
    // 显示下拉框
    showSelectFun(event) {
      let newSelect = this.$refs.newSelect;
      newSelect.setPostion(event);
      this.showSelect = true;
    },
    // 选择摄像头
    selectClick(data, index) {
      // 当前状态 摄像头
      this.selectCamera = deepCopy(data);
      // console.log(data)
      // localStorage.selectCamera = JSON.stringify(data);
      this.showSelect = false;
      this.getLocalStream();
    },
    // 确认按钮
    confirm() {
      localStorage.selectCamera = JSON.stringify(this.selectCamera);
      this.$emit("selectCamera_confirm");
      // this.getLocalStream();
    },
  },
  mounted() {
    this.cameraAllow();
  },
};
</script>

<style scoped lang="scss">
.SELECTCAMERA {
  position: relative;
  width: 340px;
  box-sizing: border-box;
  background-color: #f5c653;
  border-radius: 20px;
  padding: 20px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon_close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 35px;
    height: 35px;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .content_video {
    width: 140px;
    height: 140px;
    background-color: #2472b3;
    border-radius: 50%;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .title {
    width: 65%;
    text-align: center;
    margin-bottom: 10px;
  }
  .switchCamera {
    width: 80%;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .buttonGroup {
    display: flex;
    .customButton {
      margin: 0 10px;
    }
  }
}
</style>