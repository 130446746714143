var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "match-the-cards-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "match-the-cards-game-content" },
        [
          _vm.titleInfo
            ? _c(
                "div",
                {
                  staticClass: "title-area",
                  class: {
                    small: _vm.titleInfo.size == "small",
                    "zindex-0": _vm.titleInfo.zindex == 0,
                    "padding-20": _vm.titleInfo.padding == 20,
                    "scale-8": _vm.titleInfo.scale == 0.8,
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "objectsCont", class: "type-" + _vm.lessonNum },
            _vm._l(_vm.imgList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "objects",
                  class: "lesson-" + _vm.lessonNum,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card",
                      class: {
                        isFinishMatchOne: item.type === 1,
                        isFinishMatchTwo: item.type === 2 || item.type === 4,
                        isFinishMatchThree: item.type === 3,
                        cardIsActive: item.cardActive == true,
                        style1: index % 2 == 0,
                        style2: index % 2 != 0,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.foundAnswer(index, item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "objectImage",
                        attrs: { src: item.image },
                      }),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.imgList.length / 2,
              currentIndex: _vm.answersfound,
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMatchDisplay === true,
                  expression: "isMatchDisplay === true",
                },
              ],
              staticClass: "matchDisplay",
            },
            [
              _c("div", { staticClass: "objectDisplaycont" }, [
                _c("div", { staticClass: "objects" }, [
                  _c("div", { staticClass: "card style1" }, [
                    _c("img", {
                      staticClass: "objectImage",
                      attrs: { src: _vm.objectDisplayFirst },
                    }),
                  ]),
                  _c("div", { staticClass: "card style2" }, [
                    _c("img", {
                      staticClass: "objectImage",
                      attrs: { src: _vm.objectDisplaySecond },
                    }),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }