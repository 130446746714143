var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stroke-path-game-container layout-border" },
    [
      _vm.isLastStep
        ? _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.nextPage.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "stroke-path-game-box" },
        [
          _c("SceneSwitchThumbnails", {
            staticClass: "thumb-nails",
            attrs: {
              buttons: _vm.buttonList,
              numBers: _vm.currentStrokeType + 1,
            },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c("div", { staticClass: "stroke-path-game-content" }, [
            _c(
              "div",
              {
                staticClass: "puzzle-game-begin",
                class: "lesson-begin-" + _vm.lessonNum,
              },
              [
                _c("div", { staticClass: "little-bear" }, [
                  _c("img", {
                    staticClass: "first-animal",
                    class: { "border-blue": _vm.currentStrokeType === 0 },
                    attrs: { src: _vm.firstAnimalInfo.img, alt: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.changeAnimal(0)
                      },
                    },
                  }),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/arrow-blue.svg"),
                      alt: "",
                    },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "puzzle-game-content" }, [
              _c("div", { ref: "puzzle", staticClass: "puzzle-game-box" }, [
                _c("img", {
                  ref: "puzzledRectImg",
                  staticClass: "puzzle-game-img",
                  attrs: {
                    src: _vm.puzzleBgImg,
                    alt: "",
                    id: "puzzle-game-img",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "avatar-move-area",
                  style: {
                    height: _vm.gameHeight + "px",
                    width: _vm.gameWidth + "px",
                  },
                },
                _vm._l(_vm.puzzleItemList, function (item, index) {
                  return _c(
                    "div",
                    { key: index + "row", staticClass: "col-area" },
                    _vm._l(item, function (ele, index) {
                      return _c(
                        "div",
                        {
                          key: index + "col",
                          staticClass: "row-area",
                          class: { black: ele.type === 1 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar-img" },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "el-fade-in" } },
                                [
                                  _vm.currentStrokeType === 0 && ele.isShow
                                    ? _c("img", {
                                        staticClass: "border-blue",
                                        attrs: {
                                          src: _vm.firstAnimalInfo.img,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "puzzle-game-final",
                class: "lesson-" + _vm.lessonNum,
              },
              [
                _c("div", { staticClass: "little-bear" }, [
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/arrow-blue.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    attrs: { src: _vm.firstAnimalInfo.finalImg, alt: "" },
                  }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "button-control-box" }, [
            _c("img", {
              staticClass: "control-bg-img",
              attrs: {
                src: require("@/assets/img/16-GAMES/G18-maze2/control.svg"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                staticClass: "instructions",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.rightClick.apply(null, arguments)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "blinkAndDisappear",
                  attrs: {
                    src: require("@/assets/img/17-Tutorials/hand.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "button-control-content" }, [
              _c(
                "div",
                {
                  staticClass: "button-img",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.leftClick()
                    },
                    mouseover: function ($event) {
                      _vm.leftImg = 2
                    },
                    mouseleave: function ($event) {
                      _vm.leftImg = 1
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.leftImg == 1,
                        expression: "leftImg == 1",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/left-icon.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.leftImg == 2,
                        expression: "leftImg == 2",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/left-icon-hover.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "button-img",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.rightClick()
                    },
                    mouseover: function ($event) {
                      _vm.rightImg = 2
                    },
                    mouseleave: function ($event) {
                      _vm.rightImg = 1
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rightImg == 1,
                        expression: "rightImg == 1",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/right-icon.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rightImg == 2,
                        expression: "rightImg == 2",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/right-icon-hover.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "button-img",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.upClick()
                    },
                    mouseover: function ($event) {
                      _vm.upImg = 2
                    },
                    mouseleave: function ($event) {
                      _vm.upImg = 1
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.upImg == 1,
                        expression: "upImg == 1",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/up-icon.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.upImg == 2,
                        expression: "upImg == 2",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/up-icon-hover.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "button-img",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.downClick()
                    },
                    mouseover: function ($event) {
                      _vm.downImg = 2
                    },
                    mouseleave: function ($event) {
                      _vm.downImg = 1
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.downImg == 1,
                        expression: "downImg == 1",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/down-icon.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.downImg == 2,
                        expression: "downImg == 2",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G18-maze2/down-icon-hover.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }