var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speaking-sentence-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("div", { staticClass: "game-content" }, [
        _vm.hasMenu
          ? _c(
              "div",
              { staticClass: "menu" },
              [
                _c("SceneSwitchThumbnails", {
                  attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
                  on: { changeTheNumbers: _vm.changeTheNumbers },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.titleInfo
          ? _c(
              "div",
              { staticClass: "title-area", class: "lesson-" + _vm.lesson },
              [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bgImg-area" },
          [
            _vm._l(_vm.gameList, function (item, index) {
              return [
                index + 1 === _vm.currentIndex
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass: "img-box",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickImg(index)
                          },
                        },
                      },
                      [
                        _c("transition", { attrs: { name: "el-fade-in" } }, [
                          !item.isShowSentence
                            ? _c("img", { attrs: { src: item.bgImg, alt: "" } })
                            : _vm._e(),
                          item.isShowSentence
                            ? _c("img", {
                                attrs: { src: item.AchieveImg, alt: "" },
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _vm.hasSentence
          ? _c(
              "div",
              {
                staticClass: "bottom-sentence-area",
                class: { higherPosition: _vm.lesson == "14" },
              },
              _vm._l(_vm.gameList, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.isShowSentence,
                        expression: "item.isShowSentence",
                      },
                    ],
                    key: index,
                    staticClass: "hanzi-item",
                    class: { blueBG: _vm.isBlueBackground },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pinyin sentence-hanzi font-pinyin-medium",
                      },
                      [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "sentence-hanzi font-hanzi-medium" },
                      [_vm._v(_vm._s(item.hanzi))]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }