var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "darg-to-dress-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + _vm.lessonNO },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.answerList.length,
              currentIndex: _vm.step,
            },
          }),
          _c("div", { staticClass: "left-content" }, [
            _c(
              "div",
              { staticClass: "drag-area" },
              _vm._l(_vm.optionImgList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index + "drag",
                    staticClass: "option-item",
                    class: "option-" + item.id,
                  },
                  [
                    _c("img", {
                      class: { notShow: _vm.dragId === item.id },
                      attrs: { src: item.image, draggable: "true" },
                      on: {
                        dragstart: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDragImg($event, item.id)
                        },
                        drop: function ($event) {
                          $event.stopPropagation()
                          return _vm.Sdrop($event)
                        },
                        dragend: function ($event) {
                          $event.stopPropagation()
                          return _vm.dragend()
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "right-content" }, [
            _vm._m(0),
            _c("div", {
              staticClass: "drop-area dropBox",
              attrs: { draggable: "true" },
              on: {
                drop: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleDropImg($event)
                },
                dragover: function ($event) {
                  $event.stopPropagation()
                  return _vm.allowDrop($event)
                },
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-area" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "title-pinyin pinyin font-pinyin-medium" }, [
          _vm._v(" Zhōngguó zǎofàn "),
        ]),
        _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
          _vm._v("中国早饭"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }