<template>
  <div class="rocket-review-game-container">
    <audio :src="audioSrc" ref="audioBox"></audio>
    <div class="customlesson-content-five position-relative">
      <div
        class="rocket-bg-area"
        ref="backgroundArea"
        id="space-background-scroll"
      >
        <img ref="content" :src="bgImg" alt="" />
      </div>
      <div
        class="rocket-area"
        ref="rocketTop"
        :class="{ fadeOutRocket: fadeOutRocket, fixPosition: startMove }"
      >
        <div class="submarine" v-if="gameType === 1">
          <img
            :class="{ isHidden: animationFrame2 != 1 }"
            :src="require('@/assets/img/FixedPicture/Submarine/submarine.svg')"
            class="rocket-img"
            alt=""
          />
          <img
            :class="{ isHidden: animationFrame2 != 2 }"
            :src="
              require('@/assets/img/FixedPicture/Submarine/submarine-2.svg')
            "
            class="rocket-img"
            alt=""
          />
          <img
            :class="{ isHidden: animationFrame2 != 3 }"
            :src="
              require('@/assets/img/FixedPicture/Submarine/submarine-3.svg')
            "
            class="rocket-img"
            alt=""
          />
        </div>
        <div class="submarine" v-if="gameType === 2">
          <img
            :src="require('@/assets/img/FixedPicture/Submarine/airship.svg')"
            alt=""
          />
        </div>
        <div class="bubbles" :class="{ isHidden: hasReachedStation }">
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b2"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b5"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b6"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b3"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b7"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b4"
          />
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png"
            class="bubble b8"
          />
        </div>
      </div>
      <!-- 控制台部分 -->
      <div class="control-area">
        <img
          class="panel-img"
          :src="
            gameType === 1
              ? require('@/assets/img/FixedPicture/Submarine/submarine-panel-no-button.svg')
              : require('@/assets/img/FixedPicture/Submarine/control.svg')
          "
          alt=""
        />
        <div
          class="control-area-button glow"
          :class="{
            isHover: !hasReachedStation && steps != 0,
            lighter: gameType === 2
          }"
          @click="controlClick"
        >
          <img
            :src="
              gameType === 1
                ? require('@/assets/img/FixedPicture/Submarine/submarine-control-button.svg')
                : require('@/assets/img/FixedPicture/Submarine/control-button.svg')
            "
            alt=""
          />
        </div>
        <!-- <div class="control-area glow" @click="controlClick">
          <img src="@/assets/img/16-GAMES/G09-rocket/control.svg" alt="" />
        </div> -->
      </div>
    </div>
    <!-- 手指的位置 -->
    <div class="customlesson-finger" v-if="false">
      <img src="@/assets/img/16-GAMES/G09-rocket/hand.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          bgImg: '',
          gameType: null
        }
      }
    }
  },
  // computed: {
  //   getRocketImg() {
  //     let img = ''
  //     switch (this.gameType) {
  //       case 1:
  //         img = require('@/assets/img/16-GAMES/G09-rocket/rocket.svg')
  //         break
  //       case 2:
  //         img = require('@/assets/img/FixedPicture/raceCar.svg')
  //         break
  //     }
  //     return img
  //   }
  // },
  data() {
    return {
      speed: 10,
      gameType: 1,
      rocketSocketInfo: {},
      bgImgScrollDis: 0,
      distance: 0,
      rocketFlameAnimated: false,
      fadeOutRocket: false,
      bgImg: '',
      scrollTop: 0,
      numBers: 1,
      rocketFlameAnimated: false,
      fadeOutRocket: false,
      showFlames: false,
      steps: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'speak',
            startNumberRange: 1,
            endNumberRange: 3
          }
        ]
      },
      hasReachedStation: false, // check if rocket has stopped (when reaching station)
      animationFrame2: 1,
      startMove: false
    }
  },
  computed: {
    audioSrc() {
      return this.gameType === 1
        ? require('@/assets/audio/submarine.mp3')
        : require('@/assets/audio/airship.mp3')
    },
    durationArr() {
      return this.gameType === 1
        ? [
            1, 0.86, 0.78, 0.7, 0.62, 0.54, 0.46, 0.38, 0.29, 0.21, 0.13, 0.06,
            0
          ]
        : [0.95, 0.77, 0.69, 0.62, 0.55, 0.48, 0.41, 0.32, 0.25, 0.18, 0]
    }
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$refs.backgroundArea.scrollTop = this.$refs.content.scrollHeight
      this.bgImgScrollDis = this.$refs.content.scrollHeight
      this.distance = this.$refs.backgroundArea.scrollTop
    }, 500)
    this.$bus.$on('clickRocketGame', () => {
      this.controlClick(1)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickRocketGame')
  },
  watch: {
    rocketSocketInfo: {
      handler() {
        const data = {
          clickType: 20009,
          data: { value: this.socketInfo },
          text: 'RocketReviewGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        // console.log(value, '这是watch的数据')
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    initData() {
      this.bgImg = this.componentProps.bgImg
      this.gameType = this.componentProps.gameType
        ? Number(this.componentProps.gameType)
        : 1
    },
    controlClick(isFromSocket = false) {
      this.hasReachedStation = false
      this.playAudio()
      if (!isFromSocket) {
        this.rocketSocketInfo = {
          index: Math.random()
        }
      }
      if (this.steps === 0) {
        this.startMove = true
      } else {
        this.speed = 40
      }
      let speed = this.speed

      const timer = setInterval(() => {
        this.distance -= speed
        if (this.animationFrame2 == 3) {
          this.animationFrame2 = 1
        } else {
          this.animationFrame2++
        }

        this.$refs.backgroundArea.scrollTop = this.distance
        // console.log(
        //   this.distance,
        //   this.bgImgScrollDis,
        //   this.$refs.backgroundArea.scrollTop,
        //   '-----------------'
        // )
        // //this.durationArr[this.steps]
        // console.log(
        //   this.durationArr[this.steps],
        //   this.bgImgScrollDis * this.durationArr[this.steps]
        // )

        if (
          this.distance <=
          this.bgImgScrollDis * this.durationArr[this.steps]
        ) {
          this.playAudio(false)

          this.hasReachedStation = true
          clearInterval(timer)
          this.steps++
          if (this.steps === this.durationArr.length) {
            this.fadeOutRocket = true

            setTimeout(() => {
              this.$refs.backgroundArea.scrollTop = 0
              this.isLastStep = true
              playCorrectSound()
              startConfetti()
            }, 2000)
            return
          }
        }
      }, 50)
    },
    playAudio(play = true) {
      if (play == true) {
        this.$refs.audioBox.play()
        console.log('play audio', this.$refs.audioBox)
      } else {
        this.$refs.audioBox.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rocket-review-game-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .customlesson-content-five {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .rocket-bg-area {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      border-radius: 58px;
      position: relative;
      display: inline-block;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .rocket-area {
      position: absolute;
      width: 130px;
      bottom: 0;
      display: flex;
      left: 42%;
      z-index: 1001;
      .submarine {
        width: 100%;
      }
      .rocket-img {
        width: 100%;
      }
    }
    .fixPosition {
      bottom: 31%;
      transition: all 1s;
    }
    .control-area {
      width: 100%;
      height: 30%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .panel-img {
        z-index: 1002;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .control-area,
      .control-area-button {
        position: absolute;
        top: 39%;
        width: 10%;
        height: 50%;
        left: 47%;
        z-index: 1009;
        border-radius: 10px;
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .control-area-button {
        top: 46%;
        width: 13%;
        height: 6vw;
        left: 25%;
        img {
          width: 100%;
          transform: scaleY(1);
          transform-origin: bottom;
          transition: all 0.6s;
        }
        &.isHover {
          // top: 55%;
          img {
            transform: scaleY(0.7);
          }
        }
      }
      .lighter {
        width: 11%;
        left: 46%;
        top: 58%;
      }
    }
  }
  .customlesson-finger {
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 49%;
    z-index: 1007;
    cursor: pointer;
    img {
      width: 15%;
    }
  }
  .glow {
    animation: glow 1s infinite alternate;
  }
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px #f4d737;
    }
    to {
      box-shadow: 0 0 10px 10px #f4d737, 0 0 1px 1px #f4d737 inset;
    }
  }
}
@keyframes fadeOutRocket {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOutRocket {
  -webkit-animation: fadeOutRocket 1.5s ease-in forwards;
  -moz-animation: fadeOutRocket 1.5s ease-in forwards;
  animation: fadeOutRocket 1.5s ease-in forwards;
}
</style>
<style lang="scss">
.bubbles {
  position: absolute;
  bottom: -55px;
  left: 50%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.bubble {
  -webkit-animation: short-bubble 1500ms linear infinite;
  animation: short-bubble 1500ms linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  height: 10px;
  width: 9px;
  border: 1px solid rgba(240, 240, 240, 0.5);
  border-radius: 50%;
}

.b2 {
  -webkit-animation: medium-bubble 1600ms linear infinite;
  animation: medium-bubble 1600ms linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin-left: -7px;
  margin-top: 25px;
  height: 13px;
  width: 12px;
}

.b3 {
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
  margin-left: -5px;
  margin-bottom: 10px;
}

.b4 {
  -webkit-animation-duration: 1100ms;
  animation-duration: 1100ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin: 0 0 40px -9px;
  height: 7px;
  width: 6px;
}

.b5 {
  -webkit-animation: medium-bubble 1500ms linear infinite;
  animation: medium-bubble 1500ms linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin-left: -1px;
  margin-top: 100px;
  height: 5px;
  width: 4px;
}

.b6 {
  -webkit-animation-duration: 1100ms;
  animation-duration: 1100ms;
  margin-left: -8px;
  margin-bottom: 50px;
}

.b7 {
  -webkit-animation-duration: 1200ms;
  animation-duration: 1200ms;
  margin-left: -3px;
  margin-bottom: 50px;
  height: 7px;
  width: 6px;
}

.b8 {
  -webkit-animation: medium-bubble 800ms linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation: medium-bubble 800ms linear infinite;
  animation-fill-mode: forwards;
  margin-left: -13px;
  margin-bottom: 30px;
}

@-webkit-keyframes short-bubble {
  0% {
    -webkit-transform: translate3d(5px, 0, 0);
    opacity: 0;
  }
  13% {
    -webkit-transform: translate3d(2px, 20px, 0);
    opacity: 1;
  }
  20% {
    -webkit-transform: translate3d(5px, 40px, 0) scale(1.2);
  }
  30% {
    -webkit-transform: translate3d(0, 60px, 0);
  }
  40% {
    -webkit-transform: translate3d(8px, 80px, 0);
  }
  50% {
    -webkit-transform: translate3d(3px, 100px, 0);
  }
  60% {
    -webkit-transform: translate3d(8px, 120px, 0);
  }
  70% {
    opacity: 0.4;
  }
  80%,
  100% {
    -webkit-transform: translate3d(0, 200px, 0);
    opacity: 0;
  }
}

@keyframes short-bubble {
  0% {
    transform: translate3d(5px, 0, 0);
    opacity: 0;
  }
  13% {
    transform: translate3d(2px, 20px, 0);
    opacity: 1;
  }
  20% {
    transform: translate3d(5px, 40px, 0) scale(1.2);
  }
  30% {
    transform: translate3d(0px, 60px, 0);
  }
  40% {
    transform: translate3d(8px, 80px, 0);
  }
  50% {
    transform: translate3d(3px, 100px, 0);
  }
  60% {
    transform: translate3d(8px, 120px, 0);
  }
  70% {
    opacity: 0.4;
  }
  80%,
  100% {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
  }
}

@-webkit-keyframes medium-bubble {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  5% {
    -webkit-transform: translate3d(4px, 20px, 0);
    opacity: 1;
  }
  10% {
    -webkit-transform: translate3d(2px, 40px, 0) rotate(10deg);
  }
  20% {
    -webkit-transform: translate3d(7px, 80px, 0) rotate(14deg) scale(0.7);
  }
  30% {
    -webkit-transform: translate3d(5px, 120px, 0) rotate(15deg);
  }
  40% {
    -webkit-transform: translate3d(1px, 160px, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(2px, 200px, 0) rotate(18deg) scale(1.1);
  }
  60% {
    -webkit-transform: translate3d(-3px, 240px, 0) rotate(22deg);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translate3d(0, 400px, 0);
    opacity: 0;
  }
}

@keyframes medium-bubble {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  5% {
    transform: translate3d(4px, 20px, 0);
    opacity: 1;
  }
  10% {
    transform: translate3d(2px, 40px, 0) rotate(10deg);
  }
  20% {
    transform: translate3d(7px, 80px, 0) rotate(14deg) scale(0.7);
  }
  30% {
    transform: translate3d(5px, 120px, 0) rotate(15deg);
  }
  40% {
    transform: translate3d(1px, 160px, 0) rotate(20deg);
  }
  50% {
    transform: translate3d(2px, 200px, 0) rotate(18deg) scale(1.1);
  }
  60% {
    transform: translate3d(-3px, 240px, 0) rotate(22deg);
    opacity: 0.4;
  }
  100% {
    transform: translate3d(0, 400px, 0);
    opacity: 0;
  }
}
</style>
