var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainCont" },
    [
      _c("div", { staticClass: "background" }, [
        _c("div", { staticClass: "backgroundImg_n_DragArea" }, [
          _c(
            "div",
            { staticClass: "firstLine" },
            _vm._l(6, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox bottombox" },
                [
                  index > 1
                    ? _c("img", {
                        staticClass: "cloud",
                        attrs: { src: _vm.cloudImage },
                      })
                    : _vm._e(),
                  index > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "button",
                          on: {
                            click: function ($event) {
                              return _vm.foundAnswer(index - 2)
                            },
                          },
                        },
                        [
                          _vm.imgIndexArr.indexOf(index - 2) != -1
                            ? _c("img", {
                                staticClass: "font-img",
                                attrs: {
                                  src: _vm.chineseList[index - 2].imageYellow,
                                },
                              })
                            : _c("img", {
                                staticClass: "font-img",
                                class: {
                                  notShow:
                                    !_vm.chineseList[index - 2].showContent,
                                },
                                attrs: {
                                  src: _vm.chineseList[index - 2].image,
                                },
                              }),
                        ]
                      )
                    : _vm._e(),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: { src: _vm.arrowImage },
                  }),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "secondLine" },
            _vm._l(4, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox leftbox" },
                [
                  _c("img", {
                    staticClass: "cloud",
                    attrs: { src: _vm.cloudImage },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "button buttonsLeft",
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(index + 4)
                        },
                      },
                    },
                    [
                      _vm.imgIndexArr.indexOf(index + 4) != -1
                        ? _c("img", {
                            staticClass: "font-img",
                            attrs: {
                              src: _vm.chineseList[index + 4].imageYellow,
                            },
                          })
                        : _c("img", {
                            staticClass: "font-img",
                            class: {
                              notShow: !_vm.chineseList[index + 4].showContent,
                            },
                            attrs: { src: _vm.chineseList[index + 4].image },
                          }),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "thirdLine" },
            _vm._l(5, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox topbox" },
                [
                  _c("img", {
                    staticClass: "cloud",
                    attrs: { src: _vm.cloudImage },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "button buttonTop",
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(index + 8)
                        },
                      },
                    },
                    [
                      _vm.imgIndexArr.indexOf(index + 8) != -1
                        ? _c("img", {
                            staticClass: "font-img",
                            attrs: {
                              src: _vm.chineseList[index + 8].imageYellow,
                            },
                          })
                        : _c("img", {
                            staticClass: "font-img",
                            class: {
                              notShow: !_vm.chineseList[index + 8].showContent,
                            },
                            attrs: { src: _vm.chineseList[index + 8].image },
                          }),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "fourthLine" },
            _vm._l(2, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox rightbox" },
                [
                  index < 2
                    ? _c("img", {
                        staticClass: "cloud",
                        attrs: { src: _vm.cloudImage },
                      })
                    : _vm._e(),
                  index < 2
                    ? _c(
                        "div",
                        {
                          staticClass: "button buttonsRight",
                          on: {
                            click: function ($event) {
                              return _vm.foundAnswer(index + 13)
                            },
                          },
                        },
                        [
                          _vm.imgIndexArr.indexOf(index + 13) != -1
                            ? _c("img", {
                                staticClass: "font-img",
                                attrs: {
                                  src: _vm.chineseList[index + 13].imageYellow,
                                },
                              })
                            : _c("img", {
                                staticClass: "font-img",
                                class: {
                                  notShow:
                                    !_vm.chineseList[index + 13].showContent,
                                },
                                attrs: {
                                  src: _vm.chineseList[index + 13].image,
                                },
                              }),
                        ]
                      )
                    : _c("img", {
                        staticClass: "treasure",
                        attrs: {
                          src: [
                            _vm.isLastStep
                              ? _vm.treasureImageOpen
                              : _vm.treasureImage,
                          ],
                        },
                      }),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "wheelWrapper", on: { click: _vm.ClickedWheel } },
            [
              _c("img", {
                staticClass: "wheel",
                attrs: { src: _vm.wheelImage },
              }),
              _c(
                "div",
                {
                  staticClass: "pointerWrapper",
                  class: {
                    rotateAnimation1:
                      _vm.isStartAnimation && _vm.wheelPosition === 0,
                    rotateAnimation2:
                      _vm.isStartAnimation && _vm.wheelPosition === 1,
                    rotateAnimation3:
                      _vm.isStartAnimation && _vm.wheelPosition === 2,
                    rotateAnimation4:
                      _vm.isStartAnimation && _vm.wheelPosition === 3,
                    rotateAnimation5:
                      _vm.isStartAnimation && _vm.wheelPosition === 4,
                  },
                  style: {
                    transform:
                      "rotate(" +
                      _vm.wheelList[_vm.wheelPosition].position +
                      "deg)",
                  },
                },
                [
                  _c("img", {
                    staticClass: "pointer",
                    attrs: { src: _vm.pointerImage },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.answersfound,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }