<template>
  <div class="game-choose-pic-container">
    <div class="game-card-content">
      <div class="title-area" v-if="Object.keys(titleInfo).length">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="cardCont">
        <div class="objects-card" v-for="(item, index) in imgList" :key="index">
          <div
            :class="{
              isFinishMatchOne: item.type === 1,
              isFinishMatchTwo: item.type === 2 || item.type === 4,
              isFinishMatchThree: item.type === 3,
              cardIsActive: item.cardActive == true,
              style1: index % 2 == 0,
              style2: index % 2 != 0
            }"
            class="card"
            @click.stop="foundAnswer(index, item)"
          >
            <img class="objectImage" :src="item.image" />
          </div>
        </div>
      </div>
      <Star :totalStarNumber="starNum" :currentIndex="answersfound" />
      <div v-show="isMatchDisplay === true" class="matchDisplay">
        <div class="objectDisplaycont">
          <div class="objects">
            <div class="card style1">
              <img class="objectImage" :src="objectDisplayFirst" />
            </div>

            <div class="card style2">
              <img class="objectImage" :src="objectDisplaySecond" />
            </div>
          </div>
        </div>
      </div>
      <CommonButton
        @shuffleClickEvent="handleShuffleClickEvent"
      />
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import CommonButton from '../GameComponent/CommonButton.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    CommonButton
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleZh: '',
          titlePy: '',
          choosePicList: []
        }
      }
    }
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20041,
          data: { value },
          text: 'MatchTheCardsGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: 'DragGameBtn shuffleSocketValue'
          }
          this.$bus.$emit('kids_webSocket_sendInfo', data)
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('ClickTheCards', ({ index, item }) => {
      this.foundAnswer(index, item, true)
    })
    this.$bus.$on('shuffleArray', ({ ramdomList }) => {
      this.imgList = []
      this.imgList = ramdomList
      this.imgList = this.imgList.map(item => {
        item.type = 0
        item.cardActive = false
        return item
      })
      this.answersfound = 0
      this.question = 0
      this.firstClick = 0
      this.secondClick = 0

      this.shuffleSocketValue = true
    })
  },
  beforeDestroy() {
    this.$bus.$off('ClickTheCards')
    this.$bus.$off('shuffleArray')
  },
  data() {
    return {
      localData: {
        titleInfo: {
          hanzi: '这是谁的？',
          pinyin: 'Zhè shì shéi de?'
        },
        imgList: [
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G29-match-cards/lesson-41/cat-food.svg'),
            type: 0,
            cardActive: false
          },
          {
            id: 2,
            image: require('@/assets/img/16-GAMES/G29-match-cards/lesson-41/human-food.svg'),
            type: 0,
            cardActive: false
          },
          {
            id: 3,
            image: require('@/assets/img/16-GAMES/G29-match-cards/lesson-41/caterpillar-food.svg'),
            type: 0,
            cardActive: false
          },
          {
            id: 2,
            image: require('@/assets/img/16-GAMES/G29-match-cards/lesson-41/human.svg'),
            type: 0,
            cardActive: false
          },
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G29-match-cards/lesson-41/cat.svg'),
            type: 0,
            cardActive: false
          },
          {
            id: 3,
            image: require('@/assets/img/16-GAMES/G29-match-cards/lesson-41/caterpillar.svg'),
            type: 0,
            cardActive: false
          }
        ],
        starNum: 3
      },
      titleInfo: {},
      imgList: [],
      starNum: null,
      answersfound: 0,
      question: 0,
      isMatchDisplay: false,
      firstClick: 0,
      secondClick: 0,
      firstClickIndex: 0,
      isLastStep: false,
      objectDisplayFirst: null,
      objectDisplaySecond: null,
      clickSocketInfo: {},
      shuffleSocketInfo: {},
      shuffleSocketValue: null,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      }
    }
  },
  methods: {
    /**
     * @description: 乱序回调
     * @return {*}
     */
    handleShuffleClickEvent() {
      const orderList = this.shuffleArray(this.imgList)
      this.imgList = []
      this.imgList = orderList

      this.shuffleSocketValue = false
      this.answersfound = 0
      this.firstClick = 0
      this.secondClick = 0
      this.question = 0
      this.imgList = this.imgList.map(item => {
        item.type = 0
        item.cardActive = false
        return item
      })
      this.shuffleSocketInfo = {
        ramdomList: this.imgList,
        ramdomIndex: Math.random()
      }
    },
    initData() {
      // console.log(this.componentProps,'--')
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.titleInfo = {
          pinyin: this.componentProps.titlePy,
          hanzi: this.componentProps.titleZh
        }
      }
      this.imgList = []
      if (
        this.componentProps.choosePicList &&
        this.componentProps.choosePicList.length
      ) {
        this.componentProps.choosePicList.forEach((item, index) => {
          if (item && item.length) {
            item[0].value.forEach((ele) => {
              this.imgList.push({
                id: index + 1,
                image: ele.value,
                type: 0,
                cardActive: false
              })
            })
          }
        })
        this.starNum = this.componentProps.choosePicList.length
      }
      // console.log(this.imgList)
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    foundAnswer(index, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          index,
          item,
          value: Math.random()
        }
      }
      if (this.firstClick == 0) {
        this.firstClick = item.id
        this.firstClickIndex = index
        // console.log(this.firstClickIndex)
        this.imgList[index].cardActive = true
        return
      } else {
        this.secondClick = item.id
      }
      if (
        this.secondClick == this.firstClick &&
        this.firstClickIndex !== index
      ) {
        //correct match
        this.objectDisplayFirst = this.imgList[this.firstClickIndex].image
        this.objectDisplaySecond = this.imgList[index].image
        this.isMatchDisplay = true
        this.answersfound++
        this.imgList[index].type = this.answersfound
        this.imgList[index].cardActive = false
        this.imgList[this.firstClickIndex].type = this.answersfound
        this.imgList[this.firstClickIndex].cardActive = false
        playCorrectSound(true, false)

        setTimeout(() => {
          this.isMatchDisplay = false
          this.firstClick = 0
          this.secondClick = 0
          this.firstClickIndex = 0

          if (this.question < this.imgList.length / 2 - 1) {
            this.question++
          } else {
            this.isLastStep = true
            playCorrectSound()
            startConfetti()
          }
        }, 1000)
      } else {
        playCorrectSound(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-choose-pic-container {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-card-content {
    background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 6%;
      // width: 14%;
      // height: 12%;
      padding: 2% 5%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: #fff;

      &.small {
        width: 15%;
      }
      &.zindex-0 {
        z-index: 0;
      }

      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
          .font-pinyin-medium {
            font-size: 12px;
          }
          .font-hanzi-medium {
            font-size: 20px;
          }
        }
      }
    }
    .cardCont {
      height: 80%;
      width: 80%;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      align-content: space-evenly;

      .objects-card {
        width: 30%;
        height: 45%;

        .card {
          background-color: #ffffff;
          box-shadow: 0 10px 0 0 #cfc8b0;
          border-radius: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 80%;
          width: 100%;
          .objectImage {
            width: 100%;
            max-height: 100%;
          }
        }

        .cardIsActive {
          border: 10px solid #cd4c3f !important;
          box-shadow: 0 10px 0 0 #cd4c3f;

          border-radius: 40px;
          box-sizing: border-box;
        }

        .style1 {
          transform: rotate(7deg);
        }
        .style2 {
          transform: rotate(-3deg);
        }
        .isFinishMatchOne {
          border-bottom: 10px solid #cd4c3f !important;
          box-shadow: none;
        }
        .isFinishMatchTwo {
          border-bottom: 10px solid #224e96 !important;
          box-shadow: none;
        }
        .isFinishMatchThree {
          border-bottom: 10px solid #f4d737 !important;
          box-shadow: none;
        }
      }
      .lesson-27 {
        width: 34%;
      }
    }

    .matchDisplay {
      width: 100%;
      height: 100%;
      position: absolute;
      .objectDisplaycont {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(159, 150, 150, 0.4);
        border-radius: 48px;
        top: 0;
        display: flex;
        flex-direction: row;

        .objects {
          position: relative;
          width: 55%;
          display: flex;
          flex-direction: row;
          align-self: center;
          margin-left: 20%;
          // background-color: black;

          .card {
            height: 90%;
            width: 80%;
            margin-left: 10%;
            background-color: #ffffff;
            border-top: 5px solid #cd4c3f;
            border-left: 5px solid #cd4c3f;
            border-right: 5px solid #cd4c3f;
            border-bottom: 10px solid #cd4c3f;
            border-radius: 20px;
            display: flex;
            align-items: center;
            .objectImage {
              width: 100%;
              max-height: 100%;
            }
          }

          .style1 {
            transform: rotate(7deg);
            //  border-right: 3px solid #CFC8B0;
          }
          .style2 {
            transform: rotate(-7deg);
          }
        }

        .displayObjects {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
        }
      }
    }
    .common-button-container {
      position: absolute;
      bottom: 3px;
      right: 0;
      height: 20%;
      max-height: 160px;
    } 
  }
}
</style>
