<template>
  <div class="nk-select-container">
    <el-select
      v-model="selectedValue"
      :placeholder="placeholder"
      @change="(e) => handleChange(e)"
      :disabled="disabled"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: null,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    typeString: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    handleChange(e) {
      // console.log(e);
      this.$emit("input", e);
      this.$emit("selectChagne", this.typeString);
    },
  },
};
</script>

<style lang="scss" scoped>
.nk-select-container::v-deep {
  .el-select {
    .el-input__inner {
      height: 35px;
      line-height: 35px;
      font-size: 12px;
    }
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-input__icon {
          height: 110% !important;
        }
        .is-reverse {
          line-height: 20px;
        }
      }
    }
    .el-input__suffix {
      .el-icon-arrow-up {
        font-weight: 700;
      }
    }
  }
}
</style>
<style lang="scss">
.el-select-dropdown {
  .el-select-dropdown__item {
    font-size: 12px;
  }
}
</style>
