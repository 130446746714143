<template>
  <div class="recognition-pinyin-container">
    <div class="main-content">
      <div class="game-box">
        <Star
          :totalStarNumber="totalStarNum"
          :currentIndex="starNum"
          v-if="totalStarNum"
        />
        <div class="title-area" v-if="Object.keys(titleInfo).length">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>
        <!-- isCover: backgroundSize == 'cover', -->
        <div class="game-body">
          <img
            class="bgIamge"
            v-if="optionList[currentIndex] && optionList[currentIndex].image"
            :class="{
              fadeOut: isFadeOut,
              fadeIn: isFadeIn
            }"
            :src="optionList[currentIndex].image"
          />
        </div>
        <div class="bottonCont">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in imgList"
              :key="index"
              class="content-words-two"
              @click="foundAnswer(item.id)"
              :class="{
                bgcolor: !item.nobgcolor
              }"
            >
              <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
              <img v-if="item.bottomImg" :src="item.bottomImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <CommonButton
        @shuffleClickEvent="handleShuffleClickEvent"
      />
    </div>
  </div>
</template>

<script>
import Star from '@/components/Course/GamePage/Star'
import CommonButton from "../GameComponent/CommonButton.vue";
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import { startConfetti, playCorrectSound } from '@/utils/tools'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star,
    AudioPlayer,
    CommonButton
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          titleZh: '',
          titlePy: '',
          buttonList: []
        }
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('RecognitionPinyinClick', ({ index }) => {
      this.foundAnswer(index, true)
    })
    this.$bus.$on("shuffleArray", ({ ramdomList }) => {
      this.optionList = [];
      this.optionList = ramdomList;
      this.shuffleSocketValue = true;
      this.starNum = 0
      this.currentIndex = 0
    });
  },
  beforeDestroy() {
    this.$bus.$off('RecognitionPinyinClick')
    this.$bus.$off('shuffleArray')
  },
  watch: {
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    },
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20021,
          data: { value },
          text: 'SpeakingSentence点击Img socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    shuffleSocketInfo: {
      handler(value) {
        if (!this.shuffleSocketValue) {
          const data = {
            clickType: 2001004,
            data: { value },
            text: "DragGameBtn shuffleSocketValue",
          };
          this.$bus.$emit("kids_webSocket_sendInfo", data);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      optionList: [],
      starNum: 0,
      titleInfo: {},
      currentIndex: 0,
      isFadeOut: false,
      isFadeIn: false,
      imgList: [],
      selectImageVisibility: [],
      totalStarNum: 0,
      shuffleSocketValue: null,
      socketInfo: {},
      shuffleSocketInfo: {},
      localData: {
        totalStarNum: 5,
        optionList: [
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-1.svg')
          },
          {
            id: 2,
            image: require('@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-2.svg')
          },
          {
            id: 3,
            image: require('@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-3.svg')
          },
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-4.svg')
          },
          {
            id: 1,
            image: require('@/assets/img/16-GAMES/G23-choose-abc/lesson-19/image-5.svg')
          }
        ],
        titleInfo: {
          pinyin: '…… shì  nǎ guó rén?',
          hanzi: '……是哪国人？'
        },
        imgList: [
          {
            id: 2,
            pinying: 'Jiānádà rén',
            hanzi: '加拿大人'
          },
          {
            id: 1,
            pinying: 'Zhōngguó rén',
            hanzi: '中国人'
          },
          {
            id: 3,
            pinying: 'Měiguó rén',
            hanzi: '美国人'
          }
        ]
      }
    }
  },
  methods: {
      /**
     * @description: 乱序回调
     * @return {*}
     */
     handleShuffleClickEvent() {
      const orderList = this.shuffleArray(this.optionList.flat());
      this.optionList = [];
      this.optionList = orderList;

      this.shuffleSocketValue = false;
      this.starNum = 0
      this.currentIndex = 0

      this.shuffleSocketInfo = {
        ramdomList: this.optionList,
        ramdomIndex: Math.random(),
      };
    },
    initData() {
      if (this.componentProps.titleZh || this.componentProps.titlePy) {
        this.titleInfo = {
          pinyin: this.componentProps.titlePy,
          hanzi: this.componentProps.titleZh
        }
      }
      this.optionList = []
      this.imgList = []
      if (
        this.componentProps.buttonList &&
        this.componentProps.buttonList.length
      ) {
        this.componentProps.buttonList.forEach((item, index) => {
          this.imgList.push({
            id: index + 1,
            pinying: item[1].value,
            hanzi: item[0].value,
            scrList: item[2].value
          })
        })
        // console.log(this.imgList, 'img---')
        this.imgList.forEach((item) => {
          if (item.scrList && item.scrList.length) {
            item.scrList = item.scrList.map((ele) => {
              return {
                id: item.id,
                image: ele.value
              }
            })
            this.optionList.push(item.scrList)
          }
        })
        this.optionList = this.optionList.flat()
        this.totalStarNum = this.optionList.length

        // console.log(this.optionList, ' this.optionList')
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    foundAnswer(index, isFromSocket = false) {
      if (this.starNum >= this.optionList.length) {
        return
      }
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random()
        }
      }
      // console.log(index)
      if (this.optionList[this.currentIndex].id === index) {
        playCorrectSound(true, false)
        this.starNum++
        setTimeout(() => {
          if (this.currentIndex + 1 < this.optionList.length) {
            //first trigger the fadeout
            this.isFadeOut = true
            setTimeout(() => {
              this.currentIndex++
              // this.shuffleSelectionImage()
              this.isFadeIn = true
              this.isFadeOut = false
            }, 500)
            setTimeout(() => {
              this.isFadeIn = false
            }, 1000)
            this.songUrl = this.imgList[this.currentIndex + 1].audioSrc
          } else {
            playCorrectSound()
            startConfetti()
            this.isLastStep = true
          }
        }, 1000)
      } else {
        playCorrectSound(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recognition-pinyin-container {
  width: 100%;
  height: 100%;
  position: relative;
  .main-content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    .game-box {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f6e6;
      border-radius: 48px;
      .title-area {
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        top: 10%;
        width: 30%;
        height: 17%;
        background: #cd4c3f;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        color: #fff;
        .title {
          .title-pinyin,
          .title-hanzi {
            text-align: center;
          }
        }
      }
    }
    .game-body {
      position: relative;
      width: -moz-fit-content;
      width: 100%;
      height: 100%;
      justify-content: center;
      z-index: 0;
      border-radius: 10%;
      display: flex;
      max-height: 80%;

      .bgIamge {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 48px;

        &.isCover {
          object-fit: cover !important;
        }
      }
    }
    .bottonCont {
      bottom: 0;
      width: 80%;
      height: 150px;
      position: absolute;
      display: flex;
      justify-content: center;
      margin-left: 11%;

      .redBackground {
        width: 65%;
        height: 70%;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background-color: #d87065;
        position: absolute;
        bottom: 0;
      }
      .bottonsWrapper {
        display: flex;
        bottom: 0;
        height: -moz-fit-content;
        justify-content: space-around;
        height: 100%;
        padding: 20px;
        position: absolute;
        padding: 10px 30px;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        background-color: #ffffff;
        align-items: center;
      }
    }
    .bgcolor {
      background-color: #ce524c;
      border-bottom: 10px solid #a53d32;
      border-top: 10px solid #d87065;
      padding: 10px 30px;
      box-sizing: border-box;
      // max-width: 150px;
    }
    .bgcolor:hover {
      background-color: rgb(
        200,
        82,
        76
      ); // TODO : adjust hover color based on instructions (pending)
    }
    .content-words-two {
      min-width: 120px;
      min-height: 100px;
      z-index: 1;
      border-radius: 20px;

      display: flex;
      flex-flow: column;
      justify-content: center;

      align-items: center;
      margin: 0 10px;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 25px;
        white-space: nowrap;
      }
      span:nth-child(2) {
        color: #fff;
        font-size: 30px;
      }
      img {
        max-width: 80%;
      }
    }
    .objectDisplay {
      position: absolute;
      height: 100%;
      //  bottom: 16%;
      //  left: 25%;
      width: 100%;
      z-index: 9999;
    }
    .soundIcon {
      position: absolute;
      width: 10%;
      top: 45%;
      left: 45%;
      cursor: pointer;
    }
    .speaker-wrapper {
      position: absolute;
      top: calc(55% - 35px);
      left: calc(50% - 35px);
      width: 70px;
      height: 70px;
      background-color: #cd4c3f;
      border-radius: 50%;
      text-align: center;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      .speaker-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .toneImg {
      width: 20% !important;
      left: 40% !important;
      top: -3%;
    }
    .common-button-container {
    position: absolute;
    bottom: 3px;
    right: 0;
    height: 20%;
    max-height: 160px;
    }
  }
}
</style>
