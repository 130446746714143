var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-stroke-page-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
        nativeOn: {
          click: function ($event) {
            return _vm.startDrawStroke.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "stroke-page-box" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm.isShowEye
            ? _c(
                "div",
                {
                  staticClass: "write-stroke-page-eye-btn",
                  on: {
                    mouseover: function ($event) {
                      _vm.eyeIsHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.eyeIsHover = false
                    },
                    click: _vm.handleClickEyeButton,
                  },
                },
                [
                  !_vm.eyeIsHover
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"),
                          alt: "",
                        },
                      }),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "write-stroke-content" },
            [
              _c("transition", { attrs: { name: "el-fade-in" } }, [
                _c("div", { staticClass: "write-stroke-area" }, [
                  _c("div", { staticClass: "write-stroke-content-left" }, [
                    _c("div", { staticClass: "write-stroke-left-content" }, [
                      _c("div", { staticClass: "left-content-border" }),
                      _c("div", { staticClass: "left-content" }, [
                        _c(
                          "span",
                          { staticClass: "pinyin font-pinyin-large" },
                          [
                            _vm._v(
                              _vm._s(_vm.strokeArr[_vm.numBers - 1].pinyin)
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "stroke-area",
                            on: {
                              mousedown: function ($event) {
                                return _vm.startDrawing()
                              },
                              mouseup: function ($event) {
                                return _vm.endDrawing($event)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "hanzi-img" },
                              [
                                _c("KidsWhiteBoard", { ref: "drawArea" }),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.drawing,
                                      expression: "drawing",
                                    },
                                  ],
                                  staticClass: "WritingStrokeArea",
                                  on: { click: _vm.draggedOutOfStroke },
                                }),
                                _c("div", { staticClass: "square" }, [
                                  _c("img", {
                                    staticClass: "stroke-square-img",
                                    attrs: {
                                      src: require("@/assets/img/15-Hanzi-strokes/background.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "hengIcon" },
                                  [
                                    _c("img", {
                                      staticClass: "stroke-square-img",
                                      attrs: {
                                        src: _vm.strokeArr[_vm.numBers - 1].img,
                                        alt: "",
                                      },
                                    }),
                                    _vm._l(
                                      _vm.strokeImgFill[_vm.numBers - 1],
                                      function (item, index) {
                                        return _c("img", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.showArr.indexOf(
                                                  index + 1
                                                ) != -1,
                                              expression:
                                                "showArr.indexOf(index+1)!=-1",
                                            },
                                          ],
                                          key: index,
                                          staticClass: "stroke-square-img",
                                          attrs: { src: item.image, alt: "" },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "write-stroke-content-right" }, [
                    _c(
                      "div",
                      { staticClass: "write-stroke-content-right_img" },
                      _vm._l(_vm.backgroundImageList, function (item, index) {
                        return _c(
                          "div",
                          { key: index + "bg", staticClass: "item" },
                          [
                            item.step === _vm.steps
                              ? _c("img", {
                                  staticClass: "bgImg",
                                  attrs: { src: item.img, alt: "" },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "bottom-content" },
                _vm._l(
                  _vm.strokeImgFill[_vm.numBers - 1],
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "bottom-item" },
                      [
                        item.show
                          ? _c("img", {
                              attrs: { src: item.ButtomImage },
                              on: {
                                mousedown: function ($event) {
                                  return _vm.strokeImgClick(item, index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }