var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "teitter-game-container layout-border",
      on: { click: _vm.handleClickScreen },
    },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("SceneSwitchThumbnails", {
        attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
        on: { changeTheNumbers: _vm.changeTheNumbers },
      }),
      _c("div", { staticClass: "game-content" }, [
        _vm._m(0),
        _vm.title
          ? _c("div", { staticClass: "page-title" }, [
              _c(
                "span",
                { staticClass: "font-text pinyin txt-py font-pinyin-medium" },
                [_vm._v(_vm._s(_vm.title.pinyin))]
              ),
              _c("span", { staticClass: "txt-hz font-hanzi-medium" }, [
                _vm._v(_vm._s(_vm.title.hanzi)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "twitter-area" }, [
          _c(
            "div",
            { staticClass: "twitter-img-area" },
            [
              _vm._l(_vm.gameList, function (item, index) {
                return [
                  index === _vm.currentIndex
                    ? _c(
                        "div",
                        { key: index, staticClass: "img-item" },
                        [
                          _c("transition", { attrs: { name: "el-fade-in" } }, [
                            _vm.currentStepIndex === 0
                              ? _c("img", {
                                  attrs: { src: item.originImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm.currentStepIndex === 1
                              ? _c("img", {
                                  attrs: { src: item.clickImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm.currentStepIndex === 2
                              ? _c("img", {
                                  attrs: { src: item.wordsImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm.currentStepIndex === 3
                              ? _c("img", {
                                  attrs: { src: item.heartImg, alt: "" },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "click-area" }, [
          _c("div", {
            staticClass: "heart-shape",
            on: { click: _vm.handleClickHeart },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "back-ground-img-area" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/03-Backgrounds/background-practice.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }