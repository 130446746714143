var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rocket-review-game-container" }, [
    _c("audio", { ref: "audioBox", attrs: { src: _vm.audioSrc } }),
    _c("div", { staticClass: "customlesson-content-five position-relative" }, [
      _c(
        "div",
        {
          ref: "backgroundArea",
          staticClass: "rocket-bg-area",
          attrs: { id: "space-background-scroll" },
        },
        [_c("img", { ref: "content", attrs: { src: _vm.bgImg, alt: "" } })]
      ),
      _c(
        "div",
        {
          ref: "rocketTop",
          staticClass: "rocket-area",
          class: {
            fadeOutRocket: _vm.fadeOutRocket,
            fixPosition: _vm.startMove,
          },
        },
        [
          _vm.gameType === 1
            ? _c("div", { staticClass: "submarine" }, [
                _c("img", {
                  staticClass: "rocket-img",
                  class: { isHidden: _vm.animationFrame2 != 1 },
                  attrs: {
                    src: require("@/assets/img/FixedPicture/Submarine/submarine.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  staticClass: "rocket-img",
                  class: { isHidden: _vm.animationFrame2 != 2 },
                  attrs: {
                    src: require("@/assets/img/FixedPicture/Submarine/submarine-2.svg"),
                    alt: "",
                  },
                }),
                _c("img", {
                  staticClass: "rocket-img",
                  class: { isHidden: _vm.animationFrame2 != 3 },
                  attrs: {
                    src: require("@/assets/img/FixedPicture/Submarine/submarine-3.svg"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
          _vm.gameType === 2
            ? _c("div", { staticClass: "submarine" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/FixedPicture/Submarine/airship.svg"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "bubbles",
              class: { isHidden: _vm.hasReachedStation },
            },
            [
              _c("img", {
                staticClass: "bubble b2",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble b5",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble b6",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble b3",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble b7",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble b4",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
              _c("img", {
                staticClass: "bubble b8",
                attrs: {
                  src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                },
              }),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "control-area" }, [
        _c("img", {
          staticClass: "panel-img",
          attrs: {
            src:
              _vm.gameType === 1
                ? require("@/assets/img/FixedPicture/Submarine/submarine-panel-no-button.svg")
                : require("@/assets/img/FixedPicture/Submarine/control.svg"),
            alt: "",
          },
        }),
        _c(
          "div",
          {
            staticClass: "control-area-button glow",
            class: {
              isHover: !_vm.hasReachedStation && _vm.steps != 0,
              lighter: _vm.gameType === 2,
            },
            on: { click: _vm.controlClick },
          },
          [
            _c("img", {
              attrs: {
                src:
                  _vm.gameType === 1
                    ? require("@/assets/img/FixedPicture/Submarine/submarine-control-button.svg")
                    : require("@/assets/img/FixedPicture/Submarine/control-button.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
    ]),
    false
      ? _c("div", { staticClass: "customlesson-finger" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/16-GAMES/G09-rocket/hand.svg"),
              alt: "",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }