var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawing-group-page-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
        nativeOn: {
          click: function ($event) {
            return _vm.handleWrite.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: {
              buttons: _vm.buttonList,
              numBers: _vm.currentIndex,
              isDrawingPage: true,
            },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "img-area" }, [
            _c("img", {
              attrs: { src: _vm.bgImgList[_vm.currentIndex].image, alt: "" },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }