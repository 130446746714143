<template>
  <div class="collapse-container">
    <el-collapse accordion>
      <div
        class="collapse-item"
        v-for="(item, index) in reviewLesson"
        :key="index"
      >
        <div class="lesson-img">
          <img
            src="@/assets/img/07-Stars/star-small.svg"
            alt=""
            v-if="item.status"
          />
          <img src="@/assets/img/19-Dashboard/lock.svg" alt="" v-else />
        </div>
        <el-collapse-item
          :title="item.lessonTitle"
          :name="index"
          class="collapse-content"
          :class="{ active: item.status === false }"
          :disabled="!item.status"
        >
          <div class="review-class-nav">
            <div class="function">
              <div class="image">
                <img src="@/assets/img/19-Dashboard/icon-goals.svg" alt="" />
              </div>
              <div class="title font-menu-large">Goals</div>
            </div>
            <div class="function">
              <div class="image">
                <img src="@/assets/img/19-Dashboard/icon-review.svg" alt="" />
              </div>
              <div class="title font-menu-large">Review</div>
            </div>
            <div class="function">
              <div class="image">
                <img src="@/assets/img/19-Dashboard/icon-homework.svg" alt="" />
              </div>
              <div class="title font-menu-large">Homework</div>
            </div>
            <div class="function" v-if="item.reviewClassify === 0">
              <div class="image">
                <img src="@/assets/img/19-Dashboard/icon-song.svg" alt="" />
              </div>
              <div class="title font-menu-large">Song</div>
            </div>
            <div class="function" v-else>
              <div class="image">
                <img src="@/assets/img/19-Dashboard/icon-writing.svg" alt="" />
              </div>
              <div class="title font-menu-large">Writing Sheet</div>
            </div>
            <div class="function">
              <div class="image">
                <img
                  src="@/assets/img/19-Dashboard/icon-evaluation.svg"
                  alt=""
                />
              </div>
              <div class="title">Evaluation</div>
            </div>
          </div>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "Collapse",
  props: {
    reviewLesson: {
      type: Array,
      require: true,
    },
  },
  computed: {
    lessonList() {
      return this.reviewLesson;
    },
  },
};
</script>

<style lang="scss" scoped>

@import "./index.scss";
</style>
