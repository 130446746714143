<template>
  <div class="courseware-editor-container customScrollbar">
    <div class="header">
      <img src="@/assets/img/login/logo-login.svg" alt="" @click="handleJump" />
      <div class="nav-area">
        <div class="editor-nav-item">STANDARD CONTENT</div>
      </div>
    </div>
    <div class="editor-content">
      <RouterView />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleJump() {
      this.$router.push('/course/calendarEventId=12345/lesson1')
    }
  }
}
</script>
<style lang="scss" scoped>
.courseware-editor-container {
  height: 100%;
  width: 100%;
  .header {
    width: calc(100% - 60px);
    position: relative;
    height: 40px;
    padding: 10px 30px;
    background: #2472b3;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 40px;
      position: absolute;
      left: 30px;
    }
    .nav-area {
      display: flex;
      margin: 0 10px;
      color: #f6c752;
      font-size: 14px;
      font-weight: 600;
      font-family: 'MontserratLight';
    }
  }
  .editor-content {
    padding: 20px 30px;
    width: calc(100% - 60px);
  }
}
</style>
