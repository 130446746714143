var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "family-tree-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("div", { staticClass: "game-content" }, [
        _c("div", { staticClass: "backGroundImg-area" }, [
          _c("img", { attrs: { src: _vm.gameInfo.bgImg, alt: "" } }),
        ]),
        !_vm.isSpeakingPage || _vm.showTitle
          ? _c(
              "div",
              {
                staticClass: "title-area",
                class: { large: _vm.titleLength == "long" },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "title-pinyin pinyin",
                      class: [
                        _vm.titleLength == "long"
                          ? "font-pinyin-small"
                          : "font-pinyin-medium",
                      ],
                    },
                    [_vm._v(" " + _vm._s(_vm.gameInfo.titlePinyin) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "title-hanzi ",
                      class: [
                        _vm.titleLength == "long"
                          ? "font-hanzi-small"
                          : "font-hanzi-medium",
                      ],
                    },
                    [_vm._v(" " + _vm._s(_vm.gameInfo.titleHanzi) + " ")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        !_vm.isSpeakingPage
          ? _c("div", { staticClass: "drag-area" }, [
              _c("div", { staticClass: "tree-avatar-area" }, [
                _c(
                  "div",
                  {
                    staticClass: "tree-arae",
                    class: "lesson-" + _vm.lessonNum,
                  },
                  [
                    _c("div", { staticClass: "people-item" }, [
                      _c("div", { staticClass: "avatar" }, [
                        _c("img", {
                          staticClass: "avatar-img self-img",
                          attrs: { src: _vm.gameInfo.selfImg, alt: "" },
                        }),
                        _c("div", { staticClass: "name" }, [
                          _c("div", { staticClass: "pinyin pinyin-name" }, [
                            _vm._v(" " + _vm._s(_vm.gameInfo.selfPinyin) + " "),
                          ]),
                          _c("div", { staticClass: "hanzi-name" }, [
                            _vm._v(_vm._s(_vm.gameInfo.selfHanzi)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._l(_vm.optionsList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "people-item",
                          class: "person-" + (index + 1),
                          on: {
                            drop: function ($event) {
                              $event.stopPropagation()
                              return _vm.dropToAvatar($event, item.id)
                            },
                            dragover: function ($event) {
                              $event.stopPropagation()
                              return _vm.allowDrop($event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "avatar" }, [
                            _c("div", { staticClass: "border" }, [
                              _c("img", {
                                staticClass: "avatar-img",
                                class: {
                                  dispear:
                                    _vm.dispearIdList.indexOf(item.id) === -1,
                                },
                                attrs: { src: item.bgImg, alt: "" },
                              }),
                            ]),
                            _c("div", { staticClass: "name" }, [
                              _c("div", { staticClass: "pinyin pinyin-name" }, [
                                _vm._v(_vm._s(item.pinyin)),
                              ]),
                              _c("div", { staticClass: "hanzi-name" }, [
                                _vm._v(_vm._s(item.hanzi)),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "avatar-area" },
                  _vm._l(_vm.sentenceList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "avatar-item",
                        class: "avatar-" + index,
                        attrs: { draggable: "true" },
                        on: {
                          dragstart: function ($event) {
                            $event.stopPropagation()
                            return _vm.dragAvatar($event, item.id)
                          },
                          drop: function ($event) {
                            $event.stopPropagation()
                            return _vm.Sdrop($event)
                          },
                          dragend: function ($event) {
                            $event.stopPropagation()
                            return _vm.dragend()
                          },
                        },
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.dispearIdList.indexOf(item.id) === -1,
                              expression:
                                "dispearIdList.indexOf(item.id) === -1",
                            },
                          ],
                          class: { notShow: _vm.dragId === item.id },
                          attrs: { src: item.bgImg, alt: "" },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "bottom-words-area" }, [
          _c(
            "div",
            { staticClass: "words-box" },
            _vm._l(_vm.sentenceList, function (item, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showHnaziId === item.id,
                      expression: "showHnaziId === item.id",
                    },
                  ],
                  key: index,
                  staticClass: "hanzi-item",
                },
                [
                  _c(
                    "div",
                    { staticClass: "pinyin sentence-hanzi font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "sentence-hanzi font-hanzi-medium" },
                    [_vm._v(_vm._s(item.hanzi))]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }