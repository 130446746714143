var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c(
        "div",
        { staticClass: "mainCont" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.totalStars,
              currentIndex: _vm.answersfound,
            },
          }),
          _c(
            "div",
            {
              staticClass: "background",
              class: { backgroundBlue: _vm.background == "blue" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "game-content",
                  class: { "number-bg-color": !_vm.isNumberGame },
                },
                [
                  _c("div", { staticClass: "game-left-box" }, [
                    _c(
                      "div",
                      { staticClass: "game-aside-content" },
                      _vm._l(
                        _vm.asideElementInfo.leftList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "item-content",
                              class: {
                                "item-content-number": _vm.isNumberGame,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item.id)
                                },
                              },
                            },
                            [_c("img", { attrs: { src: item.image } })]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                  _vm._l(_vm.questionInfoList, function (item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.question === index,
                            expression: "question === index",
                          },
                        ],
                        key: index + "question",
                        staticClass: "game-center-box",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "font-pinyin-large pinyin" },
                          [_vm._v("qing")]
                        ),
                        _vm.isShowImg
                          ? _c("img", {
                              staticClass: "objectDisplay fadeIn",
                              attrs: { src: item.bgImg2 },
                            })
                          : _c("img", {
                              staticClass: "objectDisplay fadeIn",
                              attrs: { src: item.bgImg },
                            }),
                        _c("div", { staticClass: "number-area" }),
                      ]
                    )
                  }),
                  _c("div", { staticClass: "game-right-box" }, [
                    _c(
                      "div",
                      { staticClass: "game-aside-content" },
                      _vm._l(
                        _vm.asideElementInfo.rightList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "item-content",
                              class: {
                                "item-content-number": _vm.isNumberGame,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.foundAnswer(item.id)
                                },
                              },
                            },
                            [_c("img", { attrs: { src: item.image } })]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }