var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mirror-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.mirrorImgList.length,
              currentIndex: _vm.startNum,
            },
          }),
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bg-area" },
            [
              !_vm.isAllSecreen
                ? _c("img", {
                    staticClass: "bg-img",
                    attrs: { src: _vm.bgImg, alt: "" },
                  })
                : _vm._e(),
              _vm._l(_vm.mirrorImgList, function (item, index) {
                return _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentImgIndex === index,
                      expression: "currentImgIndex === index",
                    },
                  ],
                  key: index + "img",
                  staticClass: "mirror-img",
                  class: { allScreen: _vm.isAllSecreen },
                  attrs: { src: item.imgSrc },
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "option-area" },
            _vm._l(_vm.optionList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "option-item",
                  class: "lesson-" + _vm.lessonNo,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "word-content",
                      class: { leftBox: index === 0, rightBox: index === 1 },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCheckAnswer(item.id)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("div", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _vm.isShowCheckGif
            ? _c("div", {
                directives: [
                  {
                    name: "checkgif",
                    rawName: "v-checkgif",
                    value: _vm.checkGif,
                    expression: "checkGif",
                  },
                ],
                staticClass: "checkGif",
                class: { checkGifWrong: !_vm.checkGif },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }