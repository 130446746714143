var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "click-reveal-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("div", { staticClass: "game-content" }, [
        _c(
          "div",
          { staticClass: "backGroundImg-area" },
          [
            _c("img", {
              staticClass: "bg-img",
              attrs: { src: _vm.gameInfo.bgImg, alt: "" },
            }),
            _vm._l(_vm.optionsImgList, function (item, index) {
              return _c("div", { key: index, staticClass: "img-item" }, [
                index === _vm.clickIndex
                  ? _c("img", { attrs: { src: item.bgImg, alt: "" } })
                  : _vm._e(),
              ])
            }),
          ],
          2
        ),
        _c("div", { staticClass: "title-area" }, [
          _c("div", { staticClass: "title" }, [
            _c(
              "div",
              { staticClass: "title-pinyin pinyin font-pinyin-medium" },
              [_vm._v(" " + _vm._s(_vm.gameInfo.titlePinyin) + " ")]
            ),
            _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
              _vm._v(" " + _vm._s(_vm.gameInfo.titleHanzi) + " "),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "click-area" },
          [
            _vm._l(_vm.optionsImgList, function (item, index) {
              return [
                _vm.clickdeIndexArr.indexOf(index) === -1
                  ? _c("div", {
                      key: index,
                      staticClass: "click-item",
                      class: "area-" + index,
                      on: {
                        click: function ($event) {
                          return _vm.handleClickPeople(item.index)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }