var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recognition-page-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c(
      "div",
      { staticClass: "recogniton-page-box" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
        }),
        _c("audio", { ref: "audioBox", attrs: { src: _vm.songUrl } }),
        _c("div", { staticClass: "recogniton-page-content layout-red-bgc" }, [
          _c("div", { staticClass: "recognition-content-left" }, [
            _c("img", {
              staticClass: "santa-claus",
              attrs: { src: _vm.backgroundImage, alt: "" },
            }),
          ]),
          _c("div", { staticClass: "recogniton-content-right" }, [
            _c(
              "div",
              { staticClass: "recognize_container_content_right_border" },
              [
                _c(
                  "div",
                  { staticClass: "recognize_container_content_right_content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "recognize_container_content_right_content_top volume-button",
                        class: { "teacher-hover": _vm.isClickAudio },
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.activeClick("socket")
                              },
                            },
                          },
                          [
                            _c("AudioPlayer", {
                              attrs: {
                                isPlay: _vm.isStartPlaying,
                                audioUrl: _vm.songUrl,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.wordObj.pinName != "" && !_vm.wordObj.toneContent
                      ? _c("div", { staticClass: "redColor" }, [
                          _c(
                            "p",
                            { staticClass: "pinyin font-pinyin-xlarge" },
                            [_vm._v(" " + _vm._s(_vm.wordObj.pinName) + " ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "font-hanzi-xlarge",
                              attrs: { id: "face" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.wordObj.chineseName) + " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.wordObj.multiEnglishName
                      ? _c(
                          "div",
                          { staticClass: "englishName multiLines" },
                          _vm._l(
                            _vm.wordObj.multiEnglishName,
                            function (item, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "pinyin",
                                  class: [
                                    _vm.wordObj.hanziClass
                                      ? _vm.wordObj.hanziClass
                                      : "font-hanzi-xlarge",
                                  ],
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }
                          ),
                          0
                        )
                      : _vm.wordObj.pinName != "" &&
                        !_vm.wordObj.toneContent &&
                        !_vm.wordObj.multiEnglishName
                      ? _c("div", { staticClass: "englishName" }, [
                          _c(
                            "p",
                            {
                              class: [
                                _vm.wordObj.englishClass
                                  ? _vm.wordObj.englishClass
                                  : "font-hanzi-medium",
                                _vm.wordObj.pinName ? "" : "pinyin",
                              ],
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.wordObj.englishName) + " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.wordObj.pinName == "" &&
                    !_vm.wordObj.toneContent &&
                    !_vm.wordObj.multiEnglishName
                      ? _c(
                          "div",
                          {
                            staticClass: "englishName",
                            class: { longPinyin: _vm.isLongPinyin },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "pinyin",
                                class: [
                                  _vm.wordObj.englishClass
                                    ? _vm.wordObj.englishClass
                                    : "font-hanzi-xlarge",
                                ],
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.wordObj.englishName) + " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.wordObj.toneContent &&
                    _vm.wordObj.toneContent.length > 0
                      ? _c(
                          "div",
                          { staticClass: "tone_content" },
                          _vm._l(
                            _vm.wordObj.toneContent,
                            function (item, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "pinyin",
                                  class: [
                                    _vm.pySize == "xlarge"
                                      ? "font-hanzi-xlarge"
                                      : "font-hanzi-large",
                                  ],
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }