<template>
  <div class="game-word-sorting-cross-river-container">
    <div class="drag-game-content" ref="moveGameBox">
      <SceneSwitchThumbnails
        v-if="buttonList.length"
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
        :key="key"
      />
      <div class="contentImg">
        <img
          v-show="step !== 2"
          src="@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/mask.svg"
          alt=""
        />
        <img
          v-show="step === 2"
          src="@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/background.svg"
          alt=""
        />
      </div>

      <div class="dogImg" :class="'step-' + step">
        <img
          v-if="step === 1 || step === 0"
          src="@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog.svg"
          alt=""
        />
        <img
          v-if="step === 2"
          src="@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/dog-01.svg"
          alt=""
        />
      </div>
      <div
        v-for="(itemBox, indexBox) in allImgList"
        :key="indexBox + 'allImgList'"
      >
        <div class="picture-envelope" v-if="numBers === indexBox + 1">
          <div class="content-picture-switching">
            <div
              :id="`selectItem-${indexBox}-${item.id}`"
              :class="`select-item selectItem-${index + 1}`"
              v-for="(item, index) in itemBox.imgList"
              :key="index + 'imgList'"
              draggable="true"
              @dragstart.stop="drag($event, item)"
              @drop.stop="drop($event)"
              @dragend="handleDragend"
            >
              <div class="select-content">
                <img
                  class="switchImg"
                  :src="item.bgImg"
                  alt=""
                  :class="
                    ({
                      dispear: item.isDispear,
                      'drag-img-envelope-4': dragNum === 4,
                    },
                    'switchImg' + item.key)
                  "
                />
                <div class="pinYin">
                  <span class="title-pinyin pinyin font-pinyin-medium">{{
                    item.pinYin
                  }}</span>
                  <span class="title-hanzi river-hanzi-medium">{{
                    item.hanZi
                  }}</span>
                </div>
                <div class="audioUrl" @click.stop="playPinyinAudio(item)">
                  <AudioPlayer
                    :isPlay="item.isStartPlaying"
                    :audioUrl="songUrl"
                    class="audioUrl_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture-mailbox">
        <div class="content-mailbox">
          <div
            class="box-item"
            v-for="(item, index) in mailboxList"
            :key="index"
            draggable="true"
            @drop.stop="drop($event, item)"
            @dragover.stop="allowDrop($event)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "../GameComponent/SceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import { templatePreviewMixin } from "./templatePreviewMixin.js";
export default {
  mixins: [templatePreviewMixin],
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          wordSortingCrossRiverList: [],
        };
      },
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
    AudioPlayer,
  },
  data() {
    return {
      key: 0,
      dragNum: 4,
      answerList: [1, 2, 3, 4],
      allImgList: [],

      mailboxList: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
      ],
      buttonList: [],

      numBers: 1,
      songUrl: null,
      lock: false,
      ramdomIndex: null,
      currentDom: null,
      currentKey: null,
      originDom: null,
      scrollDom: null,
      scrollWidth: null,
      scrollSpeed: 2,
      finishNum: 0,
      distance: 0,
      step: 0,
      dragSocketInfo: {},
      checkInfo: {},
      menuSocketInfo: {},
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dragSocketInfoValue: false,
      showIndex: null,
      localData: {
        numBers: 1,
        step: 0,
        finishNum: 0,
        allImgList: [
          {
            imgList: [
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "kàn ",
                hanZi: " 看 ",
                audioSrc: "",
                id: 3,
                isDispear: false,
                key: 3,
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "Bàba",
                hanZi: "爸爸   ",
                audioSrc: "",
                id: 1,
                isDispear: false,
                key: 1,
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "zài jiā",
                hanZi: "    在家   ",
                audioSrc: "",
                id: 2,
                isDispear: false,
                key: 2,
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "diànshì.",
                hanZi: "   电视。 ",
                audioSrc: "",
                id: 4,
                isDispear: false,
                key: 4,
              },
            ],
          },
          {
            imgList: [
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "qiú,",
                hanZi: " 球，  ",
                audioSrc: "",
                id: 3,
                isDispear: false,
                key: 3,
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: " Wǒ xǐhuan ",
                hanZi: "我喜欢  ",
                audioSrc: "",
                id: 1,
                isDispear: false,
                key: 1,
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "wán",
                hanZi: "  玩 ",
                audioSrc: "",
                id: 2,
                isDispear: false,
                key: 2,
              },
              {
                bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
                isStartPlaying: null,
                pinYin: "nǐ ne？",
                hanZi: " 你呢？",
                audioSrc: "",
                id: 4,
                isDispear: false,
                key: 4,
              },
            ],
          },
        ],
        buttonList: [
          {
            image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
            startNumberRange: 1,
            endNumberRange: 1,
          },
          {
            image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
            startNumberRange: 2,
            endNumberRange: 2,
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on("clickGameSideBtn", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    this.$bus.$on("clickRandomBtn", (value) => {
      this.$emit("socketRandom", value);
      this.bottomCornerButtonList.buttons[0].type = "look";
      this.lock = true;
    });
    this.$bus.$on("dragEnvelope", ({ currentKey, numBers }) => {
      this.originDom = document.getElementById(
        `selectItem-${numBers - 1}-${currentKey}`
      ).parentNode;

      this.currentDom = document.getElementById(
        `selectItem-${numBers - 1}-${currentKey}`
      );
      this.currentKey = currentKey;
      this.dragSocketInfoValue = true;
    });
    this.$bus.$on("checkDrag", ({ event, item }) => {
      this.drop(event, item, true);
    });
    this.$bus.$on("shuffleArray", ({ allImgList }) => {
      this.allImgList = allImgList;
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickGameSideBtn");
    this.$bus.$off("clickRandomBtn");
    this.$bus.$off("dragEnvelope");
    this.$bus.$off("checkDrag");
    this.$bus.$off("shuffleArray");
    clearTimeout(this.timer);
  },
  watch: {
    componentProps: {
      handler(newVal) {
        this.initData(newVal);
      },
      deep: true,
      immediate: true,
    },

    finishNum(value) {
      if (value === this.dragNum) {
        this.isLastStep = true;

        startConfetti();
        playCorrectSound();
        this.step = 1;
        this.timer = setTimeout(() => {
          this.step = 2;
        }, 2000);
      }
    },
    dragSocketInfo: {
      handler(value) {
        if (!this.dragSocketInfoValue) {
          const data = {
            clickType: 2001002,
            data: { value },
            text: "DragGameBtn drag点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
    checkInfo: {
      handler(value) {
        const data = {
          clickType: 2001003,
          data: { value },
          text: "DragGameBtn check点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    menuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2000501,
          data: { value },
          text: "测试PracticePage组件内sideButtonClick事件socket",
        };
        this.$bus.$emit("kids_webSocket_sendInfo", data);
      },
      deep: true,
    },
  },
  methods: {
    initData(newVal) {
      if (!newVal) {
        return;
      }
      const { isTemplatePrview } = newVal;
      if (isTemplatePrview) return;
      let startNumber = 1;
      this.buttonList = newVal.wordSortingCrossRiverList.map((subArray) => {
        const list = {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
          startNumberRange: startNumber,
          endNumberRange: startNumber,
        };
        startNumber++;
        return list;
      });
      // this.key = Date.now()
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    changeTheNumbers(index, isFromSocket = false) {
      // this.$nextTick(() => {})
      if (!isFromSocket) {
        this.menuSocketInfo = {
          index,
          value: Math.random(),
        };
      }

      this.numBers = index;
      this.currentIndex = index - 1;
      this.chooseImgIndex = null;
      this.step = -1;
      setTimeout(() => {
        this.step = 0;
      }, 1200);
      this.finishNum = 0;
      for (let i = 0; i < 8; i++) {
        if (document.getElementsByClassName("box-item")[i]) {
          document.getElementsByClassName("box-item")[i].innerHTML = " ";
        }
      }
    },
    /**
     * @description: 发送乱序事件
     * @param {*} value
     * @return {*}
     */
    sendRandom(value) {
      const data = {
        clickType: 2001004,
        data: { value },
        text: "DragGameBtn shuffleSocketValue",
      };
      this.$bus.$emit("kids_webSocket_sendInfo", data);
    },
    playPinyinAudio(type) {
      type.isStartPlaying = Math.random();
      this.songUrl = type.audioSrc;
    },
    handleDragend() {
      this.showIndex = null;
    },
    changeOrder() {
      if (this.lock) {
        return;
      }
      this.$emit("randomOrder");
      const data = {
        clickType: 2001001,
        data: { value: this.allImgList },
        text: "DragGameBtn点击socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      this.bottomCornerButtonList.buttons[0].type = "look";
      this.lock = true;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    drag(event, item) {
      this.showIndex = item.key;
      this.dragSocketInfoValue = false;
      this.originDom = event.currentTarget.parentNode;
      this.currentDom = event.currentTarget;

      this.currentKey = item.key;
      this.dragSocketInfo = {
        currentKey: this.currentKey,
        ramdomIndex: Math.random(),
        numBers: this.numBers,
      };
    },
    drop(event, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.checkInfo = {
          event: {
            target: event.target,
          },
          ramdomIndex: Math.random(),
          item,
        };
      }
      if (isFromSocket) {
        //根据传过来的value拿到对应的dom元素
        let target =
          document.getElementsByClassName("box-item")[item.value - 1];

        target.appendChild(this.currentDom); // 传入信封
        if (this.currentKey === item.value) {
          this.finishNum++;
          playCorrectSound(true, false);
        } else {
          setTimeout(() => {
            target.removeChild(this.currentDom); // 传入信封
            this.originDom.appendChild(this.currentDom);
            target.parentElement.className += " shake";
            playCorrectSound(false);
          }, 200);
        }
      } else {
        //正常通过event获取dom元素
        event.target.appendChild(this.currentDom); // 传入信封
        if (this.currentKey === item.value) {
          this.finishNum++;
          playCorrectSound(true, false);
        } else {
          setTimeout(() => {
            event.target.removeChild(this.currentDom); // 传入信封
            this.originDom.appendChild(this.currentDom);
            event.target.parentElement.className += " shake";
            playCorrectSound(false);
          }, 200);
        }
      }
    },

    allowDrop(event) {
      event.preventDefault();
    },
    startConfetti() {
      var n = new Audio(this.success_audio_sound);
      n.src = this.success_audio_sound;
      n.play();
      var myCanvas = document.getElementById("confettiCanvas");
      var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      });
      var end = Date.now() + 0.5 * 1000;

      // go Buckeyes!
      var colors = ["#ce4c3f", "#224e96", "#ffffff"];

      (function frame() {
        myConfetti({
          particleCount: 2,
          spread: 55,
          colors: colors,
        });
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    },
  },
};
</script>
<style scoped lang="scss">
.game-word-sorting-cross-river-container {
  width: calc(100% - 20px) !important;
  height: calc(100% - 40px) !important;
  padding: 10px 10px 30px 10px;
  border-radius: 56px;
  background: #fff;
  .drag-game-content {
    height: 100%;
    border-radius: 56px;
    position: relative;
    .contentImg {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        border-radius: 58px;
        object-fit: cover;
      }
    }
    .dogImg {
      width: 20%;
      position: absolute;
      top: 50%;
      left: 2%;
      transition: all 2s;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .step-2,
    .step-1 {
      position: absolute;
      top: 50%;
      left: 75%;
    }
    .picture-envelope {
      position: absolute;
      top: 0;
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;
      .content-picture-switching {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        .select-item {
          max-width: 160px;
          width: 20%;
        }
        .selectItem-1 {
          top: 60%;
          left: 22%;
        }
        .selectItem-2 {
          top: 15%;
          left: 30%;
        }
        .selectItem-3 {
          top: 37%;
          left: 48%;
        }
        .selectItem-4 {
          top: 70%;
          left: 64%;
        }
      }
    }
    .select-item {
      position: absolute;
      .select-content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .audioUrl {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -20px;
          .audioUrl_img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: #cd4c3f;
          }
        }
        .pinYin {
          position: absolute;
          top: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .picture-mailbox {
      position: absolute;
      top: 73%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content-mailbox {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1%;
        .box-item {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          height: 40px;
          border-radius: 10%;
          border: 3px dashed #fff;
          box-sizing: border-box;
          position: relative;
          .select-item {
            width: 100%;
            bottom: 0;
          }
        }
      }
    }
  }
  .confettiCanvas {
    z-index: 900;
    position: absolute;
  }
  .river-hanzi-medium {
    font-size: 35px !important;
    // very large screen
    @media screen and (max-width: 2200px) {
      // external screen
      font-size: 25px !important;
    }
    @media screen and (max-width: 1500px) {
      // laptop
      font-size: 24px !important;
    }
    @media screen and (max-width: 1300px) {
      // ipad
      font-size: 23px !important; //verify lesson 1 pick card 1 or 2 (boy / girl)
    }
  }
}
</style>
