var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "courseware-editor-container customScrollbar" },
    [
      _c("div", { staticClass: "header" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/login/logo-login.svg"), alt: "" },
          on: { click: _vm.handleJump },
        }),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "editor-content" }, [_c("RouterView")], 1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-area" }, [
      _c("div", { staticClass: "editor-nav-item" }, [
        _vm._v("STANDARD CONTENT"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }