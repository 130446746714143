<template>
  <div class="write-stroke-page-container layout-border">
    <div class="stroke-page-box">
      <!-- writing button -->
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
        @updateNumbers="updateNumbers($event)"
        @click.native="startWritingStroke"
      ></PageButton>


      <!-- 眼睛按钮 eye-->
      <div
        v-if="isWritePage"
        class="write-stroke-page-eye-btn"
        @mouseover="eyeIsHover = true"
        @mouseleave="eyeIsHover = false"
        @click="handleClickEyeButton"
      >
        <img
          v-if="!eyeIsHover"
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"
          alt=""
        />
        <img
          v-else
          src="@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"
          alt=""
        />
      </div>

      <div class="white-stroke-content">
        <!-- left 左边 -->

        <div class="white-stroke-content-left">
          <div class="left-content-border">
            <div class="left-content">
              <div class="left-content-top">
                <img
                  v-for="(item, index) in 3"
                  :key="index + 'flag'"
                  :src="
                    steps < 3 - index
                      ? flagImageObj.img
                      : flagImageObj.imgActive
                  "
                  alt=""
                />
              </div>

              <!-- default 默认左边背景 -->
              <div class="stroke-area" @mousedown="startDrawing()" @mouseup="endDrawing($event)">
                <div class="hanzi-img">
                  <KidsWhiteBoard ref="drawArea"/>
                   <div v-if="drawing" class="WritingStrokeArea" @click="draggedOutOfStroke">
                        <!-- <div class="drawingRedArea"> </div> -->
                        <div v-if="isShu" class="shuDrawingGreenArea">
                          <div class="checkPoint1" @mouseenter="hitCurrentCheckpoint(0)" @mouseleave="finishedCurrentCheckpoint(0)">                          
                          </div>
                          <div class="checkPoint2" @mouseenter="hitCurrentCheckpoint(1)" @mouseleave="finishedCurrentCheckpoint(1)">
                          </div>
                          <div class="checkPoint3" @mouseenter="hitCurrentCheckpoint(2)" @mouseleave="hitRedArea()">
                          </div>                       
        
                        </div> 
                        <div v-else class="hengDrawingGreenArea">
                          <div class="checkPoint1" @mouseenter="hitCurrentCheckpoint(0)" @mouseleave="finishedCurrentCheckpoint(0)">                          
                          </div>
                          <div class="checkPoint2" @mouseenter="hitCurrentCheckpoint(1)" @mouseleave="finishedCurrentCheckpoint(1)">
                          </div>
                          <div class="checkPoint3" @mouseenter="hitCurrentCheckpoint(2)" @mouseleave="hitRedArea()">
                          </div>                       
        
                        </div>  
                                             
                      </div>
                  <div class="square">
                    <img
                      class="volume-button"
                      src="@/assets/img/15-Hanzi-strokes/background.svg"
                      alt=""
                    />
                  </div>

                  <div class="hengIcon">
                    <img
                      class="volume-button"
                      :src="strokeImage"
                      alt=""
                      :class="{ shu: isShu }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- right 右边 -->
        <div class="white-stroke-content-right">
          <div class="white-stroke-content-right_img">
            <div
              class="item"
              v-for="(item, index) in backgroundImageList"
              :key="index + 'bg'"
            >
              <!-- this img need be discussed /9-Game-write-heng/water.svg -->
              <!-- <img src="" alt="" /> -->
              <img
                v-if="item.step === steps"
                :src="item.img"
                alt=""
                class="bgImg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    strokeImage: {
      type: String,
      require: true,
    },
    backgroundImageList: {
      type: Array,
      require: true,
    },
    flagImageObj: {
      type: Object,
      require: true,
    },
    isShu: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      characterCheckpoints:[
        {
          id:1,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id:2,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        },
        {
          id:3,
          hitCheckpoint: false,
          finishedCheckpoint: false,
        }

      ],

      writeIsHover: false,
      nextIsHover: false,
      eyeIsHover: false,
      isWritePage: false,
      isFinishWriting: false,
      isShowHand: false,
      drawing: false,
      steps: 0,

      cvWidth: 268,
      cvHeight: 268,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },
    };
  },
  components: {
    KidsWhiteBoard,
    PageButton,
  },
  mounted() {
    this.$bus.$on("showWritePage", () => {
      this.startWritingStroke();
    });
    this.$bus.$on("startDrawStroke", (val) => {
      this.steps = val;
    });
    this.$bus.$on("backOrigin", () => {
      this.isWritePage = false;
      this.isShowHand = false;
    });
  },
  beforeDestroy() {
    this.$bus.$off("showWritePage");
    this.$bus.$off("startDrawStroke");
    this.$bus.$off("backOrigin");
  },
  watch: {
    isWritePage(value) {
      // console.log(value);
      this.$bus.$emit("startStorePage", value);
    },
  },
  methods: {
    startDrawing(){
       setTimeout(() => {
      this.drawing = true;
      }, 100);
    //  this.$refs.drawArea.handleMouseDown(e)
    },
    endDrawing(e){
      this.drawing = false;
      this.$refs.drawArea.handleMouseUp(e)
      if( this.characterCheckpoints[2].finishedCheckpoint == true){
        this.finishedStroke();
        console.log("finished")
      } else{
          this.hitRedArea();
          console.log("not finished")
      }
      
    },

    finishedCurrentCheckpoint(checkpoint){
      this.characterCheckpoints[checkpoint].finishedCheckpoint = true;
    },
    hitCurrentCheckpoint(checkpoint){
      this.checkPoint(checkpoint);
      if(checkpoint == 2){
        if(this.characterCheckpoints[0].finishedCheckpoint == true &&  this.characterCheckpoints[1].finishedCheckpoint == true){
          this.finishedCurrentCheckpoint(checkpoint);
          this.characterCheckpoints[checkpoint].hitcheckpoint = true;
        } else{
          this.hitRedArea();
        }
        
      } else{
        this.characterCheckpoints[checkpoint].hitcheckpoint = true;
      }
      
    },
    
    finishedStroke(){
      console.log("finished")
      this.handleSteps()
    },
    overShot(){
      console.log("over shot")
    },

    hitRedArea(){
      
      this.drawing = false;
      for (let index = 0; index < this.characterCheckpoints.length; index++) {
        this.characterCheckpoints[index].hitcheckpoint = false;
        this.characterCheckpoints[index].finishedCheckpoint = false;
      }
      setTimeout(() => {
          this.$bus.$emit("controlCanvas", "clear");
        }, 500);
    },
    draggedOutOfStroke(){
      console.log("dragged out");
    },
    checkPoint(point){
      console.log("check point: " , point);
    },


    // 开始写笔画
    startWritingStroke() {
      // this.isShowHand = true;
      setTimeout(() => {
        this.isWritePage = true;
        this.isShowHand = true;
        //   this.isFinishWriting = false;
        this.steps = 0;
      }, 1000);
    },
    // 返回最原始page
    handleClickEyeButton() {
      this.isWritePage = false;
      this.isFinishWriting = false;
      this.isShowHand = false;
      console.log("+++++++++++++");
    },
    // 下一页
    nextPage() {
      this.$bus.$emit("startStorePage", false);
      this.$bus.$emit("nextButton", true);
    },
    // 步数
    handleSteps() {
      this.steps++;
      if (this.steps === 3) {
        this.isFinishWriting = true;
      } else {
        setTimeout(() => {
          this.$bus.$emit("controlCanvas", "clear");
        }, 500);
        this.hitRedArea();
      }
    },
  },
};
</script>

<style lang="scss" scoped>


 .drawingRedArea{
          top: 0 !important;
          //left: -624% !important;
          width: 100% !important;
          height: 100% !important;
           padding-bottom: 0 !important;
          // background-color: red;
           z-index: 9999;
           
  }
 
.shuDrawingGreenArea{
   width: 11% !important;
    height: 82% !important;
    padding-bottom: 0 !important;
    left: 44% !important;
    top: 10% !important;
    background-color: green;
    z-index: 9999;
    pointer-events: none;

       .checkPoint3{
        background-color: black;
    width: 100% !important;
    height: 12% !important;
    padding-bottom: 0 !important;
    right: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: all;
  }
 .checkPoint2{
    background-color: black;
    width: 100% !important;
    height: 5% !important;
    padding-bottom: 0 !important;
    right: 0;
    top: 50%;
    z-index: 9999;
    pointer-events: all;
   
  }
 .checkPoint1{
   background-color: black;
    width: 100% !important;
    height: 18% !important;
    padding-bottom: 0 !important;
    left: 0;
    top: 0;
    z-index: 9999;
    pointer-events: all;
      
  }
  }
  .hengDrawingGreenArea{
   //user-select: ; background-color: black;
    width: 66% !important;
    height: 10% !important;
    padding-bottom: 0 !important;
    left: 16% !important;
    top: 45% !important;
   //z-index: 10;
      background-color: green;
        z-index: 9999;
       pointer-events: none;

       .checkPoint3{
         background-color: black;
    width: 12% !important;
    height: 100% !important;
    padding-bottom: 0 !important;
    right: 0;
    top: 0;
     z-index: 9999;
      pointer-events: all;
  }
 .checkPoint2{
    background-color: black;
    width: 5% !important;
    height: 100% !important;
    padding-bottom: 0 !important;
    right: 50%;
    top: 0;
     z-index: 9999;
      pointer-events: all;
   
  }
 .checkPoint1{
   background-color: black;
    width: 18% !important;
    height: 100% !important;
    padding-bottom: 0 !important;
    left: 0;
    top: 0;
     z-index: 9999;
    pointer-events: all;
      
  }
  }
.WritingStrokeArea{
  width: 100% !important;
 // height: 100% !important;
//  background-color: black;
 // position: relative !important;
    
  
}
.write-stroke-page-container {
  .stroke-page-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    position: relative;
    .white-stroke-content {
      background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
        no-repeat;
      // padding: 10px 10px 30px 10px;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 48px;
      display: flex;
      .white-stroke-content-left {
        position: absolute;
        width: 50%;
        height: 90%;
        left: 0;
        .left-content-border {
          // min-width: 280px;
          min-height: 360px;
          max-width: 400px;
          max-height: 500px;
          margin-top: 10px;
          margin-left: 10px;
          width: calc(65% - 10px);
          height: calc(72% - 10px);
          position: absolute;
          right: 0;
          // top: 13%;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #224e96;
          border-radius: 30px;
          .left-content {
            min-height: 350px;
            max-width: 400px;
            max-height: 500px;
            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 30px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            // justify-content: space-evenly;
            align-items: center;
            cursor: default;
            .left-content-top {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 10%;
              width: 100%;
              margin-right: 6%;
              margin-top: 5%;
              img {
                // height: 100%;
                width: 13%;
                margin-left: 8px;
              }
            }
            .stroke-area {
              width: 70%;
              margin-top: 20%;
              .hanzi-img {
                position: relative;
                width: 100%;
                .hengIcon {
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  margin: auto;
                  img {
                    width: 66%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                  }
                }
              }
              .volume-button {
                cursor: pointer;
              }
              div {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: absolute;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
      .white-stroke-content-right {
        width: 50%;
        height: 90%;
        position: absolute;
        left: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .white-stroke-content-right_img {
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              display: block;
              width: 60%;
              max-height: 350px;
              // &:first-child {
              //   width: 30%;
              //   // left: 12%;
              //   // top: 20%;
              //   z-index: 1001;
              //   max-height: 150px;
              // }
            }
          }
        }
      }
    }
    // button
    .write-stroke-page-eye-btn {
      position: absolute;
      right: 14px;
      bottom: 18%;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      text-align: right;
      padding-right: 3%;
      img {
        width: 42%;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // margin: auto;
      }
    }
    .button-area {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 18%;
      cursor: pointer;
      z-index: 1001;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
  .shu {
    height: 80%;
  }
}
</style>
