<template>
  <div class="editor-content-container">
    <!-- <span class="testAdd" @click="addTemplate">新增</span>
    <span class="testDel" @click="deleteTemplate">删除</span> -->
    <div class="filter-area">
      <div class="select-area">
        <NKSelect
          :options="levelOptions"
          v-model="filterForm.levelId"
          @selectChagne="handleListInit"
          :typeString="'level'"
          placeholder="请选择Level"
        />
        <NKSelect
          :options="chapterOptions"
          v-model="filterForm.chapterId"
          @selectChagne="handleListInit"
          :typeString="'chapter'"
          placeholder="请选择Chapter"
          :disabled="!filterForm.levelId"
        />
        <NKSelect
          :options="lessonOptions"
          v-model="filterForm.lessonId"
          placeholder="请选择Lesson"
          :typeString="'lesson'"
          :disabled="!filterForm.chapterId"
          @selectChagne="handleListInit"
        />
        <NKSelect
          :options="coursewareList"
          :disabled="true"
          placeholder="课型"
          v-model="lessonType"
        />
        <!-- <NKSelect
          :options="
            lessonType ? coursewareList[lessonType - 1].lessonTypeList : []
          "
          placeholder="标题类型"
          :disabled="!(lessonType || !filterForm.lessonId)"
          v-model="filterForm.templateId"
        /> -->
      </div>
      <div class="search-btn">
        <span
          @click="handleSearchCourse"
          v-loading="searchLoading"
          element-loading-spinner="el-icon-loading"
          >Search</span
        >
        <!-- <span>Add</span> -->
      </div>
    </div>

    <div
      class="template-area"
      v-if="lessonPageList.length"
      v-loading="searchLoading"
      element-loading-spinner="el-icon-loading"
    >
      <div class="template-left">
        <div class="lesson-No">
          LESSON {{ getFormatLessonId(filterForm.lessonId) }}
        </div>
        <div class="customScrollbar">
          <div class="template-wrapper">
            <div
              class="page-item"
              v-for="item in lessonPageList"
              :key="item.value"
              :style="{
                backgroundImage: 'url(\'' + item.bgImg + '\')',
              }"
            >
              <div
                class="shadow-area"
                :class="{ haveShadow: !item.isFinished }"
              >
                <div class="title">{{ item.templateTypeName }}</div>
                <div class="icon">
                  <i
                    class="el-icon-view"
                    v-if="item.isFinished"
                    @click="handlePagePreview(item)"
                  ></i
                  ><i
                    class="el-icon-edit"
                    v-if="item.isFinished"
                    @click="handlePageEdit(item)"
                  ></i>
                  <i
                    class="el-icon-delete"
                    v-if="item.isFinished"
                    @click="handlePageDelete(item)"
                  >
                  </i
                  ><i
                    class="el-icon-circle-plus"
                    v-if="!item.isFinished"
                    @click="handleAddPage(item)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="template-right" v-if="currentPageInfo.id">
        <div class="header-template">
          <div class="template-label">TEMPLATE</div>
          <div class="template-wrapper">
            <!-- 
                          :style="{
                backgroundImage: 'url(\'' + item.templateImg + '\')'
              }"
             -->
            <div
              class="template-item"
              v-for="item in currentPageTemplateList"
              :key="item.templateId"
              :class="{
                tempateActive: item.templateId === currentPageInfo.templateId,
              }"
              @click="handleClickTemplate(item)"
            >
              <p>{{ item.tempalteName }}</p>
              <p>{{ item.templateField.split("_")[0] }}</p>
              <p>{{ item.templateField.split("_")[1] }}</p>
            </div>
          </div>
        </div>
        <div class="action">
          <div class="reset-btn">
            <span class="btn" @click="handleReset" v-if="!isInEdit">reset</span>
          </div>
          <div class="reset-btn">
            <span class="btn" @click="handlePreviewTemplate">preview</span>
          </div>
        </div>
        <div class="page-content">
          <div class="page-wrapper" v-if="currentPageInfo.id">
            <div class="view-left">
              <TemplateViewer :pageInfo="currentPageInfo" />
            </div>
            <div class="field-right">
              <FieldEditor
                :pageInfo="currentPageInfo"
                @fieldchangeEvent="handleFieldChange"
                @addNewSyllabusItem="handleAddNewSyllabusItem"
                @selectFieldchangeEvent="handleSelectFieldChange"
                @getPinyinFieldchangeEvent="handleGetPinyinFieldchange"
                @dialogValueChange="handleDialogChange"
                @deleteListItem="handleDeleteItem"
                @uploadMorePicture="handleChangeMorePicture"
                @deletePicture="handleDeletePicture"
                @cancelEditAddEvent="handleCancelEditAddEvent"
                @saveCoursewareEvent="handleSaveCoursewareEvent"
                @mirrorUploadMorePicture="handleMirrorUploadMorePicture"
                @deleteMirrorPicture="handleDeleteMirrorPicture"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="previewVisible"
      class="preview-dialog"
      @close="handleClosePreview"
    >
      <div class="preview-container">
        <span class="icon-close" @click="handleClosePreview">
          <i class="el-icon-close"></i>
        </span>
        <div class="preview-content">
          <TemplateViewer
            v-if="previewInfo && previewInfo.templateField"
            :pageInfo="previewInfo"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { templateTestList } from "./components/GameAndPage/template.js";
import {
  getKidsCourseEnumData,
  addOrUpdateKidsCourseware,
  addOrUpdateKidsCoursewareTemplate,
  getKidsCoursewareDetail,
  getKidsCoursewareTemplateList,
  deleteKidsCourseware,
  deleteKidsCoursewareTemplate,
} from "@/assets/js/axios/coursewareEditor.js";
export default {
  components: {
    NKSelect: () => import("./components/NKSelect"),
    FieldEditor: () => import("./components/FieldEditor"),
    TemplateViewer: () => import("./components/TemplateView"),
  },
  data() {
    return {
      searchLoading: false,
      filterForm: {
        levelId: null,
        chapterId: null,
        lessonId: null,
        coursewareType: null,
        templateId: null,
      },
      levelOptions: [],
      chapterOptions: [],
      lessonOptions: [],
      coursewareList: [
        {
          value: 1,
          label: "A课",
          lessonIds: [1, 3, 5],
        },
        {
          value: 2,
          label: "B课",
          lessonIds: [2, 4, 6],
        },
        {
          value: 3,
          label: "文化课",
          lessonIds: [7],
        },
        {
          value: 4,
          label: "总复习",
          lessonIds: [0],
        },
      ],
      lessonPageList: [],
      currentPageTemplateList: [],
      originPageInfoData: [],
      currentPageInfo: {},

      pageHoverIndex: null,

      titleEnumList: [],
      previewVisible: false,
      previewInfo: null,
      isInEdit: false,
    };
  },
  computed: {
    lessonType() {
      const lessonIndex = this.lessonOptions.find(
        (item) => item.value === this.filterForm.lessonId
      )
        ? this.lessonOptions.find(
            (item) => item.value === this.filterForm.lessonId
          ).filterId
        : null;
      if (!lessonIndex) return null;
      let type = null;
      this.coursewareList.forEach((item) => {
        if (item.lessonIds.includes(lessonIndex % 8)) {
          type = item.value;
        }
      });
      // console.log("课件类型", type, lessonIndex);
      return type;
    },
  },
  async created() {
    await this.getLevelList();
    await this.getTitleEnum();
  },
  methods: {
    async addTemplate() {
      const index = 47;
      const templateType = [5752];

      // console.log(templateTestList[index])
      const { contents, tempalteName, templateField } = templateTestList[index];
      const sendData = {
        content: JSON.stringify(contents),
        fieldName: templateField,
        templateType,
        title: tempalteName,
      };
      // console.log(sendData)
      // return
      await addOrUpdateKidsCoursewareTemplate(sendData);
    },
    async getTitleEnum() {
      await getKidsCourseEnumData({
        enumId: 291,
      });
      // console.log(data)
    },
    async deleteTemplate() {
      const templateId = 530;
      // return
      await deleteKidsCoursewareTemplate({ id: templateId });
      // console.log(res)
    },
    //-------------------------测试结束----------------------
    handleListInit(type) {
      switch (type) {
        case "level":
          this.filterForm.chapterId = null;
          this.filterForm.lessonId = null;
          this.getChapterList(this.filterForm.levelId);
          break;
        case "chapter":
          this.filterForm.lessonId = null;
          this.getLessonList(this.filterForm.chapterId);
          break;
        case "lesson":
          this.filterForm.templateId = null;
          break;
      }
    },
    async getEnumDataList(enumId, parentId, isLesson = false) {
      const { data } = await getKidsCourseEnumData({
        enumId,
        parentId,
      });
      return data.map((item) => {
        return {
          label: item.label,
          value: item.id,
          filterId: item.value,
          parentId: item.parentId,
        };
      });
    },
    /**
     * @description: 根据id查询课件信息
     * @return {*}
     */
    async handleSearchCourse() {
      this.searchLoading = true;
      const { chapterId, lessonId, levelId } = this.filterForm;
      if (!chapterId || !lessonId || !levelId) {
        this.$message.warning("请选择查询条件");
        this.searchLoading = false;
        return;
      }
      const { data, code } =
        (await getKidsCoursewareDetail({
          chapterId,
          lessonId,
          levelId,
        })) || {};
      if (code === 200) {
        this.currentLessonInfo = data;
        this.lessonPageList = data.contentList.map((item) => {
          return {
            ...item,
            content: item.content ? JSON.parse(item.content) : [],
            isFinished: !!item.content,
            bgImg: item.content
              ? require("@/assets/img/03-Backgrounds/level-1-background-color.svg")
              : "",
            levelId: data.levelId,
            lessonId: data.lessonId,
            chapterId: data.chapterId,
            id: data.id,
          };
        });

        this.lessonPageList = this.lessonPageList.map((item, index) => {
          return {
            ...item,
            sortNo: index + 1,
          };
        });
        this.currentPageInfo = {};
        this.previewInfo = {};
        this.currentPageTemplateList = [];
        // console.log('处理后显示的列表页', this.lessonPageList)
      }
      this.searchLoading = false;
    },

    getFormatLessonId(id) {
      return this.lessonOptions.find((item) => item.value === id)
        ? this.lessonOptions.find((item) => item.value === id).filterId
        : "";
    },
    async getLevelList() {
      this.levelOptions = await this.getEnumDataList(292, null);
    },
    async getChapterList(parentId) {
      this.chapterOptions = await this.getEnumDataList(292, parentId);
      if (this.chapterOptions.length > 0) {      
            this.filterForm.chapterId = this.chapterOptions[0].value;
            this.getLessonList(this.filterForm.chapterId);
          }
    },
    async getLessonList(parentId) {
      this.lessonOptions = await this.getEnumDataList(292, parentId);
      this.filterForm.lessonId = this.filterForm.chapterId + 1;
    },

    /**
     * @description: 通过模板类型获取关联的模板详情数组
     * @return {*}
     */
    async getCoursewareTemplateList(templateType) {
      const sendData = {
        templateTypeList: [templateType],
      };

      return await getKidsCoursewareTemplateList(sendData);
    },

    async handleInitPageInfo(item, isEditPage = true, type) {
      // console.log(item, 'item')
      this.currentPageInfo.id = null;
      this.previewInfo.id = null;
      const {
        templateType,
        templateId: pageTemplateId,
        content: eidtContents = [],
        levelId,
        chapterId,
        lessonId,
        id,
        sortNo,
      } = item;
      const lesssonFieldList = [
        {
          label: "level",
          fieldName: "levelId",
          value: levelId,
          type: null,
        },
        {
          label: "chapter",
          fieldName: "chapterId",
          value: chapterId,
          type: null,
        },
        {
          label: "lesson",
          fieldName: "lessonId",
          value: lessonId,
          type: null,
        },
      ];

      const res = await this.getCoursewareTemplateList(templateType);
      // 通过模板id获取模板详情

      if (res.code === 200) {
        if (!res.data.length)
          return this.$message.warning("暂未绑定模板，请联系IT添加模板");
        const currentTemplateInfo = isEditPage
          ? res.data.find((item) => item.id === pageTemplateId)
          : res.data[0];

        const {
          fieldName: templateField = null,
          title = "",
          content: templateContents,
        } = currentTemplateInfo;

        const propsInfo = {};

        const contents = [
          ...(isEditPage ? eidtContents : JSON.parse(templateContents)),
          ...lesssonFieldList,
        ];
        // console.log(contents,'测试');

        contents.forEach((item) => {
          propsInfo[item.fieldName] = item.value;
        });
        let pageInfoObj = {
          id,
          templateId: isEditPage ? pageTemplateId : currentTemplateInfo.id,
          templateField,
          contents,
          propsInfo,
          sortNo,
        };
        if (type) {
          this.previewInfo = pageInfoObj;
          this.previewVisible = true;
        } else {
          this.currentPageInfo = pageInfoObj;
        }

        this.currentPageTemplateList = isEditPage
          ? [
              {
                templateId: pageTemplateId,
                tempalteName: title,
                templateField,
                templateImg: require("@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg"),
                contents,
                id,
                sortNo,
              },
            ]
          : res.data.map((item) => {
              return {
                id,
                sortNo,
                templateId: item.id,
                tempalteName: item.title,
                templateField: item.fieldName,
                templateImg: require("@/assets/img/03-Backgrounds/level-1-chapter-2-background-color.svg"),
                contents: [
                  ...JSON.parse(item.content || "[]"),
                  ...lesssonFieldList,
                ],
              };
            });
      }

      // console.log('当前编辑页的页面信息:', this.currentPageInfo)
      // console.log('当前编辑页的模板信息:', this.currentPageTemplateList)

      this.originPageInfoData = JSON.parse(
        JSON.stringify(this.currentPageTemplateList)
      );
      localStorage.setItem(
        "currentPageInfo",
        JSON.stringify(this.originPageInfoData)
      );
    },

    /**
     * @description: 页面编辑
     * @return {*}
     */
    handlePageEdit(item) {
      this.isInEdit = true;

      // console.log("编辑的页面信息:", item);
      this.handleInitPageInfo(item);
    },

    /**
     * @description: 新增页面
     * @return {*}
     */
    handleAddPage(item) {
      this.isInEdit = false;
      // console.log("新增的页面信息:", item);
      this.handleInitPageInfo(item, false);
    },
    /**
     * @description: 预览
     * @return {*}
     */
    async handlePreviewTemplate() {
      this.previewVisible = true;
      const originPageInfoData = JSON.parse(
        localStorage.getItem("currentPageInfo")
      );
      const res = originPageInfoData.find(
        (item) => item.templateId === this.currentPageInfo.templateId
      );
      this.previewInfo = {
        ...JSON.parse(JSON.stringify(res)),
        propsInfo: {
          isTemplatePrview: true,
        },
      };
      // console.log(this.previewInfo)
    },
    /**
     * @description: reset功能
     * @return {*}
     */
    handleReset() {
      // console.log(localStorage.getItem('currentPageInfo'))
      const originPageInfoData = JSON.parse(
        localStorage.getItem("currentPageInfo")
      );
      // return
      this.handleClickTemplate(
        originPageInfoData.find(
          (item) => item.templateId === this.currentPageInfo.templateId
        )
      );
    },
    handleClickTemplate(item) {
      // console.log(item, '模板信息')

      this.currentPageInfo.id = null;
      // console.log('点击的模板信息:', item.templateField)
      const { id, templateField, templateId, contents, sortNo } = item;
      const propsInfo = {};
      contents.forEach((item) => {
        propsInfo[item.fieldName] = item.value;
      });
      // return
      setTimeout(() => {
        this.currentPageInfo = {
          id,
          templateId,
          templateField,
          contents,
          propsInfo,
          sortNo,
        };
        // console.log(this.currentPageInfo);
      }, 100);
    },
    /**
     * @description: 页面预览
     * @return {*}
     */
    async handlePagePreview(item) {
      // 预览做弹框展示
      await this.handleInitPageInfo(item, true, 1);
    },

    /**
     * @description: 页面删除
     * @return {*}
     */
    handlePageDelete(item) {
      // 加一个确认弹框
      this.$confirm("Are you sure to delete this page?", "Tips", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const { sortNo, id } = item;
          // console.log(this.currentPageInfo, this.lessonPageList)

          const contentList = this.lessonPageList
            .filter((item) => item.sortNo !== sortNo)
            .map((item) => {
              return {
                content:
                  item.content && item.content.length
                    ? JSON.stringify(item.content)
                    : null,
                isRepeat: item.isRepeat,
                templateId: item.templateId,
                templateType: item.templateType,
                templateTypeName: item.templateTypeName,
              };
            });
          // console.log(contentList)

          this.handleUpdateCourseware(id, contentList);
        })
        .catch(() => {
          return false;
        });
    },

    /**
     * @description: 字段同步刷新
     * @return {*}
     */
    handleFieldChange(data) {
      // console.log(data, '000')
      const { fieldName, value } = data;
      this.currentPageInfo.propsInfo[fieldName] = value;
      // console.log(this.currentPageInfo,'099090-----------');
      this.currentPageInfo.contents.forEach((item) => {
        if (item.fieldName === fieldName) {
          item.value = value;
        }
      });
    },
    /**
     * @description: 新增一条syllabus项
     * @return {*}
     */
    handleAddNewSyllabusItem(index, formData) {
      // console.log('新增最低层的index', index, formData)
      // if (index !== null) return
      this.currentPageInfo.contents.forEach((item) => {
        switch (item.fieldName) {
          case "syllabusList":
            item.value.push([
              {
                label: "标题",
                fieldName: "syllabusTitle",
                value: "",
                type: 1,
              },
              {
                label: "内容",
                fieldName: "syllabusContent",
                value: "",
                type: 7,
                options: [],
              },
              {
                label: "图片",
                fieldName: "syllabusImg",
                value: [],
                type: 8,
              },
            ]);
            break;
          case "dialogList":
            item.value.push([
              {
                label: "头像",
                fieldName: "avatar",
                value: "",
                type: 60,
                options:
                  item.value[0] && item.value[0].length
                    ? item.value[0][0].options
                    : [],
              },
              {
                label: "内容",
                fieldName: "dialogue",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "py",
                value: "",
                type: 1,
              },
              {
                label: "位置",
                fieldName: "position",
                value: false,
                type: 4,
                options: [
                  {
                    label: "左",
                    value: false,
                  },
                  {
                    label: "右",
                    value: true,
                  },
                ],
              },
            ]);
            break;
          case "gameLinkUpList":
            item.value.push([
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
              {
                label: "图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "gameMirrorList":
            item.value.push([
              {
                label: "按钮图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "图片",
                fieldName: "imgList",
                value: [],
                type: 8,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "py",
                value: "",
                type: 1,
              },
              {
                label: "正确选项",
                fieldName: "correctOption",
                value: "",
                type: 4,
                options: [],
              },
            ]);
            break;
          case "gameRecognitionList":
            item.value.push([
              {
                label: "图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "py",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "gameFlipList":
            item.value.push([
              {
                label: "图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "py",
                value: "",
                type: 1,
              },
              {
                label: "英文",
                fieldName: "english",
                value: "",
                type: 1,
              },
              {
                label: "背景色",
                fieldName: "correctOption",
                value: "",
                type: 4,
                options: [
                  {
                    label: "红色",
                    value: "redColor",
                  },
                  {
                    label: "黄色",
                    value: "yellowColor",
                  },
                ],
              },
            ]);
            break;
          case "phoneNumberList":
            item.value.push([
              {
                label: "音频",
                type: 2,
                fieldName: "audio",
                value: "",
              },
              {
                label: "数字",
                type: 1,
                fieldName: "number",
                value: "",
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "findByPictureList":
            item.value.push([
              {
                label: "按钮拼音",
                type: 1,
                fieldName: "buttonPy",
                value: "",
              },
              {
                label: "按钮汉字",
                type: 1,
                fieldName: "buttonZh",
                value: "",
              },
              {
                label: "图片",
                type: 3,
                fieldName: "img",
                value: "",
              },
              {
                label: "音频",
                type: 2,
                fieldName: "audio",
                value: "",
              },
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "buttonList":
            item.value.push([
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
              {
                label: "图片列表",
                fieldName: "imgList",
                value: [],
                type: 8,
              },
            ]);
            break;
          case "buttonPyList":
            item.value.push([
              {
                label: "图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "音频",
                fieldName: "audio",
                value: "",
                type: 2,
              },
              {
                label: "音频图片",
                fieldName: "audioImg",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "scratchPicList":
            item.value.push([
              {
                label: "背景图片",
                type: 3,
                value: "",
                fieldName: "bgImg",
              },
              {
                label: "按钮图片",
                type: 3,
                value: "",
                fieldName: "btnImg",
              },
            ]);
            break;
          case "questionList":
            item.value.push([
              {
                label: "问题拼音",
                fieldName: "questionPy",
                value: "",
                type: 1,
              },
              {
                label: "问题汉字",
                fieldName: "questionZh",
                value: "",
                type: 1,
              },
              {
                label: "图片列表",
                fieldName: "imgList",
                value: [],
                type: 8,
              },
              {
                label: "答案图片",
                fieldName: "answerImg",
                type: 60,
                value: "",
                options: [],
              },
            ]);
            break;
          case "ChooseImgList":
            item.value.push([
              {
                label: "描述拼音",
                fieldName: "contentPy",
                value: "",
                type: 1,
              },
              {
                label: "描述汉字",
                fieldName: "contentZh",
                value: "",
                type: 1,
              },
              {
                label: "图片",
                fieldName: "imgList",
                value: [],
                type: 8,
              },
              {
                label: "答案图片",
                fieldName: "answerImg",
                value: "",
                type: 60,
                options: [],
              },
              {
                label: "按钮图片",
                fieldName: "btnImg",
                type: 3,
                value: "",
              },
            ]);
            break;
          case "StructureList":
            item.value.push([
              {
                label: "答案图片",
                fieldName: "answerImg",
                value: "",
                type: 3,
              },
              {
                label: "按钮拼音I",
                fieldName: "btnPyI",
                value: "",
                type: 1,
              },
              {
                label: "按钮汉字I",
                fieldName: "btnZhI",
                value: "",
                type: 1,
              },
              {
                label: "按钮拼音II",
                fieldName: "btnPyII",
                value: "",
                type: 1,
              },
              {
                label: "按钮汉字II",
                fieldName: "btnZhII",
                value: "",
                type: 1,
              },
              {
                label: "按钮拼音III",
                fieldName: "btnPyIII",
                value: "",
                type: 1,
              },
              {
                label: "按钮汉字III",
                fieldName: "btnZhIII",
                value: "",
                type: 1,
              },
              {
                label: "正确答案",
                fieldName: "correctAnswer",
                value: "",
                type: 60,
                options: [],
              },
            ]);
            break;
          case "puzzleImgList":
            item.value.push([
              {
                label: "图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "是否正确",
                fieldName: "isCorrect",
                value: 0,
                type: 4,
                options: [
                  {
                    label: "正确",
                    value: 0,
                  },
                  {
                    label: "错误",
                    value: 1,
                  },
                ],
              },
            ]);
            break;
          case "optionList":
            if (index !== null) {
              item.value[index][2].value.push([
                {
                  label: "答案拼音",
                  fieldName: "anwserPy",
                  value: "",
                  type: 1,
                },
                {
                  label: "答案汉字",
                  fieldName: "answerZh",
                  value: "",
                  type: 1,
                },
                {
                  label: "是否展示",
                  fieldName: "isShow",
                  value: true,
                  type: 4,
                  options: [
                    {
                      label: "是",
                      value: true,
                    },
                    {
                      label: "否",
                      value: false,
                    },
                  ],
                },
              ]);
            } else {
              item.value.push([
                {
                  label: "选项图片",
                  fieldName: "optionImg",
                  value: [],
                  type: 8,
                },
                {
                  label: "正确答案",
                  fieldName: "correctAnswer",
                  value: "",
                  type: 60,
                  options: [],
                },
                {
                  label: "答案列表",
                  fieldName: "answerList",
                  value: [
                    [
                      {
                        label: "答案拼音",
                        fieldName: "anwserPy",
                        value: "",
                        type: 1,
                      },
                      {
                        label: "答案汉字",
                        fieldName: "answerZh",
                        value: "",
                        type: 1,
                      },
                      {
                        label: "是否展示",
                        fieldName: "isShow",
                        value: true,
                        type: 4,
                        options: [
                          {
                            label: "是",
                            value: true,
                          },
                          {
                            label: "否",
                            value: false,
                          },
                        ],
                      },
                    ],
                  ],
                  type: 9,
                },
              ]);
            }
            break;
          case "addLevelList":
            item.value.push([
              {
                label: "故事图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "汉字选项",
                fieldName: "optionList",
                value: "",
                type: 1,
              },
              {
                label: "正确答案",
                fieldName: "answer",
                value: "",
                type: 1,
              },
              {
                label: "音频",
                fieldName: "audioSrc",
                value: "",
                type: 2,
              },
            ]);
            break;
          case "dragPicList":
            item.value.push([
              {
                label: "按钮拼音",
                fieldName: "btnPy",
                value: "",
                type: 1,
              },
              {
                label: "按钮汉字",
                fieldName: "btnZh",
                value: "",
                type: 1,
              },
              {
                label: "答案图片",
                fieldName: "answerPic",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "photoList":
            item.value.push([
              {
                label: "背景图片",
                fieldName: "bgImg",
                value: "",
                type: 3,
              },
              {
                label: "正确图片",
                fieldName: "answerImg",
                value: "",
                type: 3,
              },
              {
                label: "位置",
                fieldName: "position",
                value: "",
                type: 4,
                options: [
                  {
                    label: "left",
                    value: 0,
                  },
                  {
                    label: "right",
                    value: 1,
                  },
                ],
              },
              {
                label: "按钮图片",
                fieldName: "btnImg",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "choosePicList":
            item.value.push([
              {
                label: "匹配的图片",
                fieldName: "matchImg",
                type: 8,
                value: [],
              },
            ]);
            break;
          case "questionInfoList":
            item.value.push([
              {
                label: "按钮拼音",
                fieldName: "btnPy",
                value: "",
                type: 1,
              },
              {
                label: "按钮汉字",
                fieldName: "btnZh",
                value: "",
                type: 1,
              },
              {
                label: "按钮音频",
                fieldName: "btnAudio",
                value: "",
                type: 2,
              },
              {
                label: "按钮位置",
                fieldName: "position",
                value: "",
                type: 4,
                options: [
                  {
                    label: "left",
                    value: 0,
                  },
                  {
                    label: "right",
                    value: 1,
                  },
                ],
              },
              {
                label: "图片",
                fieldName: "detailImg",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "structurePicList":
            item.value.push([
              {
                label: "结构图片",
                fieldName: "structurePic",
                value: "",
                type: 60,
                options:
                  item.value[0] && item.value[0].length
                    ? item.value[0][0].options
                    : [],
              },
              {
                label: "问题图片",
                fieldName: "questionPic",
                value: "",
                type: 3,
              },
              {
                label: "确认图片",
                fieldName: "answerPic",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "hanziList":
            item.value.push([
              {
                label: "汉字/词语",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "listenChoosePicList":
            if (index !== null) {
              item.value[index][5].value.push([
                {
                  label: "汉字",
                  fieldName: "zh",
                  value: "",
                  type: 1,
                },
                {
                  label: "拼音",
                  fieldName: "py",
                  value: "",
                  type: 1,
                },
                {
                  label: "是否正确",
                  fieldName: "isRight",
                  value: "",
                  type: 4,
                  options: [
                    {
                      label: "是",
                      value: 0,
                    },
                    {
                      label: "否",
                      value: 1,
                    },
                  ],
                },
              ]);
            } else {
              item.value.push([
                {
                  label: "按钮图片",
                  fieldName: "btnImg",
                  value: "",
                  type: 3,
                },
                {
                  label: "图片选项列表",
                  fieldName: "picChooseList",
                  value: [],
                  type: 8,
                },
                {
                  label: "答案图片",
                  fieldName: "answerPic",
                  value: "",
                  options: [],
                  type: 60,
                },
                {
                  label: "音频",
                  fieldName: "audioSrc",
                  value: "",
                  type: 2,
                },
                {
                  label: "答案拼音",
                  fieldName: "pyDetail",
                  value: "",
                  type: 1,
                },
                {
                  label: "答案汉字",
                  fieldName: "hanziDetail",
                  value: "",
                  type: 1,
                },
                {
                  label: "汉字列表",
                  fieldName: "zhList",
                  value: [
                    [
                      {
                        label: "汉字",
                        fieldName: "zh",
                        value: "",
                        type: 1,
                      },
                      {
                        label: "拼音",
                        fieldName: "py",
                        value: "",
                        type: 1,
                      },
                      {
                        label: "是否正确",
                        fieldName: "isRight",
                        value: "",
                        type: 4,
                        options: [
                          {
                            label: "是",
                            value: 0,
                          },
                          {
                            label: "否",
                            value: 1,
                          },
                        ],
                      },
                    ],
                  ],
                  type: 9,
                },
              ]);
            }
            break;
          case "mirrorPictureList":
            item.value.push([
              {
                label: "关卡图片",
                fieldName: "mirrorPicture",
                value: "",
                type: 3,
              },
              {
                label: "关卡答案",
                fieldName: "mirrorAnswer",
                value: "",
                type: 4,
                options: [
                  {
                    label: "我",
                    value: 0,
                  },
                  {
                    label: "你",
                    value: 1,
                  },
                ],
              },
            ]);
            break;
          case "speakingTwoSentenceList":
            item.value.push([
            {
              label: '标题拼音',
              fieldName: 'titlePinYin',
              value: '',
              type: 1
            },
            {
              label: '标题汉字',
              fieldName: 'titleHanZi',
              value: '',
              type: 1
            },
            {
              label: '背景图片',
              fieldName: 'bgPic',
              value: '',
              type: 3
            },
            {
              label: '关卡图片',
              fieldName: 'bgImg',
              value: '',
              type: 3
            },
            {
              label: '问题汉字',
              fieldName: 'questionHanZi',
              value: '',
              type: 1
            },
            {
              label: '问题拼音',
              fieldName: 'questionPinYin',
              value: '',
              type: 1
            },
            {
              label: '答案汉字',
              fieldName: 'answerHanzi',
              value: '',
              type: 1
            },
            {
              label: '答案拼音',
              fieldName: 'answerPinyin',
              value: '',
              type: 1
            }
            ]);
            break;
          case "TwitterGameList":
            item.value.push([
              {
                label: '标题拼音',
                fieldName: 'titlePy',
                value: '',
                type: 1
              },
              {
                label: '标题汉字',
                fieldName: 'titleZh',
                value: '',
                type: 1
              },
              {
                label: "问题图片",
                fieldName: "Img1",
                value: "",
                type: 3,
              },
              {
              label: '答案拼音',
              fieldName: 'answerPy',
              value: '',
              type: 1
              },
              {
              label: '答案汉字',
              fieldName: 'answerZh',
              value: '',
              type: 1
              },
              {
                label: "关卡序号",
                fieldName: "menuNum",
                value: 0,
                type: 3,
              },
            ]);
            break;
          case "practiceList":
            item.value.push([
              {
                label: "拼音",
                fieldName: "pinyin",
                type: 1,
                value: "",
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                type: 1,
                value: "",
              },
              {
                label: "图片列表",
                fieldName: "imageList",
                type: 8,
                value: [],
              },
            ]);
            break;
          case "turnTableList":
            item.value.push([
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "questionLists":
            item.value.push([
              {
                label: "标题拼音",
                fieldName: "titlePy",
                type: 1,
                value: "",
              },
              {
                label: "标题汉字",
                fieldName: "titleZh",
                type: 1,
                value: "",
              },
              {
                label: "图片列表",
                fieldName: "imageList",
                type: 8,
                value: [],
              },
              {
                label: "正确答案",
                fieldName: "correctPic",
                value: "",
                type: 60,
                options: [],
              },
            ]);
            break;
          case "practiceImgList":
            if (index !== null) {
              item.value[index][4].value.push([
                {
                  label: "拼音",
                  fieldName: "pinyin",
                  value: "",
                  type: 1,
                },
                {
                  label: "汉字",
                  fieldName: "hanzi",
                  value: "",
                  type: 1,
                },
              ]);
            } else {
              item.value.push([
                {
                  label: "按钮图片",
                  fieldName: "menuImg",
                  value: "",
                  type: 3,
                },
                {
                  label: "图片",
                  fieldName: "img",
                  value: "",
                  type: 3,
                },
                {
                  label: "答案拼音",
                  fieldName: "answerPy",
                  value: "",
                  type: 1,
                },
                {
                  label: "答案汉字",
                  fieldName: "answerZh",
                  value: "",
                  type: 1,
                },
                {
                  label: "汉字列表",
                  fieldName: "wordList",
                  type: 9,
                  value: [
                    [
                      {
                        label: "拼音",
                        fieldName: "pinyin",
                        value: "",
                        type: 1,
                      },
                      {
                        label: "汉字",
                        fieldName: "hanzi",
                        value: "",
                        type: 1,
                      },
                    ],
                  ],
                },
              ]);
            }
            break;
          case "cardList":
            item.value.push([
              {
                label: "图片拼音",
                value: "",
                fieldName: "pinyin",
                type: 1,
              },
              {
                label: "图片汉字",
                value: "",
                fieldName: "hanzi",
                type: 1,
              },
              {
                label: "图片正面",
                value: "",
                fieldName: "image",
                type: 3,
              },
              {
                label: "图片反面",
                value: "",
                fieldName: "backImage",
                type: 3,
              },
            ]);
            break;
          case "basketBallList":
            item.value.push([
              {
                label: "音频",
                fieldName: "audioSrc",
                value: "",
                type: 2,
              },
              {
                label: "",
                fieldName: "answerImg",
                value: "",
                type: 60,
                options:
                  item.value[0] && item.value[0].length
                    ? item.value[0][1].options
                    : [],
              },
            ]);
            break;
          case "basketBallPyList":
            if (index !== null) {
              item.value[index][1].value.push([
                {
                  label: "选项内容",
                  fieldName: "optionContent",
                  value: "",
                  type: 1,
                },
                {
                  label: "是否正确答案",
                  fieldName: "isCorrectAnswer",
                  value: true,
                  type: 4,
                  options: [
                    {
                      label: "是",
                      value: true,
                    },
                    {
                      label: "否",
                      value: false,
                    },
                  ],
                },
              ]);
            } else {
              item.value.push([
                {
                  label: "音频",
                  fieldName: "audioSrc",
                  value: "",
                  type: 2,
                },
                {
                  label: "选项列表",
                  fieldName: "optionList1",
                  value: [
                    [
                      {
                        label: "选项内容",
                        fieldName: "optionContent",
                        value: "",
                        type: 1,
                      },
                      {
                        label: "是否正确答案",
                        fieldName: "isCorrectAnswer",
                        value: true,
                        type: 4,
                        options: [
                          {
                            label: "是",
                            value: true,
                          },
                          {
                            label: "否",
                            value: false,
                          },
                        ],
                      },
                    ],
                  ],
                  type: 9,
                },
              ]);
            }
            break;
          case "chooseImgByListeningList":
            item.value.push([
              {
                label: "音频",
                fieldName: "audioSrc",
                value: "",
                type: 2,
              },
              {
                label: "正确图片",
                fieldName: "correctImg",
                value: "",
                type: 3,
              },
              {
                label: "图片1",
                fieldName: "Img1",
                value: "",
                type: 3,
              },
              {
                label: "图片2",
                fieldName: "Img2",
                value: "",
                type: 3,
              },
              {
                label: "图片3",
                fieldName: "Img3",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "picMenuList":
            item.value.push([
              {
                label: "背景图片",
                fieldName: "bgImg",
                value: "",
                type: 3,
              },
              {
                label: "灰色图片",
                fieldName: "grayImg",
                value: "",
                type: 3,
              },
              {
                label: "选中图片",
                fieldName: "menuImg",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "picMenuChooseList":
            item.value.push([
              {
                label: "背景图片",
                fieldName: "bgImg",
                value: "",
                type: 3,
              },
              {
                label: "灰色图片",
                fieldName: "grayImg",
                value: "",
                type: 3,
              },
              {
                label: "选中图片",
                fieldName: "menuImg",
                value: "",
                type: 3,
              },
              {
                label: "句子拼音",
                fieldName: "sentencePy",
                value: "",
                type: 1,
              },
              {
                label: "句子汉字",
                fieldName: "sentenceZh",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "pickWordsByPicList":
            item.value.push([
              {
                label: "问题图片",
                fieldName: "fullImg",
                value: "",
                type: 3,
              },
              {
                label: "答案图片序号",
                fieldName: "answerId",
                value: "",
                type: 1,
              },
              {
                label: "第一张拼图",
                fieldName: "firstImg",
                value: "",
                type: 3,
              },
              {
                label: "第二张拼图",
                fieldName: "secondImg",
                value: "",
                type: 3,
              },
              {
                label: "音频",
                fieldName: "audioUrl",
                value: "",
                type: 2,
              },
              {
                label: "关卡序号图片",
                fieldName: "menuNum",
                value: 0,
                type: 3,
              },
              {
                label: "选项1",
                fieldName: "option1",
                value: "",
                type: 3,
              },
              {
                label: "选项2",
                fieldName: "option2",
                value: "",
                type: 3,
              },
              {
                label: "选项3",
                fieldName: "option3",
                value: "",
                type: 3,
              },
              {
                label: "选项4",
                fieldName: "option4",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "chooseBtnList":
            item.value.push([
              {
                label: "按钮拼音",
                fieldName: "btnPy",
                value: "",
                type: 1,
              },
              {
                label: "按钮中文",
                fieldName: "btnZh",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "optionLists":
            if (index !== null) {
              item.value[index][1].value.push([
                {
                  label: "卡片正面",
                  value: "",
                  fieldName: "front",
                  type: 3,
                },
                {
                  label: "卡片反面",
                  value: "",
                  fieldName: "back",
                  type: 3,
                },
              ]);
            } else {
              item.value.push([
                {
                  label: "按钮图片",
                  value: "",
                  fieldName: "buttonImg",
                  type: 3,
                },
                {
                  label: "卡片列表",
                  value: [
                    [
                      {
                        label: "卡片正面",
                        value: "",
                        fieldName: "front",
                        type: 3,
                      },
                      {
                        label: "卡片反面",
                        value: "",
                        fieldName: "back",
                        type: 3,
                      },
                    ],
                  ],
                  fieldName: "cardLists",
                  type: 9,
                },
              ]);
            }

            break;
          case "wordsList":
            item.value.push([
              {
                label: "词汇拼音",
                fieldName: "wordsPy",
                value: "",
                type: 1,
              },
              {
                label: "词汇汉字",
                fieldName: "wordsZh",
                value: "",
                type: 1,
              },
            ]);
            break;

          case "gameSpeakByPicList":
            item.value.push([
              {
                label: "图片",
                fieldName: "bgImg",
                value: "",
                type: 3,
              },
              {
                label: "汉字",
                fieldName: "hanZi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinYin",
                value: "",
                type: 1,
              },
              {
                label: "关卡序号图片",
                fieldName: "menuImg",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "eraseWordList":
            item.value.push([
              {
                label: "汉字",
                fieldName: "hanZi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinYin",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "allCards":
            item.value.push([
              {
                label: "卡片正面",
                fieldName: "front",
                value: "",
                type: 3,
              },
              {
                label: "卡片反面",
                fieldName: "back",
                value: "",
                type: 3,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "wordSortingCrossRiverList":
            item.value.push([
              {
                label: "汉字",
                fieldName: "firstHanZi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "firstPinYin",
                value: "",
                type: 1,
              },
              {
                label: "音频",
                fieldName: "firstAudioUrl",
                value: "",
                type: 2,
              },
              {
                label: "汉字",
                fieldName: "secondHanZi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "secondPinYin",
                value: "",
                type: 1,
              },
              {
                label: "音频",
                fieldName: "secondAudioUrl",
                value: "",
                type: 2,
              },
              {
                label: "汉字",
                fieldName: "thirdHanZi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "thirdPinYin",
                value: "",
                type: 1,
              },
              {
                label: "音频",
                fieldName: "thirdAudioUrl",
                value: "",
                type: 2,
              },
              {
                label: "汉字",
                fieldName: "fourthHanZi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "fourthPinYin",
                value: "",
                type: 1,
              },
              {
                label: "音频",
                fieldName: "fourthAudioUrl",
                value: "",
                type: 2,
              },
            ]);
            break;
          case "allImgs":
            item.value.push([
              {
                label: "选项图片",
                fieldName: "optionPic",
                value: "",
                type: 3,
              },
              {
                label: "句子图片",
                fieldName: "img",
                value: "",
                type: 3,
              },
              {
                label: "汉字",
                fieldName: "hanzi",
                value: "",
                type: 1,
              },
              {
                label: "拼音",
                fieldName: "pinyin",
                value: "",
                type: 1,
              },
            ]);
            break;
          case "puzzleList":
            item.value.push([
              {
                label: "笔画图片",
                fieldName: "strokeImg",
                value: "",
                type: 3,
              },
              {
                label: "翻转图片",
                filedName: "flipImg",
                value: "",
                type: 3,
              },
              {
                label: "笔画选项",
                fieldName: "strokeValue",
                value: "",
                options:
                  item.value[0] && item.value[0].length
                    ? item.value[0][2].options
                    : [],
                type: 60,
              },
              {
                label: "位置",
                fieldName: "position",
                value: "",
                type: 60,
                options: [
                  {
                    label: "left",
                    value: 1,
                  },
                  {
                    label: "middle",
                    value: 2,
                  },
                  {
                    label: "right",
                    value: 3,
                  },
                ],
              },
            ]);
            break;
          case "strokeImgList":
            item.value.push([
              {
                label: "未找到时的图片",
                fieldName: "strokeNotFondImg",
                value: "",
                type: 3,
              },
              {
                label: "找到笔画后的图片",
                fieldName: "strokeFoundImg",
                value: "",
                type: 3,
              },
            ]);
            break;
          case "clickImgSentenceList":
            item.value.push([
              {
                label: "点击图片",
                fieldName: "clickImg",
                value: "",
                type: 3,
              },
              {
                label: "展示图片",
                fieldName: "showImg",
                value: "",
                type: 3,
              },
              {
                label: "展示句子拼音",
                fieldName: "showPy",
                value: "",
                type: 1,
              },
              {
                label: "展示句子汉字",
                fieldName: "showZh",
                value: "",
                type: 1,
              },
            ]);
            break;
        }
        //根据汉字选择笔画模版 多个type9类型的数组需要分开判断,否则会导致添加其中一个所有的都会添加
        if (
          formData.fieldName === "strokeList" &&
          formData.fieldName === item.fieldName
        ) {
          item.value.push([
            {
              label: "笔画图片",
              fieldName: "strokeImg",
              value: "",
              type: 3,
            },
            {
              label: "笔画总数",
              fieldName: "strokeNum",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "firstHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "firstHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "firstHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "secondHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "secondHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "secondHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "thirdHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "thirdHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "thirdHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "fourthHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "fourthHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "fourthHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "fifthHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "fifthHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "fifthHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "sixthHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "sixthHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "sixthHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "seventhHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "seventhHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "seventhHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "eighthHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "eighthHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "eighthHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "ninthHanziStrokeImgList"
        ) {
          item.value.push([
            {
              label: "包含笔画图片",
              fieldName: "ninthHanziStrokeImg",
              value: "",
              type: 3,
            },
            {
              label: "对应右侧笔画序号",
              fieldName: "ninthHanziStrokeType",
              value: "",
              type: 1,
            },
          ]);
        }
        if (
          formData.fieldName === "wordsLists" &&
          formData.fieldName === item.fieldName
        ) {
          item.value.push([
            {
              label: "词汇拼音",
              fieldName: "wordsPy",
              value: "",
              type: 1,
            },
            {
              label: "词汇汉字",
              fieldName: "wordsZh",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "sentenceList"
        ) {
          item.value.push([
            {
              label: "句子拼音",
              fieldName: "sentencePy",
              value: "",
              type: 1,
            },
            {
              label: "句子汉字",
              fieldName: "sentenceZh",
              value: "",
              type: 1,
            },
          ]);
        } else if (
          formData.fieldName === item.fieldName &&
          formData.fieldName === "pyList"
        ) {
          item.value.push([
            {
              label: "拼音",
              fieldName: "pinyin",
              value: "",
              type: 1,
            },
          ]);
        }
      });
    },
    /**
     * @description: 数组编辑双向绑定逻辑
     * @param {*} data
     * @return {*}
     */
    handleDialogChange(data) {
      // console.log(data, this.currentPageInfo.contents)
      const { index, topIndex } = data;
      if (topIndex || topIndex === 0) {
        // 三层数组渲染逻辑
        // console.log('走三层数组渲染逻辑')
        this.currentPageInfo.contents.forEach((item) => {
          if (
            [
              "optionList",
              "listenChoosePicList",
              "practiceImgList",
              "optionLists",
              "basketBallPyList",
            ].indexOf(item.fieldName) > -1
          ) {
            // console.log(item.value[topIndex], 9999)
            item.value[topIndex].forEach((ele) => {
              if (
                [
                  "zhList",
                  "answerList",
                  "wordList",
                  "cardLists",
                  "optionList1",
                ].indexOf(ele.fieldName) > -1
              ) {
                const editArr = ele.value[index];
                if (editArr && editArr.length) {
                  editArr.forEach((i) => {
                    if (i.fieldName === data.fieldName) {
                      i.value = data.value;
                    }
                  });
                }
              }
            });
          }
          // switch (item.fieldName) {
          //   case 'optionList':
          //     // console.log(item.value[topIndex][2].value[index])
          //     const editArr = item.value[topIndex][2].value[index]
          //     if (editArr && editArr.length) {
          //       editArr.forEach((ele) => {
          //         if (ele.fieldName === data.fieldName) {
          //           ele.value = data.value
          //         }
          //       })
          //     }
          //     break
          //   // case 'listenChoosePicList':
          //   //   const listArr = item.value[topIndex][4].value[index]
          //   //   if (listArr && listArr.length) {
          //   //     listArr.forEach((ele) => {
          //   //       if (ele.fieldName === data.fieldName) {
          //   //         ele.value = data.value
          //   //       }
          //   //     })
          //   //   }
          //   //   break
          //   default:
          //     break
          // }
        });
      } else {
        this.currentPageInfo.contents.forEach((item) => {
          if (
            [
              "dialogList",
              "gameLinkUpList",
              "gameMirrorList",
              "gameRecognitionList",
              "gameFlipList",
              "phoneNumberList",
              "findByPictureList",
              "buttonList",
              "buttonPyList",
              "scratchPicList",
              "questionList",
              "ChooseImgList",
              "StructureList",
              "trainList",
              "puzzleImgList",
              "optionList",
              "addLevelList",
              "photoList",
              "questionInfoList",
              "structurePicList",
              "hanziList",
              "mirrorPictureList",
              "listenChoosePicList",
              "speakingTwoSentenceList",
              "practiceList",
              "questionLists",
              "TwitterGameList",
              "practiceList",
              "turnTableList",
              "practiceImgList",
              "cardList",
              "basketBallList",
              "chooseImgByListeningList",
              "basketBallPyList",
              "pickWordsByPicList",
              "picMenuChooseList",
              "chooseBtnList",
              "wordsList",
              "gameSpeakByPicList",
              "eraseWordList",
              "allCards",
              "wordSortingCrossRiverList",
              "allImgs",
              "strokeList",
              "firstHanziStrokeImgList",
              "secondHanziStrokeImgList",
              "thirdHanziStrokeImgList",
              "fourthHanziStrokeImgList",
              "fifthHanziStrokeImgList",
              "sixthHanziStrokeImgList",
              "seventhHanziStrokeImgList",
              "eighthHanziStrokeImgList",
              "ninthHanziStrokeImgList",
              "puzzleList",
              "wordsLists",
              "sentenceList",
              "pyList",
              "clickImgSentenceList",
              "bhList",
              "detailImg",
              "dragPicList",
            ].indexOf(item.fieldName) > -1
          ) {
            if (item.value[index] && item.value[index].length) {
              item.value[index].forEach((ele) => {
                // console.log(ele)

                if (ele.fieldName === data.fieldName) {
                  ele.value = data.value;
                }
                if (
                  ele.fieldName === "correctAnswer" &&
                  ["btnZhI", "btnZhII", "btnZhIII"].indexOf(data.fieldName) > -1
                ) {
                  const titleList =
                    ele.options && ele.options.length
                      ? ele.options.map((item) => item.title)
                      : [];

                  if (titleList.indexOf(data.fieldName) > -1) {
                    ele.options.forEach((i) => {
                      if (i.title === data.fieldName) {
                        i.label = data.value;
                        i.value = data.value;
                      }
                    });
                  } else {
                    ele.options.push({
                      label: data.value,
                      value: data.value,
                      title: data.fieldName,
                    });
                  }
                }
              });
            }
          }
        });
      }
    },
    /**
     * @description: 删除列表项
     * @param {*} index
     * @param {*} data
     * @return {*}
     */
    handleDeleteItem(index, topIndex, data) {
      // console.log(
      //   index,
      //   'topIndex',
      //   topIndex,
      //   this.currentPageInfo.contents,
      //   data
      // )
      const { fieldName } = data;
      // return
      if (topIndex || topIndex === 0) {
        this.currentPageInfo.contents.forEach((item) => {
          switch (fieldName) {
            case "listenChoosePicList":
              // 使用filter或者splice 都可以
              if (item.value[topIndex] && item.value[topIndex].length) {
                item.value[topIndex][5].value.splice(index, 1)
              }
              break;
            case "optionList":
              if (item.value[topIndex] && item.value[topIndex].length) {
                item.value[topIndex][2].value.splice(index, 1);
              }
              // console.log(item.value[topIndex][2].value)
              break;
            case "practiceImgList":
              if (item.value[topIndex] && item.value[topIndex].length) {
                item.value[topIndex][4].value.splice(index, 1);
              }
              break;
            case "optionLists":
              if (item.value[topIndex] && item.value[topIndex].length) {
                item.value[topIndex][1].value.splice(index, 1);
              }
              break;
            case "basketBallPyList":
              if (item.value[topIndex] && item.value[topIndex].length) {
                item.value[topIndex][1].value.splice(index, 1);
              }
              break;
            default:
              break;
          }
        });
      } else {
        this.currentPageInfo.contents.forEach((item) => {
          if (item.fieldName === data.fieldName) {
            item.value.splice(index, 1);
          }
        });
      }
    },
    handleSelectFieldChange(data) {
      const { value, index } = data;
      // console.log(value, index, '00')
      this.currentPageInfo.contents.forEach((item) => {
        if (item.fieldName === "syllabusList") {
          item.value[index][0]["value"] = value;
        }
      });
    },
    handleGetPinyinFieldchange(data) {
      const { options, index } = data;
      // console.log(options, index, '12')
      this.currentPageInfo.contents.forEach((item) => {
        if (item.fieldName === "syllabusList") {
          item.value[index][1]["options"] = options;
        }
      });
    },

    handleChangeMorePicture(data) {
      // console.log(data, '00000------00000')
      const { fieldName, value } = data;
      let list = [];
      this.currentPageInfo.contents.forEach((item, index) => {
        if (item.fieldName === fieldName) {
          item.value.push({
            value,
            label:
              "img" +
              (item.value.length + 1 < 10
                ? "0" + (item.value.length + 1)
                : item.value.length + 1),
          });
          list = item.value;
          // console.log(item.value, 'value22')
        }
        if (
          [
            "avatarList",
            "structureImgList",
            "storyImgList",
            "imgOptionList",
            "imgChooseList",
          ].indexOf(fieldName) > -1 &&
          [
            "dialogList",
            "structurePicList",
            "basketBallList",
            "puzzleList",
          ].indexOf(item.fieldName) > -1
        ) {
          item.value.forEach((ele) => {
            if (ele && ele.length) {
              ele.forEach((i) => {
                if (
                  [
                    "avatar",
                    "structurePic",
                    "answerImg",
                    "strokeValue",
                  ].indexOf(i.fieldName) > -1
                ) {
                  i.options = [...list];
                }
              });
            }
          });
        }
      });
    },
    handleMirrorUploadMorePicture(data) {
      const { fieldName, value, index } = data;
      // console.log(fieldName,value,index);

      let list = [];
      this.currentPageInfo.contents.forEach((item) => {
        if (
          [
            "imgList",
            "optionImg",
            "matchImg",
            "picChooseList",
            "imageList",
            "imgListOptions",
            "timeList",
          ].indexOf(fieldName) > -1 &&
          [
            "gameMirrorList",
            "questionList",
            "ChooseImgList",
            "optionList",
            "choosePicList",
            "buttonList",
            "listenChoosePicList",
            "practiceList",
            "questionLists",
            "basketBallPyList",
          ].indexOf(item.fieldName) > -1
        ) {
          item.value.forEach((ele, eleIndex) => {
            // console.log(eleIndex, index)
            if (eleIndex === index && ele && ele.length) {
              ele.forEach((i) => {
                if (i.fieldName === fieldName) {
                  i.value.push({
                    value,
                    label:
                      "img" +
                      (i.value.length + 1 < 10
                        ? "0" + (i.value.length + 1)
                        : i.value.length + 1),
                  });
                  list = i.value;
                }
                if (
                  [
                    "correctOption",
                    "answerImg",
                    "correctAnswer",
                    "answerPic",
                    "correctPic",
                  ].indexOf(i.fieldName) > -1
                ) {
                  i.options = [...list];
                }
              });
            }
          });
        }
      });
    },
    handleDeletePicture(data) {
      const { fieldName, index } = data;
      let list = [];
      this.currentPageInfo.contents.forEach((item) => {
        if (item.fieldName === fieldName) {
          item.value.splice(index, 1);
          list = item.value;
        }
        if (
          [
            "avatarList",
            "structureImgList",
            "storyImgList",
            "imgOptionList",
          ].indexOf(fieldName) > -1 &&
          ["dialogList", "structurePicList", "basketBallList"].indexOf(
            item.fieldName
          ) > -1
        ) {
          item.value.forEach((ele) => {
            if (ele && ele.length) {
              ele.forEach((i) => {
                if (
                  ["avatar", "structurePic", "answerImg"].indexOf(i.fieldName) >
                  -1
                ) {
                  i.options = [...list];
                }
              });
            }
          });
        }
      });
    },
    handleDeleteMirrorPicture(data) {
      const { fieldName, index, selectIndex } = data;
      // console.log(data, 'data')

      let list = [];
      this.currentPageInfo.contents.forEach((item) => {
        if (
          [
            "imgList",
            "matchImg",
            "picChooseList",
            "imageList",
            "optionImg",
          ].indexOf(fieldName) > -1 &&
          [
            "gameMirrorList",
            "questionList",
            "ChooseImgList",
            "choosePicList",
            "buttonList",
            "listenChoosePicList",
            "practiceList",
            "questionLists",
            "timeList",
            "optionList",
          ].indexOf(item.fieldName) > -1
        ) {
          item.value.forEach((ele, eleIndex) => {
            if (eleIndex === selectIndex && ele.length) {
              ele.forEach((i) => {
                if (i.fieldName === fieldName) {
                  i.value.splice(index, 1);
                  list = i.value;
                }
                if (
                  [
                    "correctOption",
                    "answerImg",
                    "answerPic",
                    "correctAnswer",
                  ].indexOf(i.fieldName) > -1
                ) {
                  i.options = [...list];
                }
              });
            }
          });
        }
      });
    },
    /**
     * @description: 取消新增和编辑
     * @return {*}
     */
    handleCancelEditAddEvent() {
      this.currentPageInfo = {};
      this.currentPageTemplateList = [];
    },
    /**
     * @description: 保存课件
     * @return {*}
     */
    async handleSaveCoursewareEvent() {
      const { sortNo, contents, templateId, id } = this.currentPageInfo;
      // console.log(this.currentPageInfo, this.lessonPageList)

      let contentList = [];
      const isEditPage = !!this.lessonPageList.find(
        (item) => item.sortNo === sortNo
      ).templateId;

      // console.log(isEditPage, sortNo)
      if (isEditPage) {
        contentList = this.lessonPageList.filter((item) => !item.bySelf);
      }
      const removerFields = ["lessonId", "chapterId", "levelId"];
      contentList = (isEditPage ? contentList : this.lessonPageList).map(
        (item) => {
          const isTarget = item.sortNo === sortNo;
          return {
            content: isTarget
              ? JSON.stringify(
                  contents.filter(
                    (ele) => removerFields.indexOf(ele.fieldName) === -1
                  )
                )
              : item.content && item.content.length
              ? JSON.stringify(item.content)
              : "",
            isRepeat: true,
            templateId: isTarget ? templateId : item.templateId,
            templateType: item.templateType,
            templateTypeName: item.templateTypeName,
          };
        }
      );

      // 插入可以添加多个页面的模板

      if (!isEditPage) {
        const curAddPage =
          this.lessonPageList.find((item) => item.sortNo === sortNo) || {};
        const { isRepeat, templateId, templateType, templateTypeName } =
          curAddPage;
        if (isRepeat) {
          contentList.splice(sortNo, 0, {
            content: null,
            isRepeat: true,
            templateId,
            templateType,
            templateTypeName,
          });
        }
      }
      this.handleUpdateCourseware(id, contentList);
    },
    async handleUpdateCourseware(id, contentList) {
      const sendData = {
        id,
        lessonId: this.filterForm.lessonId,
        chapterId: this.filterForm.chapterId,
        levelId: this.filterForm.levelId,
        contentList,
      };
      // console.log(sendData)
      // return
      const res = await addOrUpdateKidsCourseware(sendData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.currentPageInfo = {};
        this.handleSearchCourse();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    handleClosePreview() {
      this.previewVisible = false;
      this.previewInfo = {};
    },
  },
  beforeDestroy() {
    localStorage.removeItem("currentPageInfo");
  },
};
</script>

<style lang="scss" scoped>
.testAdd {
  position: absolute;
  right: 30px;
  top: 80px;
  color: #2472b3;
  border: 1px solid #333;
  padding: 5px 10px;
  border-radius: 10px;
}
.testDel {
  position: absolute;
  right: 100px;
  top: 80px;
  color: #2472b3;
  border: 1px solid #333;
  padding: 5px 10px;
  border-radius: 10px;
}
.editor-content-container {
  height: 100%;
  width: 100%;
  .filter-area::v-deep {
    margin-bottom: 20px;
    .select-area {
      display: flex;
      .nk-select-container {
        margin-right: 20px;
      }
    }
    .search-btn {
      margin-top: 20px;
      span {
        display: inline-block;
        padding: 0 16px;
        transition: all 0.3s ease-out;
        font-size: 12px;
        background: #2472b3;
        color: #fff;
        border-radius: 3px;
        height: 35px;
        line-height: 35px;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        .el-loading-spinner {
          margin-top: -17px !important;
        }
      }
    }
  }
  .template-area {
    padding-bottom: 20px;
    // display: flex;
    .template-left {
      height: 100%;
      width: fit-content;
      .lesson-No {
        margin-bottom: 10px;
        color: #2472b3;
        font-size: 16px;
        font-weight: 600;
        // margin-left: 20px;
        height: 30px;
        line-height: 30px;
      }
      .customScrollbar {
        padding: 10px 30px;
        height: calc(100% - 50px);
      }
      .template-wrapper {
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        gap: 30px;
        .page-item {
          height: 120px;
          width: 180px;
          box-sizing: border-box;
          border-radius: 20px;
          border: 1.5px solid #f1f1f0;

          transition: all 0.3s ease-out;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &:hover {
            border-color: #f6c752;
          }

          .shadow-area {
            display: flex;
            opacity: 0;
            background: #fff;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease-out;
            &:hover {
              opacity: 0.7;
            }
            .title {
              font-size: 12px;
              color: #333;
              font-weight: 600;
            }
            .icon {
              display: flex;
              gap: 10px;
              i {
                font-size: 20px;
                color: #2472b3;
                cursor: pointer;
                transition: all 0.2s ease-out;
                &:hover {
                  font-weight: 600;
                  transform: translateY(-1px);
                }
              }
            }
          }
          .haveShadow {
            opacity: 1;
            .title {
              color: #4d4d4d;
            }
          }
        }
      }
    }
    .template-right {
      padding-left: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease-out;
      min-height: 800px;

      .header-template {
        margin-bottom: 20px;
        .template-label {
          margin-bottom: 10px;
          font-weight: 600;
        }
        .template-wrapper {
          display: flex;
          gap: 20px;
          padding-left: 30px;
          flex-wrap: wrap;
          .template-item {
            height: 96px;
            width: 160px;
            box-sizing: border-box;
            border-radius: 20px;
            border: 2px solid #f1f1f0;
            transition: all 0.3s ease-out;
            position: relative;
            &:hover {
              border-color: #2472b3;
            }
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;

            display: flex;
            flex-direction: column;
            p {
              font-size: 12px;
            }
          }
          .tempateActive {
            border-color: #2472b3;
          }
        }
      }
      .page-content {
        transition: all 0.3s ease-out;
        flex: 1;
        display: flex;
        overflow: auto;
        padding: 0 40px;
        .page-wrapper {
          width: fit-content;
          display: flex;
          .view-left {
            transition: all 0.3s ease-out;
            // flex: 1;
            // width: 700px;
            width: 730px;
            // height: 550px;
            height: 600px;
          }
          .field-right {
            transition: all 0.3s ease-out;

            margin-left: 20px;
            min-width: 400px;
            // height: 550px;
            max-height: 600px;
            overflow: auto;
            height: 100%;
            box-sizing: border-box;
            border: 2px solid #f1f1f0;
            padding: 20px 30px;
          }
        }
      }
      .action {
        margin-top: 10px;
        margin-left: 78%;
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        .reset-btn {
          .btn {
            background: #2472b3;
            color: #fff;
            border-radius: 4px;
            padding: 10px 16px;
            font-size: 12px;
            cursor: pointer;
            transition: all 0.3s ease-out;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.preview-dialog {
  .el-dialog {
    min-width: 890px;
    width: 80%;
    border-radius: 30px;
    max-width: 960px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 70px 80px;
  }
  .preview-container {
    position: relative;

    .icon-close {
      position: absolute;
      top: -35px;
      right: -32px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #f1f1f0;
      i {
        font-size: 14px;
        cursor: pointer;
      }
      &:hover {
        background-color: #2472b3;
        color: #fff;
      }
    }
  }
  .preview-content {
    height: 600px;
    width: 730px;
    margin: auto;
    overflow: hidden;

    .template-view-container {
    }
  }
}
</style>
