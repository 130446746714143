var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "story-page-container layout-border" },
    [
      _c("div", {
        staticClass: "story-page-content",
        style: {
          "background-image": "url(" + _vm.bgImg + ")",
        },
      }),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.numBers,
        },
        on: {
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }