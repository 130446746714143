var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "frog-jump-game-container layout-border",
      class: "lesson-" + _vm.lessonNum,
    },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.currentIndex,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.durationArr.length - 1,
              currentIndex: _vm.clickIndex,
            },
          }),
          _c("div", { ref: "moveGameBox", staticClass: "bg-img" }, [
            _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
            _c(
              "div",
              {
                staticClass: "click-area",
                style: { width: _vm.scrollWidth + "px", height: "100%" },
              },
              [
                _vm._l(_vm.durationArr, function (item, index) {
                  return _c("div", {
                    key: index,
                    staticClass: "item-box leaf-step",
                    class: "leaf-steps-" + index,
                    on: {
                      click: function ($event) {
                        return _vm.handleClickLeaf("socket")
                      },
                    },
                  })
                }),
                _c("div", { staticClass: "safeImg" }, [
                  _c("img", { attrs: { src: _vm.safeImg, alt: "" } }),
                ]),
              ],
              2
            ),
            _c(
              "div",
              {
                ref: "student",
                staticClass: "move-wrapper",
                style: { width: _vm.scrollWidth + "px", height: "100%" },
              },
              [
                _c(
                  "div",
                  { staticClass: "frog-img", class: "step-" + _vm.step },
                  [_c("img", { attrs: { src: _vm.imgInfo.frogImg, alt: "" } })]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }