var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-listen-container" }, [
    _c(
      "div",
      { staticClass: "listen-content" },
      [
        _c("SceneSwitchThumbnails", {
          attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
          on: { changeTheNumbers: _vm.changeTheNumbers },
        }),
        _vm.isImgPage
          ? _c("div", { staticClass: "choose-img-area" }, [
              _c("div", { staticClass: "img-area" }, [
                _vm.allImgList && _vm.allImgList.length
                  ? _c(
                      "div",
                      { staticClass: "img-content" },
                      _vm._l(
                        _vm.allImgList[_vm.currentIndex],
                        function (item, index) {
                          return _c(
                            "span",
                            {
                              key: index + "img",
                              staticClass: "img-item",
                              class: {
                                active: index === _vm.chooseImgIndex,
                                isCorrect:
                                  item.id ===
                                    _vm.answerList[_vm.currentIndex] &&
                                  index === _vm.chooseImgIndex,
                              },
                            },
                            [
                              _c("img", {
                                attrs: { src: item.bgImg, alt: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChooseImg(item.id, index)
                                  },
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "trumpet-area-right" }, [
                _c(
                  "span",
                  {
                    staticClass: "trumpet-area",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.playPinyinAudio.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("AudioPlayer", {
                      attrs: {
                        isPlay: _vm.isStartPlaying,
                        audioUrl: _vm.songUrl,
                        title:
                          _vm.chooseHanziList[_vm.currentIndex] &&
                          _vm.chooseHanziList[_vm.currentIndex].wordsHanzi,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _c("div", { staticClass: "choose-hanzi-area" }, [
              _vm.chooseHanziList && _vm.chooseHanziList.length
                ? _c("div", { staticClass: "answer-area" }, [
                    _c("div", { staticClass: "answer-img" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.chooseHanziList[_vm.currentIndex].bgImg,
                          alt: "",
                        },
                      }),
                    ]),
                    !_vm.isShowAllWords
                      ? _c(
                          "div",
                          { staticClass: "answer-hanzi-area" },
                          _vm._l(
                            _vm.chooseHanziList[_vm.currentIndex]
                              .hanziRightList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "right",
                                  staticClass: "hanzi-item",
                                  class: {
                                    active: _vm.insertIndex === index,
                                    hasInserted:
                                      _vm.rightHanziIndexArr.indexOf(
                                        index + 1
                                      ) > -1,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pinyin font-pinyin-medium",
                                    },
                                    [_vm._v(_vm._s(item.pinyin))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "font-hanzi-medium" },
                                    [_vm._v(_vm._s(item.hanzi))]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", { staticClass: "answer-right-hanzi-area" }, [
                          _c("div", { staticClass: "right-hanzi-box" }, [
                            _c(
                              "span",
                              { staticClass: "pinyin font-pinyin-medium" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.chooseHanziList[_vm.currentIndex]
                                      .wordsPinyin
                                  )
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.chooseHanziList[_vm.currentIndex]
                                    .wordsHanzi
                                )
                              ),
                            ]),
                          ]),
                        ]),
                  ])
                : _vm._e(),
              _vm.chooseHanziList && _vm.chooseHanziList.length
                ? _c(
                    "div",
                    { staticClass: "choose-hanzi-area" },
                    _vm._l(
                      _vm.chooseHanziList[_vm.currentIndex].hanziChooseList,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index + "choose",
                            staticClass: "hanzi-item",
                            class: {
                              dispear: _vm.choosedHanziArr.indexOf(index) > -1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleChooseHanzi(item.id, index)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "pinyin font-pinyin-medium" },
                              [_vm._v(_vm._s(item.pinyin))]
                            ),
                            _c("span", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.hanzi)),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }