var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "customlesson-left-small-picture1",
      class: {
        drawingPage: _vm.isDrawingPage,
        rowMenuStyle: _vm.isRowMenu,
        verySmallMenu: _vm.smallMenu,
        smallMenu: _vm.menuSize == "small",
      },
    },
    [
      _c(
        "div",
        { staticClass: "small-picture-content" },
        _vm._l(_vm.buttonList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "small-picture-content-img",
              on: {
                mouseover: function ($event) {
                  return _vm.mouseover(index)
                },
                mouseleave: function ($event) {
                  return _vm.mouseleave(index)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.clickHandle(item, index)
                },
              },
            },
            [
              _c("img", {
                staticClass: "canClick",
                class: {
                  isActive:
                    (_vm.cilckIndex === index && !item.isPuzzlePage) ||
                    (_vm.cilckIndex === null && index == 0),
                  completed:
                    _vm.numBers > item.endNumberRange && !item.isPuzzlePage,
                  hover:
                    _vm.isHover &&
                    _vm.hoverIndex === index &&
                    !item.isPuzzlePage,
                  blueBorder: item.isPuzzlePage && index === 0,
                  yelllowBorder:
                    item.isPuzzlePage &&
                    (index === 1 || _vm.numBers > item.endNumberRange),
                },
                attrs: { src: item.image, alt: "" },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }