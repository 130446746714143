var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "find-stroke-review-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _c("div", { staticClass: "find-stroke-box" }, [
        _c("div", { staticClass: "find-stroke-content" }, [
          _c("div", { staticClass: "find-stroke-area" }, [
            _c("div", { staticClass: "find-stroke-content-left" }, [
              _c(
                "div",
                { staticClass: "left-bg-img-content" },
                [
                  _c("img", { attrs: { src: _vm.gridList.backImg, alt: "" } }),
                  _vm._l(_vm.strokeType, function (item, index) {
                    return _c(
                      "div",
                      { key: index + "strokeImg", staticClass: "stroke-img" },
                      _vm._l(
                        _vm.singleStrokeList[index],
                        function (ele, indexSon) {
                          return _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: ele.found,
                                expression: "ele.found",
                              },
                            ],
                            key: indexSon + "son",
                            staticClass: "strokeImg",
                            attrs: { src: ele.image },
                          })
                        }
                      ),
                      0
                    )
                  }),
                  _c(
                    "div",
                    { class: "wrapper-" + _vm.lessonNum },
                    _vm._l(_vm.singleStrokeList, function (item, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentIndex === index,
                              expression: "currentIndex === index",
                            },
                          ],
                          key: index + "click",
                          staticClass: "click-wrapper",
                          class: {
                            blueStroke: index === 0,
                            redStroke: index === 1,
                            yellowStroke: index === 2,
                          },
                        },
                        _vm._l(
                          _vm.singleStrokeList[index],
                          function (ele, indexSon) {
                            return _c(
                              "div",
                              {
                                key: indexSon + "single",
                                staticClass: "singleClick",
                                class: "stroke-" + indexSon,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "line1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.strokeClicked(
                                          index,
                                          indexSon
                                        )
                                      },
                                    },
                                  },
                                  [_c("div", { staticClass: "line2" })]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }),
                    0
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "find-stroke-content-right" }, [
              _c(
                "div",
                { staticClass: "hanziFoundWrapper" },
                _vm._l(_vm.strokeInfoList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "hanziFoundCont" },
                    [
                      _c("img", { attrs: { src: item.image } }),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(item.found) +
                            " / " +
                            _vm._s(item.total) +
                            " "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }