var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "review-train-container layout-border",
      class: "lesson-" + _vm.lessonNb,
    },
    [
      _c("div", { staticClass: "review-train-box" }, [
        _c(
          "div",
          {
            staticClass: "train-area",
            class: "step-" + _vm.stepNum + " origin-" + _vm.step,
          },
          [
            _c("img", {
              ref: "train",
              staticClass: "train-img",
              attrs: {
                src: require("@/assets/img/20-Train-review/review-train.svg"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "review-content-wrapper",
                style: { width: _vm.trainlWidth + "px", height: "78%" },
              },
              [
                _vm.stepNum >= 1
                  ? _c(
                      "div",
                      { staticClass: "review-content vocabulary fadeIn" },
                      [
                        _c(
                          "div",
                          { staticClass: "review-area" },
                          _vm._l(
                            _vm.vocabularyReviewList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "review",
                                  staticClass: "train-item-col",
                                },
                                _vm._l(item, function (ele, indexSon) {
                                  return _c(
                                    "div",
                                    {
                                      key: indexSon + "son",
                                      staticClass: "train-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pinyinArea pinyin font-pinyin-medium pinyin",
                                        },
                                        [_vm._v(" " + _vm._s(ele.pinyin) + " ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hanziArea font-hanzi-medium",
                                        },
                                        [_vm._v(_vm._s(ele.hanzi))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.stepNum >= 2
                  ? _c(
                      "div",
                      {
                        staticClass: "review-content knowledge fadeIn",
                        class: {
                          isFullWidth:
                            _vm.knowledgeReviewList[0][0].isFullWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "review-area" },
                          _vm._l(
                            _vm.knowledgeReviewList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "review",
                                  staticClass: "train-item-col",
                                },
                                _vm._l(item, function (ele, indexSon) {
                                  return _c(
                                    "div",
                                    {
                                      key: indexSon + "son",
                                      staticClass: "train-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pinyinArea pinyin font-pinyin-medium pinyin",
                                        },
                                        [_vm._v(" " + _vm._s(ele.pinyin) + " ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "hanziArea font-hanzi-medium",
                                        },
                                        [_vm._v(_vm._s(ele.hanzi))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.stepNum >= 3
                  ? _c(
                      "div",
                      { staticClass: "review-content pinyinWord fadeIn" },
                      [
                        _c(
                          "div",
                          { staticClass: "review-area" },
                          _vm._l(
                            _vm.pinyinWordReviewList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "review",
                                  staticClass: "train-item-col",
                                },
                                _vm._l(item, function (ele, indexSon) {
                                  return _c(
                                    "div",
                                    {
                                      key: indexSon + "son",
                                      staticClass: "train-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vocabularyArea pinyin font-pinyin-large",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(ele.vocabulary) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.stepNum >= 4
                  ? _c(
                      "div",
                      { staticClass: "review-content strokes fadeIn" },
                      [
                        _c(
                          "div",
                          { staticClass: "review-area" },
                          _vm._l(_vm.strokesReviewList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index + "review",
                                staticClass: "train-item-col",
                              },
                              _vm._l(item, function (ele, indexSon) {
                                return _c(
                                  "div",
                                  {
                                    key: indexSon + "son",
                                    staticClass: "stroke-img",
                                  },
                                  [
                                    ele.strokeImg
                                      ? _c("img", {
                                          attrs: {
                                            src: ele.strokeImg,
                                            alt: "",
                                          },
                                        })
                                      : _c(
                                          "div",
                                          { staticClass: "train-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vocabularyArea font-pinyin-medium",
                                              },
                                              [_vm._v(_vm._s(ele.character))]
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }