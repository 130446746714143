var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choose-img-by-sentence-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("div", { staticClass: "game-content" }, [
        _vm.titleInfo
          ? _c("div", { staticClass: "title-area" }, [
              _c("div", { staticClass: "title" }, [
                _c(
                  "div",
                  { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                  [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                ),
                _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                  _vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " "),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "back-ground-img-area" }, [
          _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
        ]),
        _vm.isShowshadow
          ? _c("div", { staticClass: "pic-words-area" }, [
              _c(
                "div",
                { staticClass: "pic-box" },
                _vm._l(_vm.sentenceList, function (item, index) {
                  return _c("div", { key: index, staticClass: "pic-item" }, [
                    index === _vm.currentId - 1
                      ? _c("img", { attrs: { src: item.bgImg, alt: "" } })
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "words-box" },
                _vm._l(_vm.sentenceList, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index === _vm.currentId - 1 && !_vm.lock,
                          expression: "index === currentId - 1 && !lock",
                        },
                      ],
                      key: index,
                      staticClass: "hanzi-item",
                      class: { noPadding: !item.hanzi },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pinyin sentence-hanzi font-pinyin-medium",
                        },
                        [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "sentence-hanzi font-hanzi-medium" },
                        [_vm._v(" " + _vm._s(item.hanzi) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "game-area" }, [
          _c("div", {
            staticClass: "pic-area",
            on: {
              click: function ($event) {
                return _vm.handleClickPic(null)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "side-menu-area" },
            _vm._l(_vm.picList, function (item, index) {
              return _c("div", { key: index, staticClass: "menu-item" }, [
                _c("img", {
                  class: { hasClick: _vm.showIdList.indexOf(item.id) > -1 },
                  attrs: {
                    src:
                      _vm.showIdList.indexOf(item.id) > -1
                        ? item.menuImg
                        : item.menuGrayImg,
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickPic(item.id)
                    },
                  },
                }),
              ])
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }