var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apple-tree-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.currentIndex,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
      _c("SceneSwitchThumbnails", {
        attrs: {
          buttons: _vm.buttonListImported
            ? _vm.buttonListImported
            : _vm.buttonList,
          numBers: _vm.numBers,
        },
        on: { changeTheNumbers: _vm.changeTheNumbers },
      }),
      _c("div", { staticClass: "game-content" }, [
        _c("div", { staticClass: "bg-img" }, [
          _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
        ]),
        _c("div", { staticClass: "person-and-tree-area" }, [
          _c(
            "div",
            { staticClass: "person-area" },
            _vm._l(_vm.personImgList, function (item, index) {
              return _c(
                "div",
                { key: index + "person", staticClass: "person-item" },
                [
                  index === _vm.currentIndex
                    ? _c("img", { attrs: { src: item, alt: "" } })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "tree-area" }, [
            _c(
              "div",
              { staticClass: "trea-box" },
              _vm._l(_vm.treeImgList, function (item, index) {
                return _c(
                  "div",
                  { key: index + "tree", staticClass: "tree-item" },
                  [
                    _c("img", {
                      class: { isHidden: !(index === _vm.clickIndex) },
                      attrs: { src: item, alt: "" },
                    }),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "basket-area" },
              _vm._l(_vm.basketImglIst, function (item, index) {
                return _c(
                  "div",
                  { key: index + "person", staticClass: "basket-item" },
                  [
                    _c("img", {
                      class: { isHidden: !(index === _vm.clickIndex) },
                      attrs: { src: item, alt: "" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickBasket("socket")
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }