var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse-container" },
    [
      _c(
        "el-collapse",
        { attrs: { accordion: "" } },
        _vm._l(_vm.reviewLesson, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "collapse-item" },
            [
              _c("div", { staticClass: "lesson-img" }, [
                item.status
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/07-Stars/star-small.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/lock.svg"),
                        alt: "",
                      },
                    }),
              ]),
              _c(
                "el-collapse-item",
                {
                  staticClass: "collapse-content",
                  class: { active: item.status === false },
                  attrs: {
                    title: item.lessonTitle,
                    name: index,
                    disabled: !item.status,
                  },
                },
                [
                  _c("div", { staticClass: "review-class-nav" }, [
                    _c("div", { staticClass: "function" }, [
                      _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/19-Dashboard/icon-goals.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "title font-menu-large" }, [
                        _vm._v("Goals"),
                      ]),
                    ]),
                    _c("div", { staticClass: "function" }, [
                      _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/19-Dashboard/icon-review.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "title font-menu-large" }, [
                        _vm._v("Review"),
                      ]),
                    ]),
                    _c("div", { staticClass: "function" }, [
                      _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/19-Dashboard/icon-homework.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "title font-menu-large" }, [
                        _vm._v("Homework"),
                      ]),
                    ]),
                    item.reviewClassify === 0
                      ? _c("div", { staticClass: "function" }, [
                          _c("div", { staticClass: "image" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/19-Dashboard/icon-song.svg"),
                                alt: "",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "title font-menu-large" }, [
                            _vm._v("Song"),
                          ]),
                        ])
                      : _c("div", { staticClass: "function" }, [
                          _c("div", { staticClass: "image" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/19-Dashboard/icon-writing.svg"),
                                alt: "",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "title font-menu-large" }, [
                            _vm._v("Writing Sheet"),
                          ]),
                        ]),
                    _c("div", { staticClass: "function" }, [
                      _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/19-Dashboard/icon-evaluation.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Evaluation"),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }