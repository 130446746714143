var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "move-game-container layout-border",
      class: "lesson" + _vm.lessonNum,
    },
    [
      _c(
        "div",
        { staticClass: "move-game-box position-relative" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
          }),
          _c("div", { staticClass: "move-game-area" }, [
            _c(
              "div",
              {
                ref: "moveGameBox",
                staticClass: "move-game-content",
                on: { click: _vm.handleClickBgImg },
              },
              [
                _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
                _c("div", { staticClass: "frontImgWrapper" }, [
                  _c("img", { attrs: { src: _vm.frontImg, alt: "" } }),
                ]),
                _c(
                  "div",
                  {
                    ref: "student",
                    staticClass: "student-img",
                    class: "step-" + _vm.step,
                  },
                  [_c("img", { attrs: { src: _vm.studentImg, alt: "" } })]
                ),
                _vm.isAction
                  ? _c(
                      "div",
                      {
                        staticClass: "answer-area",
                        class: "dialog-" + _vm.step,
                      },
                      [
                        _vm.isQuestionOpen
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/16-GAMES/G12-map/bubble-question.svg"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm.isAnswerOpen
                          ? _c("img", {
                              attrs: { src: _vm.correctAnswerImg, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }