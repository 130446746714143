var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c("div", { staticClass: "mainCont kids_dragStrokeToImagePuzzle" }, [
        _c("div", { staticClass: "background" }, [
          _c("div", { staticClass: "backgroundImg_n_DragArea" }, [
            _c("div", { staticClass: "imageWrapper" }, [
              _c("img", {
                staticClass: "objectDisplay",
                attrs: { src: _vm.ObjectList[_vm.question].image },
              }),
            ]),
            _c(
              "div",
              { staticClass: "bottonsWrapper" },
              _vm._l(_vm.chineseList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "content-words-two",
                    on: {
                      click: function ($event) {
                        return _vm.foundAnswer(index)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                      _vm._v(_vm._s(item.pinying)),
                    ]),
                    _c("span", { staticClass: "font-hanzi-medium" }, [
                      _vm._v(_vm._s(item.hanzi)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "strokesFoundBoxes" }, [
            _c("div", { staticClass: "outerBox" }, [
              _vm.answersfound > 0
                ? _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockFoundImage },
                  })
                : _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockNotFoundImage },
                  }),
            ]),
            _c("div", { staticClass: "outerBox" }, [
              _vm.answersfound > 1
                ? _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockFoundImage },
                  })
                : _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockNotFoundImage },
                  }),
            ]),
            _c("div", { staticClass: "outerBox" }, [
              _vm.answersfound > 2
                ? _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockFoundImage },
                  })
                : _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockNotFoundImage },
                  }),
            ]),
            _c("div", { staticClass: "outerBox" }, [
              _vm.answersfound > 3
                ? _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockFoundImage },
                  })
                : _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockNotFoundImage },
                  }),
            ]),
            _c("div", { staticClass: "outerBox" }, [
              _vm.answersfound > 4
                ? _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockFoundImage },
                  })
                : _c("img", {
                    staticClass: "strokesFoundimage",
                    attrs: { src: _vm.strockNotFoundImage },
                  }),
            ]),
          ]),
        ]),
      ]),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.answersfound,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }