<template>
  <div class="sing-page-container">
    <audio
      @canplay="getDuration"
      @timeupdate="updateTime"
      ref="audio"
      :src="songSrc"
    ></audio>
    <div class="sing-page-content">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <!-- 琴弦背景 -->
      <div class="customlesson-content-sing">
        <img
          :src="require('@/assets/img/16-GAMES/G07-ktv/ktv-background.svg')"
        />
      </div>

      <!-- 整首歌播放 -->
      <div class="audio-content" v-show="isPlayWholeSong">
        <div class="content-text">
          <div class="audio-img">
            <img
              class="bg-img"
              :src="
                require('@/assets/img/16-GAMES/G07-ktv/ktv-record-player.svg')
              "
            />
            <img
              class="animation-img"
              :src="require('@/assets/img/16-GAMES/G07-ktv/ktv-record.gif')"
            />
          </div>

          <transition name="el-fade-in">
            <div class="sentence-box" v-if="!isFinishPlayingSong">
              <template v-for="(item, index) in singList.lyricsArr">
                <div
                  class="sentence-content"
                  :key="index + 'lyrics'"
                  v-if="currentSentenceIndex === item.id"
                >
                  <div class="origin item">
                    <div class="font-pinyin-medium pinyin center">
                      {{ item.spelling }}
                    </div>
                    <div class="font-hanzi-medium">
                      {{ item.chineseWords }}
                    </div>
                  </div>
                  <div class="animation item">
                    <div
                      class="font-pinyin-medium pinyin center"
                      :style="{
                        'background-position': `${distance}px 0`,
                      }"
                      :class="{
                        'singleSentence-yellow':
                          item.id === currentSentenceIndex,
                      }"
                    >
                      {{ item.spelling }}
                    </div>
                    <div
                      class="font-hanzi-medium"
                      :style="{
                        'background-position': `${distance}px 0`,
                      }"
                      :class="{
                        'singleSentence-yellow':
                          item.id === currentSentenceIndex,
                      }"
                    >
                      {{ item.chineseWords }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </transition>
        </div>
      </div>
      <!-- 按钮 -->
      <!--   -->
      <div class="customlesson-button-img" v-if="isPlayWholeSong">
        <div
          class="slow-button"
          @click="handleSongVelocity(0)"
          @mouseenter="isSlowButtonHover = true"
          @mouseleave="isSlowButtonHover = false"
        >
          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-slow.svg"
            alt=""
            v-show="!isSlowButtonHover && !isChooseSlow"
          />
          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-slow-hover.svg"
            alt=""
            v-show="isSlowButtonHover || isChooseSlow"
          />
        </div>
        <div
          class="quick-button"
          @click="handleSongVelocity(1)"
          @mouseenter="isQuickButtonHover = true"
          @mouseleave="isQuickButtonHover = false"
        >
          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-fast.svg"
            alt=""
            v-show="!isQuickButtonHover && !isChooseQuick"
          />
          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-fast-hover.svg"
            alt=""
            v-show="isQuickButtonHover || isChooseQuick"
          />
        </div>
        <div class="button-img" @click="handlePlayAndPauseAudio">
          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-play.svg"
            alt=""
            v-show="!isPlaySong"
          />
          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-lyrics.svg"
            alt=""
            v-show="isPlaySong"
          />
        </div>

        <div
          class="customlesson-content_foseOneImg"
          @click="switchSentences"
          :class="{ lyricsClick: isLyricsClick }"
        >
          <!-- ktv-button-lyrics-active -->

          <img
            src="@/assets/img/16-GAMES/G07-ktv/ktv-button-lyrics-active.svg"
            alt=""
            v-show="isLyricsClick"
          />
          <img
            src="@/assets/img/16-GAMES/G07-ktv/yellow-all.svg"
            alt=""
            v-show="!isLyricsClick"
          />
        </div>
      </div>

      <!-- 单句歌词播放 -->
      <div class="lyrics-box-demo" v-if="!isPlayWholeSong">
        <div class="lyrics-box">
          <div class="lyrics-content">
            <div
              class="lyrics-item"
              v-for="(item, index) in singList.lyricsArr"
              :key="index + 'lyrics'"
            >
              <div class="font-pinyin-medium pinyin">{{ item.spelling }}</div>
              <div class="font-hanzi-medium">
                {{ item.chineseWords }}
              </div>
            </div>
          </div>

          <div class="lyrics-content animation-all">
            <div
              class="lyrics-item"
              v-for="(item, index) in singList.lyricsArr"
              :key="index + 'lyrics-'"
              @click="handleClickSingleSentence(item)"
            >
              <div
                class="font-pinyin-medium pinyin"
                :style="{
                  'background-position': `${distance}px 0`,
                }"
                :class="{ 'singleSentence-blue': showBgcIndex === item.id }"
              >
                {{ item.spelling }}
              </div>
              <div
                class="font-hanzi-medium"
                :style="{
                  'background-position': `${distance}px 0`,
                }"
                :class="{ 'singleSentence-blue': showBgcIndex === item.id }"
              >
                {{ item.chineseWords }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 返回整首歌 -->
      <div class="customlesson-content-img" v-if="!isPlayWholeSong">
        <img src="@/assets/img/16-GAMES/G07-ktv/ktv-record.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    singList: {
      type: Object,
      require: true,
      default: () => {},
    },
    songInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
    timeStampList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.audioDom = this.$refs.audio;
      this.songSrc = this.songInfo.songUrl;
      this.audioDom.play();
    });

    this.$bus.$on("singSlowAndQuick", ({ type }) => {
      this.handleSongVelocity(type, true);
    });

    this.$bus.$on("showLyricsList", () => {
      this.switchSentences(true);
    });
    this.$bus.$on("singPlay", () => {
      this.handlePlayAndPauseAudio("socket", true);
    });
    this.$bus.$on("playlyrics", ({ item }) => {
      this.handleClickSingleSentence(item, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("singSlowAndQuick");
    this.$bus.$off("showLyricsList");
    this.$bus.$off("singPlay");
    this.$bus.$off("playlyrics");
  },
  data() {
    return {
      isSlowButtonHover: false,
      isQuickButtonHover: false,
      isLyricsClick: false,
      isPlayWholeSong: true,
      currentSentenceIndex: 0,
      isPlaySong: false,

      isLastStep: true,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
      duration: null,
      currentTime: null,
      songSrc: "",
      isFinishPlayingSong: true,
      showBgcIndex: false,
      audioDom: null,
      playbackRate: 1,
      singlelyricsInfo: {},

      distance: -500,
      speed: 4.5,

      isChooseSlow: false,
      isChooseQuick: false,

      velocitySocketInfo: {},
      palySongSocketInfo: {},
      showSentenceSocketInfo: {},
      playSingleSentenceSocketInfo: {},
    };
  },
  watch: {
    currentSentenceIndex() {
      this.distance = -500;
      if (this.isPlayWholeSong) {
        this.handleLyricsShow();
      }
    },
    velocitySocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000701,
          data: { value },
          text: "sing页面按钮socket 播放速度",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    palySongSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000704,
          data: { value },
          text: "sing页面按钮socket 播放暂停按钮",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    showSentenceSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000703,
          data: { value },
          text: "sing页面按钮socket 播放速度",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    playSingleSentenceSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000702,
          data: { value },
          text: "sing页面按钮socket 播放速度",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    /**
     * @description:初始化数据
     * @return {*}
     */
    initData() {
      this.isPlaySong = false;
      this.distance = -500;
      this.speed = 4.5;
      this.audioDom.playbackRate = this.playbackRate;
      this.audioDom.load();
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    /**
     * @description: 处理音乐播放和暂停
     * @return {*}
     */
    handlePlayAndPauseAudio(socket, isFromSocket = false) {
      console.log("+++++++++++++++");

      if (!isFromSocket) {
        console.log("+++++++++++++++");
        this.palySongSocketInfo = {
          value: Math.random(),
        };
      }
      this.isPlaySong = !this.isPlaySong;
      if (this.isPlaySong) {
        // console.log("开始了");
        if (this.currentSentenceIndex) {
          this.handleLyricsShow();
        }
        this.playingSong();
      } else {
        // console.log("暂停了");
        clearInterval(this.timer);
        this.pausedSong();
      }
    },
    /**
     * @description: 暂停音乐
     * @return {*}
     */
    pausedSong() {
      this.audioDom.pause();
    },
    /**
     * @description: 播放音乐
     * @return {*}
     */
    playingSong() {
      this.audioDom.play();
    },
    /**
     * @description:获取歌曲时长
     * @return {*}
     */
    getDuration() {
      // console.log(this.$refs.audio.duration); //此时可以获取到duration
      this.duration = this.$refs.audio.duration;
      // console.log(this.duration);
    },
    /**
     * @description: 歌曲播放事件
     * @param {*} 
     * @return {*}
     */
    updateTime(e) {
      if (this.audioDom.ended) {
        this.initData();
      }
      this.isFinishPlayingSong = this.audioDom.ended;
      const currentTime = e.target.currentTime; //获取audio当前播放时间
       console.log(currentTime,999999)
      for (let i = 0; i < this.timeStampList.length; i++) {
        const startTime = this.timeStampList[i];
        const endTime = this.timeStampList[i + 1];
        if (startTime < currentTime && currentTime < endTime) {
          this.currentSentenceIndex = i + 1;
        }
        if (currentTime > startTime && i === this.timeStampList.length - 1) {
          this.currentSentenceIndex = 0;
        }
      }
    },
    /**
     * @description: 字幕动画
     * @return {*}
     */
    handleLyricsShow() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.distance += this.speed;
        if (this.distance > 0) {
          clearInterval(this.timer);
        }
      }, 50);
    },

    /**
     * @description: 控制播放速度
     * @param {*} type
     * @return {*}
     */
    handleSongVelocity(type, isFromSocket = false) {
      if (!isFromSocket) {
        this.velocitySocketInfo = {
          type,
          value: Math.random(),
        };
      }
      this.audioDom.pause();
      if (type) {
        //加速
        this.isChooseQuick = !this.isChooseQuick;
        this.isChooseSlow = false;
        if (this.isChooseQuick) {
          this.speed = 7;
          this.playbackRate = 1.4;
        } else {
          this.speed = 4.5;
          this.playbackRate = 1;
        }
      } else {
        // 减速
        this.isChooseSlow = !this.isChooseSlow;
        this.isChooseQuick = false;

        if (this.isChooseSlow) {
          this.speed = 2;
          this.playbackRate = 0.6;
        } else {
          this.speed = 4.5;
          this.playbackRate = 1;
        }
      }
      this.audioDom.playbackRate = this.playbackRate;
      this.isPlaySong = true;
      this.audioDom.play();
    },
    switchSentences(isFromSocket = false) {
      if (!isFromSocket) {
        this.showSentenceSocketInfo = {
          value: Math.random(),
        };
      }
      this.initData();
      this.isLyricsClick = !this.isLyricsClick;
      this.isPlayWholeSong = !this.isPlayWholeSong;
    },
    handleClickSingleSentence(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.playSingleSentenceSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      this.initData();
      this.handleLyricsShow();

      this.showBgcIndex = item.id;

      this.audioDom.currentTime = this.timeStampList[item.id - 1];
      this.isPlaySong = true;
      this.playingSong();
      setTimeout(() => {
        this.pausedSong();
      }, (this.timeStampList[item.id] - this.timeStampList[item.id - 1]) * 1000);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.sing-page-container {
  width: 100%;
  height: 100%;
  .sing-page-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: relative;
    .content-text {
      width: 55%;
      height: 47%;
      // background-image: url("../../assets/img/16-GAMES/G07-ktv/ktv-record-player.svg");
      background-size: 100% 100%;
    }
    .custom_treeImg {
      min-width: 30%;
      min-height: 20%;
      background-color: #fff;
      border-radius: 58px;
      padding: 5% 5% 5% 10%;
    }
    .customlesson-content-sing {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 22%;
      // background-size: 100% 100%;
      img {
        width: 100%;
      }
    }

    .customlesson-content-img {
      width: 15%;
      height: 20%;
      position: absolute;
      bottom: 30px;
      left: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 100%;
      }
    }
    .customlesson-button-img {
      z-index: 10;
      position: absolute;
      top: 33%;
      height: 50%;
      right: 3%;
      width: 10%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      .button-img {
        width: 40%;
        padding: 10%;
        background-color: #214f96;
        border-radius: 35px;
        margin-top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .button-img:hover {
        background-color: #1b4589;
      }
      .slow-button,
      .quick-button {
        width: 60%;
        border-radius: 35px;
        margin-top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .customlesson-content_foseOneImg {
        cursor: pointer;
        width: 40%;
        padding: 10%;
        background-color: #214f96;
        border-radius: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .lyricsClick {
        background: #f4d737;
      }
    }

    .audio-content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content-text {
        width: 65%;
        display: flex;
        flex-direction: column;
        .audio-img {
          padding-bottom: 2%;
          flex: 3;
          position: relative;
          .bg-img {
            width: 100%;
          }
          .animation-img {
            width: 95%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .sentence-box {
          flex: 1;
          .sentence-content {
            display: flex;
            justify-content: center;
            position: relative;
            .item {
              padding: 2% 18%;
              border-radius: 55px;
              color: #fff;
              .center {
                text-align: center;
              }
            }
            .origin {
              background: #224e96;
            }
          }
        }
      }
    }
    .lyrics-box-demo {
      width: 70%;
      height: 70%;
      display: flex;
      background: #fff;
      border-radius: 50px;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
      .lyrics-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #224e96;
        .lyrics-item {
          cursor: pointer;
          margin-bottom: 5%;
        }
      }
    }
  }
}

.txt-py {
  font-family: "STHEITISCPINYIN-LIGHT";
}
@keyframes loop {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 0px 0;
  }
}

.origin {
  opacity: 0.9;
}
.animation {
  position: absolute;
  top: 0;
  margin: 0 auto;
  .center {
    text-align: center;
  }
}
.animation-all {
  font-weight: 200;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
// 剪切文字
.singleSentence-yellow {
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  background-image: url("../../../../assets/img/16-GAMES/G07-ktv/tes-y.png");
  background-position: -500px 0;
  background-repeat: no-repeat;
  color: transparent;
  transition: background-position;
  transition-timing-function: cubic-bezier(0.29, 0.26, 0.8, 0.51);
  // animation-name: loop;
  // animation-timing-function: cubic-bezier(0.29, 0.26, 0.8, 0.51);
}
.singleSentence-blue {
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  background-image: url("../../../../assets/img/16-GAMES/G07-ktv/test.png");
  background-position: -500px 0;
  background-repeat: no-repeat;
  color: transparent;
  transition: background-position;
  transition-timing-function: cubic-bezier(0.29, 0.26, 0.8, 0.51);
  // animation: loop 4s cubic-bezier(0.29, 0.26, 0.8, 0.51);
}
</style>
