var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor-content-container" },
    [
      _c("div", { staticClass: "filter-area" }, [
        _c(
          "div",
          { staticClass: "select-area" },
          [
            _c("NKSelect", {
              attrs: {
                options: _vm.levelOptions,
                typeString: "level",
                placeholder: "请选择Level",
              },
              on: { selectChagne: _vm.handleListInit },
              model: {
                value: _vm.filterForm.levelId,
                callback: function ($$v) {
                  _vm.$set(_vm.filterForm, "levelId", $$v)
                },
                expression: "filterForm.levelId",
              },
            }),
            _c("NKSelect", {
              attrs: {
                options: _vm.chapterOptions,
                typeString: "chapter",
                placeholder: "请选择Chapter",
                disabled: !_vm.filterForm.levelId,
              },
              on: { selectChagne: _vm.handleListInit },
              model: {
                value: _vm.filterForm.chapterId,
                callback: function ($$v) {
                  _vm.$set(_vm.filterForm, "chapterId", $$v)
                },
                expression: "filterForm.chapterId",
              },
            }),
            _c("NKSelect", {
              attrs: {
                options: _vm.lessonOptions,
                placeholder: "请选择Lesson",
                typeString: "lesson",
                disabled: !_vm.filterForm.chapterId,
              },
              on: { selectChagne: _vm.handleListInit },
              model: {
                value: _vm.filterForm.lessonId,
                callback: function ($$v) {
                  _vm.$set(_vm.filterForm, "lessonId", $$v)
                },
                expression: "filterForm.lessonId",
              },
            }),
            _c("NKSelect", {
              attrs: {
                options: _vm.coursewareList,
                disabled: true,
                placeholder: "课型",
              },
              model: {
                value: _vm.lessonType,
                callback: function ($$v) {
                  _vm.lessonType = $$v
                },
                expression: "lessonType",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "search-btn" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.searchLoading,
                  expression: "searchLoading",
                },
              ],
              attrs: { "element-loading-spinner": "el-icon-loading" },
              on: { click: _vm.handleSearchCourse },
            },
            [_vm._v("Search")]
          ),
        ]),
      ]),
      _vm.lessonPageList.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.searchLoading,
                  expression: "searchLoading",
                },
              ],
              staticClass: "template-area",
              attrs: { "element-loading-spinner": "el-icon-loading" },
            },
            [
              _c("div", { staticClass: "template-left" }, [
                _c("div", { staticClass: "lesson-No" }, [
                  _vm._v(
                    " LESSON " +
                      _vm._s(_vm.getFormatLessonId(_vm.filterForm.lessonId)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "customScrollbar" }, [
                  _c(
                    "div",
                    { staticClass: "template-wrapper" },
                    _vm._l(_vm.lessonPageList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "page-item",
                          style: {
                            backgroundImage: "url('" + item.bgImg + "')",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "shadow-area",
                              class: { haveShadow: !item.isFinished },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v(_vm._s(item.templateTypeName)),
                              ]),
                              _c("div", { staticClass: "icon" }, [
                                item.isFinished
                                  ? _c("i", {
                                      staticClass: "el-icon-view",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePagePreview(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                item.isFinished
                                  ? _c("i", {
                                      staticClass: "el-icon-edit",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePageEdit(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                item.isFinished
                                  ? _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePageDelete(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                !item.isFinished
                                  ? _c("i", {
                                      staticClass: "el-icon-circle-plus",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAddPage(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm.currentPageInfo.id
                ? _c("div", { staticClass: "template-right" }, [
                    _c("div", { staticClass: "header-template" }, [
                      _c("div", { staticClass: "template-label" }, [
                        _vm._v("TEMPLATE"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "template-wrapper" },
                        _vm._l(_vm.currentPageTemplateList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.templateId,
                              staticClass: "template-item",
                              class: {
                                tempateActive:
                                  item.templateId ===
                                  _vm.currentPageInfo.templateId,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickTemplate(item)
                                },
                              },
                            },
                            [
                              _c("p", [_vm._v(_vm._s(item.tempalteName))]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(item.templateField.split("_")[0])
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(item.templateField.split("_")[1])
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "action" }, [
                      _c("div", { staticClass: "reset-btn" }, [
                        !_vm.isInEdit
                          ? _c(
                              "span",
                              {
                                staticClass: "btn",
                                on: { click: _vm.handleReset },
                              },
                              [_vm._v("reset")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "reset-btn" }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn",
                            on: { click: _vm.handlePreviewTemplate },
                          },
                          [_vm._v("preview")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "page-content" }, [
                      _vm.currentPageInfo.id
                        ? _c("div", { staticClass: "page-wrapper" }, [
                            _c(
                              "div",
                              { staticClass: "view-left" },
                              [
                                _c("TemplateViewer", {
                                  attrs: { pageInfo: _vm.currentPageInfo },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "field-right" },
                              [
                                _c("FieldEditor", {
                                  attrs: { pageInfo: _vm.currentPageInfo },
                                  on: {
                                    fieldchangeEvent: _vm.handleFieldChange,
                                    addNewSyllabusItem:
                                      _vm.handleAddNewSyllabusItem,
                                    selectFieldchangeEvent:
                                      _vm.handleSelectFieldChange,
                                    getPinyinFieldchangeEvent:
                                      _vm.handleGetPinyinFieldchange,
                                    dialogValueChange: _vm.handleDialogChange,
                                    deleteListItem: _vm.handleDeleteItem,
                                    uploadMorePicture:
                                      _vm.handleChangeMorePicture,
                                    deletePicture: _vm.handleDeletePicture,
                                    cancelEditAddEvent:
                                      _vm.handleCancelEditAddEvent,
                                    saveCoursewareEvent:
                                      _vm.handleSaveCoursewareEvent,
                                    mirrorUploadMorePicture:
                                      _vm.handleMirrorUploadMorePicture,
                                    deleteMirrorPicture:
                                      _vm.handleDeleteMirrorPicture,
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "preview-dialog",
          attrs: { visible: _vm.previewVisible },
          on: {
            "update:visible": function ($event) {
              _vm.previewVisible = $event
            },
            close: _vm.handleClosePreview,
          },
        },
        [
          _c("div", { staticClass: "preview-container" }, [
            _c(
              "span",
              {
                staticClass: "icon-close",
                on: { click: _vm.handleClosePreview },
              },
              [_c("i", { staticClass: "el-icon-close" })]
            ),
            _c(
              "div",
              { staticClass: "preview-content" },
              [
                _vm.previewInfo && _vm.previewInfo.templateField
                  ? _c("TemplateViewer", {
                      attrs: { pageInfo: _vm.previewInfo },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }