var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speaking-page-container layout-border" },
    [
      _vm.isfinishCheck
        ? _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
            nativeOn: {
              click: function ($event) {
                return _vm.startPlayingVideo("socket")
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "speaking-page-content" }, [
        _c("div", { staticClass: "video-area" }, [
          _c("video", {
            ref: "videoBox",
            staticClass: "video-player",
            attrs: { autoplay: false, poster: _vm.pageInfo.bgImg },
            on: { canplay: _vm.getDuration, timeupdate: _vm.toRecordPage },
          }),
        ]),
        _vm.pageInfo.titlePinyin && !_vm.isVideoPlay
          ? _c("div", { staticClass: "title-area" }, [
              _c("div", { staticClass: "title-content" }, [
                _c("div", { staticClass: "words pinyin font-pinyin-medium" }, [
                  _vm._v(" " + _vm._s(_vm.pageInfo.titlePinyin) + " "),
                ]),
                _c("div", { staticClass: "words font-hanzi-medium" }, [
                  _vm._v(" " + _vm._s(_vm.pageInfo.titleHanzi) + " "),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.isRecordPageOpen
          ? _c("div", { staticClass: "recorder-area" }, [
              _vm.isShowCheckGif
                ? _c("div", {
                    directives: [
                      {
                        name: "checkgif",
                        rawName: "v-checkgif",
                        value: _vm.checkGif,
                        expression: "checkGif",
                      },
                    ],
                    staticClass: "checkGif",
                    class: { checkGifWrong: !_vm.checkGif },
                  })
                : _vm._e(),
              _c("div", { staticClass: "red-background-border" }),
              _c("div", { staticClass: "recorder-content" }, [
                _c("div", { staticClass: "trumpet-area" }, [
                  _c("div", { staticClass: "trumpet-content" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/06-Sound/sound-white.svg"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.playVoice("socket")
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "words-area" }, [
                  _c(
                    "div",
                    { staticClass: "words-content" },
                    _vm._l(
                      _vm.currentWordsInfo.singleWordList,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "word-item",
                            class: {
                              watingStatus:
                                _vm.isFinishRecording && item.status === 2,
                              correctColor:
                                item.status != 0 && _vm.isFinishRecording,
                              wrongColor:
                                item.status === 0 && _vm.isFinishRecording,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "words pinyin font-pinyin-medium",
                              },
                              [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "words font-hanzi-medium" },
                              [_vm._v(" " + _vm._s(item.hanzi) + " ")]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                !_vm.isFinishRecording
                  ? _c("div", { staticClass: "recorder-button" }, [
                      _c(
                        "div",
                        {
                          staticClass: "button-img",
                          on: {
                            click: function ($event) {
                              return _vm.startRecorder("socket")
                            },
                          },
                        },
                        [
                          !_vm.isStartRecording
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/img/16-GAMES/G14-tape-recorder/record.svg"),
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/img/16-GAMES/G14-tape-recorder/record-start.svg"),
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm.isShowTime
                        ? _c("div", { staticClass: "recorder-time" }, [
                            _vm._v(" " + _vm._s(_vm.recorderTime) + " "),
                          ])
                        : _vm._e(),
                    ])
                  : _c("div", { staticClass: "finish-record-area" }, [
                      _c("div", { staticClass: "button-play" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G14-tape-recorder/button-play.svg"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.playRecorder("socket")
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "button-record" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G14-tape-recorder/record-next.svg"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.backToRecord("socket")
                            },
                          },
                        }),
                      ]),
                    ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }