var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "practice-page-four-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        {
          staticClass: "finger",
          on: {
            click: function ($event) {
              return _vm.handleClickCamera()
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/img/17-Tutorials/instructions.svg"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "practice-page-four-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm._l(_vm.imgList, function (item, index) {
            return [
              index === _vm.numBers - 1
                ? _c(
                    "div",
                    {
                      key: index,
                      class: {
                        imgLeft: item.position === 0,
                        imgRight: item.position === 1,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.hasClicked ? item.answerImg : item.bgImg,
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _c("div", { staticClass: "customlesson-conter-right-text" }, [
            _c("div", { staticClass: "right-text" }, [
              _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                _vm._v(_vm._s(_vm.titleInfo.pinyin)),
              ]),
              _c("span", { staticClass: "font-hanzi-medium" }, [
                _vm._v(_vm._s(_vm.titleInfo.hanzi)),
              ]),
            ]),
            _vm._m(0),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "camera" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/16-GAMES/G04-photo-studio/camera.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }