var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-view-container" },
    [
      _vm.currentComponent
        ? _c(_vm.currentComponent, {
            tag: "component",
            attrs: { componentProps: _vm.pageInfo.propsInfo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }