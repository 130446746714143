var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "write-stroke-page-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
        nativeOn: {
          click: function ($event) {
            return _vm.startDrawStroke.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "stroke-page-box" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.menuButton, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _vm.isShowEye
            ? _c(
                "div",
                {
                  staticClass: "write-stroke-page-eye-btn",
                  on: {
                    mouseover: function ($event) {
                      _vm.eyeIsHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.eyeIsHover = false
                    },
                    click: _vm.handleClickEyeButton,
                  },
                },
                [
                  !_vm.eyeIsHover
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/16-GAMES/G19-writing-strokes/button-eye-on-hover.svg"),
                          alt: "",
                        },
                      }),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "write-stroke-content" },
            [
              _c("transition", { attrs: { name: "el-fade-in" } }, [
                _c("div", { staticClass: "write-pinyin-area" }, [
                  _c("div", { staticClass: "write-stroke-content-left" }, [
                    _c("div", { staticClass: "write-stroke-left-content" }, [
                      _c("div", { staticClass: "left-content-border" }),
                      _c("div", { staticClass: "left-content" }, [
                        _vm.isTipPage
                          ? _c("div", { staticClass: "gif-img" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.currentPinyinInfo.tipsImg,
                                  alt: "",
                                },
                              }),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "pinyin-area",
                                on: {
                                  mouseup: function ($event) {
                                    return _vm.endDrawing($event)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pinyin-img" }, [
                                  _c("img", {
                                    staticClass: "stroke-square-img",
                                    attrs: {
                                      draggable: "false",
                                      src: _vm.currentPinyinInfo.pinyinImg,
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "canvas-area" },
                                  [_c("KidsWhiteBoard", { ref: "drawArea" })],
                                  1
                                ),
                              ]
                            ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }