var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainCont" },
    [
      _c("div", { staticClass: "background" }, [
        _c("div", { staticClass: "backgroundImg_n_DragArea" }, [
          _c(
            "div",
            { staticClass: "firstLine" },
            _vm._l(6, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox bottombox" },
                [
                  index > 1
                    ? _c("img", {
                        staticClass: "cloud",
                        attrs: { src: _vm.cloudImage },
                      })
                    : _vm._e(),
                  index > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "button",
                          class: {
                            yellowBG:
                              _vm.chineseList[index - 2].clicked == true,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.foundAnswer(index - 2)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.chineseList[index - 2].showContent,
                                  expression:
                                    "chineseList[index - 2].showContent",
                                },
                              ],
                              staticClass: "font-pinyin-medium pinyin",
                            },
                            [_vm._v(_vm._s(_vm.chineseList[index - 2].pinying))]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.chineseList[index - 2].showContent,
                                  expression:
                                    "chineseList[index - 2].showContent",
                                },
                              ],
                              staticClass: "font-hanzi-medium",
                            },
                            [_vm._v(_vm._s(_vm.chineseList[index - 2].hanzi))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: { src: _vm.arrowImage },
                  }),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "secondLine" },
            _vm._l(4, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox leftbox" },
                [
                  _c("img", {
                    staticClass: "cloud",
                    attrs: { src: _vm.cloudImage },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "button buttonsLeft",
                      class: {
                        yellowBG: _vm.chineseList[index + 4].clicked == true,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(index + 4)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.chineseList[index + 4].showContent,
                              expression: "chineseList[index + 4].showContent",
                            },
                          ],
                          staticClass: "font-pinyin-medium pinyin",
                        },
                        [_vm._v(_vm._s(_vm.chineseList[index + 4].pinying))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.chineseList[index + 4].showContent,
                              expression: "chineseList[index + 4].showContent",
                            },
                          ],
                          staticClass: "font-hanzi-medium",
                        },
                        [_vm._v(_vm._s(_vm.chineseList[index + 4].hanzi))]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "thirdLine" },
            _vm._l(5, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox topbox" },
                [
                  _c("img", {
                    staticClass: "cloud",
                    attrs: { src: _vm.cloudImage },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "button buttonTop",
                      class: {
                        yellowBG: _vm.chineseList[index + 8].clicked == true,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(index + 8)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.chineseList[index + 8].showContent,
                              expression: "chineseList[index + 8].showContent",
                            },
                          ],
                          staticClass: "font-pinyin-medium pinyin",
                        },
                        [_vm._v(_vm._s(_vm.chineseList[index + 8].pinying))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.chineseList[index + 8].showContent,
                              expression: "chineseList[index + 8].showContent",
                            },
                          ],
                          staticClass: "font-hanzi-medium",
                        },
                        [_vm._v(_vm._s(_vm.chineseList[index + 8].hanzi))]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "fourthLine" },
            _vm._l(2, function (index) {
              return _c(
                "div",
                { key: index, staticClass: "buttonBox rightbox" },
                [
                  index < 2
                    ? _c("img", {
                        staticClass: "cloud",
                        attrs: { src: _vm.cloudImage },
                      })
                    : _vm._e(),
                  index < 2
                    ? _c(
                        "div",
                        {
                          staticClass: "button buttonsRight",
                          class: {
                            yellowBG:
                              _vm.chineseList[index + 13].clicked == true,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.foundAnswer(index + 13)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.chineseList[index + 13].showContent,
                                  expression:
                                    "chineseList[index + 13].showContent",
                                },
                              ],
                              staticClass: "font-pinyin-medium pinyin",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.chineseList[index + 13].pinying)
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.chineseList[index + 13].showContent,
                                  expression:
                                    "chineseList[index + 13].showContent",
                                },
                              ],
                              staticClass: "font-hanzi-medium",
                            },
                            [_vm._v(_vm._s(_vm.chineseList[index + 13].hanzi))]
                          ),
                        ]
                      )
                    : _c("img", {
                        staticClass: "treasure",
                        attrs: {
                          src: [
                            _vm.isLastStep
                              ? _vm.treasureImageOpen
                              : _vm.treasureImage,
                          ],
                        },
                      }),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "wheelWrapper", on: { click: _vm.ClickedWheel } },
            [
              _c("img", {
                staticClass: "wheel",
                attrs: { src: _vm.wheelImage },
              }),
              _c(
                "div",
                {
                  staticClass: "pointerWrapper",
                  class: {
                    rotateAnimation1:
                      _vm.isStartAnimation && _vm.wheelPosition === 0,
                    rotateAnimation2:
                      _vm.isStartAnimation && _vm.wheelPosition === 1,
                    rotateAnimation3:
                      _vm.isStartAnimation && _vm.wheelPosition === 2,
                    rotateAnimation4:
                      _vm.isStartAnimation && _vm.wheelPosition === 3,
                    rotateAnimation5:
                      _vm.isStartAnimation && _vm.wheelPosition === 4,
                  },
                  style: {
                    transform:
                      "rotate(" +
                      _vm.wheelList[_vm.wheelPosition].position +
                      "deg)",
                  },
                },
                [
                  _c("img", {
                    staticClass: "pointer",
                    attrs: { src: _vm.pointerImage },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.answersfound,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }