var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "write-page-heng-container" }, [
    _c(
      "div",
      { staticClass: "write-stroke-page-container layout-border" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
          nativeOn: {
            click: function ($event) {
              return _vm.startWritingStroke.apply(null, arguments)
            },
          },
        }),
        _c("div", { staticClass: "stroke-page-box" }, [
          _c(
            "div",
            { staticClass: "write-stroke-content" },
            [
              _c("transition", { attrs: { name: "el-fade-in" } }, [
                _c("div", { staticClass: "write-stroke-area" }, [
                  _c("div", { staticClass: "write-stroke-content-left" }, [
                    _c("div", { staticClass: "write-stroke-left-content" }, [
                      _c("div", { staticClass: "left-content-border" }),
                      _c("div", { staticClass: "left-content" }, [
                        _c("div", { staticClass: "stroke-pinyin" }, [
                          _c(
                            "span",
                            { staticClass: "pinyin font-pinyin-large" },
                            [_vm._v(_vm._s(_vm.currentStrokeInfo.pinyin))]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "stroke-area",
                            on: {
                              mousedown: function ($event) {
                                return _vm.startDrawing()
                              },
                              mouseup: function ($event) {
                                return _vm.endDrawing($event)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "hanzi-img" },
                              [
                                _vm.isShowEye
                                  ? _c("KidsWhiteBoard", { ref: "drawArea" })
                                  : _vm._e(),
                                _c("div", { staticClass: "hengIcon" }, [
                                  !_vm.showStroke
                                    ? _c("img", {
                                        staticClass: "stroke-square-img",
                                        attrs: {
                                          src: _vm.currentStrokeInfo.strokeImg,
                                          alt: "",
                                        },
                                      })
                                    : _c("img", {
                                        staticClass: "stroke-square-img",
                                        attrs: {
                                          src: _vm.currentStrokeInfo
                                            .redStrokeImg,
                                          alt: "",
                                        },
                                      }),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "write-stroke-content-right" }, [
                    _c(
                      "div",
                      { staticClass: "right-img-content" },
                      _vm._l(_vm.showImgList, function (item, index) {
                        return _c(
                          "div",
                          { key: index + "show", staticClass: "img-item" },
                          [
                            _vm.currentIndex > index
                              ? _c("img", {
                                  attrs: { src: item.showRedImg, alt: "" },
                                })
                              : _c("img", {
                                  attrs: { src: item.showImg, alt: "" },
                                }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }