<template>
  <div class="darg-to-dress-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNO">
      <Star
        :totalStarNumber="answerList.length"
        :currentIndex="step"
      />
   
      <!-- <div class="background-area">
        <img :src="bgImg" class="bg-img" />
        <template v-for="(item, index) in bgImgList">
          <img
            :src="item.image"
            class="bg-img-item"
            :key="index + 'bg'"
            v-if="dropId === item.id"
          />
        </template>
      </div> -->
     <div class="left-content">
      <!-- 开始拖拽 -->
      <div class="drag-area">
        <div
          v-for="(item, index) in optionImgList"
          class="option-item"
          :class="'option-' + item.id"
          :key="index + 'drag'"
        >
          <img
            :class="{ notShow: dragId === item.id }"
            :src="item.image"
            draggable="true"
            @dragstart.stop="handleDragImg($event, item.id)"
            @drop.stop="Sdrop($event)"
            @dragend.stop="dragend()"
          />
        </div>
      </div>
    </div>
    <!-- 拖拽区域 -->
    <div class="right-content">
       <div class="title-area">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            Zhōngguó zǎofàn
          </div>
          <div class="title-hanzi font-hanzi-medium">中国早饭</div>
        </div>
      </div>
     <div
        class="drop-area dropBox"
        draggable="true"
        @drop.stop="handleDropImg($event)"
        @dragover.stop="allowDrop($event)"
      ></div>
    </div>
  </div>

  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "@/components/Course/GamePage/Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "DragToFoodGame",
  props: {
    optionImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
     answerList: {
      type: Array,
      require: true,
      default: () => [],
    },
    
    lessonNO: {
      type: Number,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
   
   
  },
  components: {
    PageButton,
    Star,
  },
  data() {
    return {
      clickIndex: 0,
      isLastStep: false,
      showHnaziIndex: false,
      dispearIdList: [],
      dropIdList: [],
      dragId: null,
      dropId: null,
      currentDom: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dropImgSocketInfo: {},
      dragImgSocketInfo: {},
      step: 0,
    };
  },
  watch: {
    dropImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005701,
          data: { value },
          text: "DragToGressGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005702,
          data: { value },
          text: "DragToGressGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropImgEvent", () => {
      this.handleDropImg("socket", true);
    });
    this.$bus.$on("dragImgEvent", ({ id }) => {
      this.handleDragImg("socket", id, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropImgEvent");
    this.$bus.$off("dragImgEvent");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleDragImg(event, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      this.currentDom = event.currentTarget;
      this.dragId = id;
      if (this.dragId === id) {
         }
    },
    handleDropImg(event, isFromSocket = false) {
      console.log("拖拽成功");
      if (!isFromSocket) {
        this.dropImgSocketInfo = {
          value: Math.random(),
        };
      }
      if(this.answerList.indexOf(this.dragId)!=-1){
      event.target.appendChild(this.currentDom);
            this.step++;
       playCorrectSound(true, false);
      }else{
       playCorrectSound(false);
      }

       if(this.step===this.answerList.length){
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
       }
      // this.dispearIdList = [];
      // this.dispearIdList.push(this.dragId);
      // this.dropId = this.dragId;

      // if (this.lessonNO === 21) {
      //   playCorrectSound();
      //   startConfetti();
      //   this.isLastStep = true;
      // } else {
      //   if (this.hasSentence) {
      //     this.showHnaziIndex = this.dropId - 1;
      //   }
      //   playCorrectSound(true, false);
      //   if (this.dropIdList.indexOf(this.dropId) !== -1) {
      //     return;
      //   }
      //   this.dropIdList.push(this.dropId);
      //   this.step++;
      //   if (this.dropIdList.length === this.bgImgList.length) {
      //     playCorrectSound();
      //     startConfetti();
      //     this.isLastStep = true;
      //   }
      // }
    },
    Sdrop(event) {
      event.preventDefault();
    },
    allowDrop(event) {
      event.preventDefault();
    },
    dragend() {
      this.dragId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.option-List {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.darg-to-dress-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    
    .left-content{
      background: #112E5D;
      width: 50%;
    border-radius: 48px 0 0 48px;
    }
    .right-content{
      background: #F4F8FD;
      width: 50%;
      border-radius: 0 48px 48px 0;
      border:22px solid #E4E8EF;
      .title-area {
        z-index: 999;
        position: absolute;
        left: 63.5%;
        // left: 0;
        top: 12%;
        width: 20%;
        height: 17%;
        background: #cd4c3f;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        color: #fff;
        .title {
          .title-pinyin,
          .title-hanzi {
            text-align: center;
          }
        }
}
    };
    .drop-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .drag-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        cursor: pointer;
        width: 90%;
        -webkit-user-drag: inherit;
      }
      .option-item {
        position: absolute;
        -webkit-user-drag: auto;
        z-index: 99;
      }
      .notShow {
        opacity: 0;
      }
      .dispear {
        opacity: 0;
      }
    }

    .hanzi-item {
      width: 40%;
      margin: 0 auto;
      background: #cd4c3f;
      position: absolute;
      bottom: 5%;
      left: 30%;
      padding: 1.5% 0%;
      color: #fff;
      border-radius: 40px;
      .sentence-hanzi,
      .sentence-pinyin {
        text-align: center;
      }
    }
  }
  .lesson-21 {
    .drag-area {
      .option-1 {
        width: 10%;
        height: 8%;
        top: 10%;
        left: 5%;
      }
      .option-2 {
       width: 10%;
        height: 8%;
        top: 10%;
        left: 20%;
      }
      .option-3 {
        width: 10%;
        height: 8%;
        top: 10%;
        left: 32%;
      }
      .option-4 {
        width: 10%;
        height: 8%;
        top: 45%;
        left: 5%;
      }
      .option-5 {
        width: 10%;
        height: 8%;
        top: 45%;
        left: 20%;
      }
      .option-6 {
        width: 10%;
        height: 8%;
        top: 45%;
        left: 32%;
      }
     .option-7 {
        width: 10%;
        height: 8%;
        top: 76%;
        left: 5%;
      }
      .option-8 {
        width: 10%;
        height: 8%;
        top: 76%;
        left: 20%;
      }
      .option-9 {
        width: 10%;
        height: 8%;
        top: 76%;
        left: 32%;
      }
     
    }
  }
  .lesson-21 {
    .dropBox {
      // background: #000;
      display: block;
      position: absolute;
      width: 40%;
      height: 60%;
      top: 25%;
      left: 50%;
      z-index: 8;
      display: flex;
      justify-content:space-evenly;
      flex-wrap: wrap;
      padding:30px;
      // justify-content: center;
      // align-items: center;
      img{
        width: 26%;
        z-index: 1;
        height: 80px;
      }
      div {
        width: 3.5%;
        height: 12%;
        top: 34.5%;
        left: 54.5%;
      }
    }
  }
  

  
  
}
</style>
