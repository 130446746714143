<template>
  <div class="field-editor-container customScrollbar">
    <FormItem
      v-for="(item, index) in curPageInfo.contents.filter((e) => e.type)"
      :key="index"
      :label="item.label"
      :formType="item.type"
      :formData="item"
      :lessonType="lessonType"
      @valueChangeEvent="handleValueChange"
      @selectChangeEvent="handleSyllabusSelcetChange"
      @dialogChangeEvent="handleDialogChange"
      @getPinyinChangeEvent="handleGetPinyinChange"
      @addNewSyllabusItemEvent="handleAddNewItem"
      @deleteItemEvent="handleDeleteItem"
      @uploadMorePicture="handleUploadMorePicture"
      @deletePicture="handleDeletePicture"
      @uploadMirrorMorePicture="handleMirrorUploadMorePicture"
      @deleteMirrorPicture="handleDeleteMirrorPicture"
    />

    <div class="save-btn" v-if="curPageInfo.id">
      <span class="btn save" @click="$emit('saveCoursewareEvent')">SAVE</span>
      <span class="btn" @click="$emit('cancelEditAddEvent')">CANCEL</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: {
      type: Object
    }
  },
  components: {
    FormItem: () => import('./FormItem')
  },
  computed: {
    lessonType() {
      let lessonId = null
      let type = null
      this.curPageInfo.contents.forEach((item) => {
        if (item.fieldName === 'lessonId') {
          lessonId = item.value
        }
      })
      if (lessonId % 8 === 0) {
        type = 4
      } else if (lessonId % 8 === 7) {
        type = 3
      } else if ([1, 3, 5].includes(lessonId % 8)) {
        type = 1
      } else if ([2, 4, 6].includes(lessonId % 8)) {
        type = 2
      }
      // console.log('lessonTpye:', type)
      return type || 0
    }
  },
  created() {
    // console.log(this.pageInfo.contents)
    this.curPageInfo = this.pageInfo
  },
  data() {
    return {
      curPageInfo: {
        contents: [],
        id: null
      }
    }
  },
  watch: {
    pageInfo: {
      handler(newValue) {
        // console.log('newValue', newValue)
        this.curPageInfo = newValue
      },
      deep: true
    }
  },
  methods: {
    handleValueChange(data) {
      // console.log(data, 'data')
      this.$emit('fieldchangeEvent', data)
    },
    handleSyllabusSelcetChange(data) {
      this.$emit('selectFieldchangeEvent', data)
    },
    handleGetPinyinChange(data) {
      this.$emit('getPinyinFieldchangeEvent', data)
    },
    handleDialogChange(data) {
      // console.log(data, '=======================')
      this.$emit('dialogValueChange', data)
    },
    handleDeleteItem(index, topIndex, data) {
      this.$emit('deleteListItem', index, topIndex, data)
    },
    handleUploadMorePicture(data) {
      this.$emit('uploadMorePicture', data)
    },
    handleDeletePicture(data) {
      this.$emit('deletePicture', data)
    },
    handleMirrorUploadMorePicture(data) {
      this.$emit('mirrorUploadMorePicture', data)
    },
    handleDeleteMirrorPicture(data) {
      this.$emit('deleteMirrorPicture', data)
    },
    handleAddNewItem(index, formData) {
      this.$emit('addNewSyllabusItem', index, formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.field-editor-container {
  height: 100%;
  overflow: auto;
  padding-right: 20px;
  .save-btn {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    .btn {
      background: #f1f1f1;
      color: #4d4d4d;
      border-radius: 4px;
      padding: 6px 16px;
      font-size: 12px;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &:hover {
        opacity: 0.7;
      }
    }
    .save {
      color: #fff;
      background: #2472b3;
    }
  }
}
</style>
