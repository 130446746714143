<template>
  <div class="form-item-container">
    <!-- formType:1  input输入框 -->
    <div class="label">{{ label }}</div>
    <el-input
      v-model="simpleValue"
      v-if="formType === 1"
      @input="handleInputChange"
    ></el-input>

    <!-- formType:2 3  上传 -->
    <div class="upload-item" v-if="formType === 2 || formType === 3">
      <span class="file-name" v-if="formData.value">
        <img
          v-if="formType === 3"
          :src="
            formData.value.includes('assets')
              ? require(`@/${formData.value}`)
              : formData.value
          "
          alt=""
        />
        <img
          v-if="formType === 2"
          :src="audioImg"
          alt=""
          @click="handleAudioClick(formData.value)"
          class="audio-icon"
        />
      </span>
      <el-upload
        action=""
        :accept="formType === 2 ? 'audio/*' : 'image/*'"
        :http-request="handleUploadPicture"
        :show-file-list="false"
      >
        <span class="upload-btn">上传</span>
      </el-upload>
    </div>

    <!-- formType:4  radio -->
    <el-radio-group
      v-model="simpleValue"
      v-if="formType === 4"
      @input="handleInputChange"
    >
      <el-radio
        :label="item.value"
        v-for="(item, index) in formData.options"
        :key="index"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>

    <!-- formType:5  syllabus -->
    <div class="syllabus-area" v-if="formType === 5">
      <div class="add-new">
        <i
          class="el-icon-circle-plus-outline"
          @click="handleAddNewSyllabusItem(null)"
        ></i>
      </div>
      <div
        class="syllabus-item"
        v-for="(item, index) in formData.value"
        :key="index + 'level'"
      >
        <div
          class="delete-icon"
          v-if="formData.value && formData.value.length > 1"
        >
          <i class="el-icon-circle-close" @click="handleDeleteNew(index)"></i>
        </div>
        <FormItem
          :label="ele.label"
          :formType="ele.type"
          :formData="ele"
          :lessonType="lessonType"
          v-for="(ele, indexSon) in item"
          :key="indexSon + 'son'"
          :selectIndex="index"
          @valueChangeEvent="handleValueChange"
          @getPinyinEvent="handleGetPinyin"
        />
      </div>
    </div>

    <!-- formType:6  syllabus下拉框 -->
    <el-select
      v-model="simpleValue"
      placeholder="请选择"
      v-if="formType === 6"
      @change="handleInputChange"
    >
      <el-option
        v-for="item in formData.options.filter((e) => e.type === lessonType)"
        :key="item.type + '' + item.value"
        :label="item.value"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <!-- formType: 60 -->
    <el-select
      v-model="simpleValue"
      placeholder="请选择"
      v-if="formType === 60"
      @change="handleInputChange"
    >
      <el-option
        v-for="item in formData.options"
        :key="item.type + '' + item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <!-- formType:61  下拉框 -->
    <el-select
      v-model="simpleValue"
      placeholder="请选择"
      v-if="formType === 61"
      multiple
      filterable
      allow-create
      default-first-option
      @change="handleInputChange"
    >
      <el-option
        v-for="item in formData.options.filter((e) => e.type === lessonType)"
        :key="item.type + '' + item.value"
        :label="item.value"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="simpleValue"
      placeholder="请选择"
      v-if="formType === 62"
      multiple
      filterable
      default-first-option
      @change="handleInputChange"
    >
      <el-option
        v-for="item in formData.options"
        :key="item.type + '' + item.value"
        :label="item.value"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <!-- formType:7  分词结构 -->
    <div v-if="formType === 7" class="syllabus">
      <div class="get-pinyin-area">
        <el-input v-model="formData.value" @change="getGetPinyin"></el-input>
        <span class="btn" @click="getGetPinyin">分词</span>
      </div>
      <div class="word-box">
        <div
          class="word-item"
          v-for="(item, index) in formData.options"
          :key="index"
        >
          <div class="pinyin">
            {{ item.wordPy }}
          </div>
          <div class="hanzi">
            {{ item.wordZh }}
          </div>
        </div>
      </div>
    </div>

    <!-- formType:8  多图片 -->
    <div class="upload-item" v-if="formType === 8">
      <div class="img-list" v-if="formData.value && formData.value.length">
        <span
          v-for="(item, index) in formData.value"
          :key="index"
          class="img-item"
        >
          <img
            :src="
              item.value.includes('assets')
                ? require(`@/${item.value}`)
                : item.value
            "
            v-if="item.value"
            alt=""
          />
          <span>{{ item.label }}</span>
          <i class="el-icon-error" @click="handleDeletePicture(index)"></i>
        </span>
      </div>
      <el-upload
        action=""
        accept="image/*"
        multiple
        :http-request="handleUploadMorePicture"
        :show-file-list="false"
      >
        <span class="upload-btn">上传</span>
      </el-upload>
    </div>

    <!-- formType: 9 gameDialog -->
    <div class="syllabus-area" v-if="formType === 9">
      <div class="add-new">
        <i
          class="el-icon-circle-plus-outline"
          @click="handleAddNewSyllabusItem(null)"
          v-if="formData.fieldName !== 'btnContentList'"
        ></i>
      </div>
      <div
        class="syllabus-item"
        v-for="(item, index) in formData.value"
        :key="index + 'level'"
      >
        <div
          class="delete-icon"
          v-if="formData.value && formData.value.length > 1"
        >
          <i class="el-icon-circle-close" @click="handleDeleteNew(index)"></i>
        </div>
        <FormItem
          :label="ele.label"
          :formType="ele.type"
          :formData="ele"
          :lessonType="lessonType"
          v-for="(ele, indexSon) in item"
          :key="indexSon + 'son'"
          :selectIndex="index"
          @valueChangeEvent="(e) => handleDialogChange(e, selectIndex)"
          @dialogChangeEvent="handleDialogChange"
          @uploadMorePicture="handleUploadMoreImage"
          @getPinyinEvent="handleGetPinyin"
          @deletePicture="handleDeleteMirrorPicture"
          @addNewSyllabusItemEvent="handleAddNewSyllabusItem(index, formData)"
          @deleteItemEvent="(e) => handleDeleteNew(e, index)"
        />
      </div>
    </div>
    <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" v-show="false" />
  </div>
</template>

<script>
import { getPinyinByZh } from '@/assets/js/axios/kidsApi'
import { uploadFileByCourseware } from '@/assets/js/axios/api'
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
export default {
  name: 'FormItem',
  components: {
    AudioPlayer
  },
  props: {
    label: {
      type: String,
      require: true
    },
    formType: {
      type: Number
    },
    formData: {
      require: true
    },
    lessonType: {
      type: Number
    },
    selectIndex: {
      type: Number
    }
  },
  created() {
    if (this.simpleTypeList.includes(this.formType)) {
      this.simpleValue = this.formData.value
    }
  },
  data() {
    return {
      simpleTypeList: [1, 4, 6],
      simpleValue: '',
      audioImg: require('@/assets/img/06-Sound/sound-blue.svg'),
      songUrl: '',
      isStartPlaying: null
    }
  },
  methods: {
    handleInputChange(value) {
      // console.log('handleInputChange', value, this.selectIndex)

      // return
      this.$emit('valueChangeEvent', {
        fieldName: this.formData.fieldName,
        value,
        index: this.selectIndex // 最底层的index
      })
    },

    handleAddNewSyllabusItem(topIndex = null) {
      // 新增一条空的syllabus项
      // console.log(this.formData,'------');
      // console.log(topIndex === null ? '基础数组新增' : '三层数组新增')
      this.$emit('addNewSyllabusItemEvent', topIndex, this.formData)
    },
    handleDeleteNew(index, topIndex = null) {
      // console.log('删除的index', index, topIndex)
      this.$emit('deleteItemEvent', index, topIndex, this.formData)
    },

    handleDialogChange(data, index) {
      // console.log('topIndex', index, this.formData.fieldName)
      let sendData
      // 此处为三层数据渲染逻辑信息，topIndex为最外层index, default基数二层数组数据渲染信息
      switch (this.formData.fieldName) {
        case 'answerList':
          sendData = { ...data, topIndex: index }
          break
        case 'zhList':
          sendData = { ...data, topIndex: index }
          break
        case 'wordList':
          sendData = { ...data, topIndex: index }
          break
        case 'optionList1':
          sendData = { ...data, topIndex: index }
          break
        default:
          sendData = data
          break
      }
      this.$emit(
        `${
          this.formData.type === 9 ? 'dialogChangeEvent' : 'valueChangeEvent'
        }`,
        sendData
      )
    },
    handleValueChange(data) {
      // console.log(data, this.formData)
      this.$emit(
        `${
          this.formData.type === 5 ? 'selectChangeEvent' : 'valueChangeEvent'
        }`,
        data
      )
    },
    /**
     * @description: 分词事件
     * @return {*}
     */
    handleGetPinyin(data) {
      this.$emit('getPinyinChangeEvent', data)
    },
    /**
     * @description: 分词
     * @return {*}
     */
    async getGetPinyin() {
      if (!this.formData.value) return
      const res = await getPinyinByZh({
        content: this.formData.value
      })
      if (res.code === 200) {
        // console.log(res.data)
        this.$emit('getPinyinEvent', {
          options: JSON.parse(res.data),
          index: this.selectIndex
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message || 'server error'
        })
      }
    },

    async handleUploadPicture(obj) {
      // console.log(23,obj);
      let formData = new FormData()
      formData.append('uploadFile', obj.file)
      formData.append('coursewareType', -99)
      const res = await uploadFileByCourseware(formData)
      // console.log(res)
      if (res.code === 200) {
        // console.log(this.formData,'formate');
        // this.$emit('uploadBackground', {
        //   fieldName: this.formData.fieldName,
        //   value: res.data
        // })
        this.$emit('valueChangeEvent', {
          fieldName: this.formData.fieldName,
          value: res.data,
          index: this.selectIndex
        })
        this.formData.value = res.data
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
    },
    async handleUploadMorePicture(obj) {
      let formData = new FormData()
      formData.append('uploadFile', obj.file)
      formData.append('coursewareType', -99)
      const res = await uploadFileByCourseware(formData)
      // console.log(res)
      if (res.code === 200) {
        this.$emit('uploadMorePicture', {
          fieldName: this.formData.fieldName,
          value: res.data,
          index: this.selectIndex
        })
        // console.log(
        //   {
        //     fieldName: this.formData.fieldName,
        //     value: res.data
        //   },
        //   '0-0-0-0'
        // )
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
    },
    handleDeletePicture(index) {
      this.$emit('deletePicture', {
        fieldName: this.formData.fieldName,
        index,
        selectIndex: this.selectIndex
      })
    },
    handleUploadMoreImage(data) {
      // console.log(data, 'data')
      this.$emit('uploadMirrorMorePicture', data)
    },
    handleDeleteMirrorPicture(data) {
      this.$emit('deleteMirrorPicture', data)
    },
    /**
     * @description: 音频点击事件
     * @return {*}
     */
    handleAudioClick(value) {
      this.playAudio(value)
    },
    playAudio(value) {
      this.isStartPlaying = Math.random();
      this.songUrl = value;
    },
  }
}
</script>

<style lang="scss" scoped>
.form-item-container {
  margin-bottom: 15px;

  .label {
    font-size: 14px;
    color: #4d4d4d;
    margin-bottom: 8px;
  }

  .upload-item::v-deep {
    .file-name {
      // font-size: 12px;
      // color: #333;
      img {
        width: 45px;
        height: 45px;
      }
      .audio-icon {
        cursor: pointer;
      }
    }

    .el-upload {
      margin-top: 8px;

      .el-upload-list,
      ul {
        display: none;
      }

      .upload-btn {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        background: #2472b3;
        height: 30px;
        line-height: 32px;
        border-radius: 4px;
        padding: 0 12px;
      }
    }
  }

  .img-list {
    display: flex;
    flex-wrap: wrap;

    .img-item {
      display: flex;
      flex-direction: column;
      position: relative;

      .el-icon-error {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: #6e6e6e;
      }
    }

    img {
      width: 65px;
      height: 65px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .syllabus-area {
    position: relative;

    .add-new {
      position: absolute;
      right: 0;
      top: -27px;
      z-index: 10;

      i {
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &:hover {
          color: #2472b3;
        }
      }
    }

    .syllabus-item {
      position: relative;

      .header-title {
        color: #4d4d4d;
        font-size: 14px;
        // position: absolute;
        // top: 0;
        // left: 0;
      }

      .delete-icon {
        position: absolute;
        right: 0;
        top: 0px;

        i {
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s ease-out;

          &:hover {
            color: #ed7221;
          }
        }
      }
    }
  }

  .syllabus::v-deep {
    .get-pinyin-area {
      display: flex;
      align-items: center;
      gap: 20px;

      .el-input {
        flex: 1;
      }

      .btn {
        padding: 6px 16px;
        color: #fff;
        background: #2472b3;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .word-box {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: flex-end;

      .word-item {
        text-align: center;
        background: #f1f1f0;
        padding: 4px 8px;
        border-radius: 4px;
      }

      .pinyin,
      .hanzi {
        font-size: 14px;
      }
    }
  }

  .el-select {
    width: 80%;
  }
}
</style>
