var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recongnition-stroke-page-container" }, [
    _c("div", { staticClass: "red-background-border" }),
    _c(
      "div",
      { staticClass: "recongnition-stroke-page-box" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
        }),
        _c(
          "div",
          { staticClass: "recongnition-stroke-page-content layout-red-bgc" },
          [
            _c("div", { staticClass: "recognize_container_content_left" }, [
              _c("img", { attrs: { src: _vm.backgroundImage, alt: "" } }),
            ]),
            _c("div", { staticClass: "recognize_container_content_right" }, [
              _c(
                "div",
                { staticClass: "recognize_container_content_right_border" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "recognize_container_content_right_content",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "recognize_container_content_right_content_top",
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticClass: "stroke-image",
                              attrs: { src: _vm.strokeObj.strokeImg, alt: "" },
                            }),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "redColor" }, [
                        _c("p", { staticClass: "font-pinyin-medium pinyin" }, [
                          _vm._v(_vm._s(_vm.strokeObj.pinName)),
                        ]),
                        _c("p", { staticClass: "font-hanzi-medium" }, [
                          _vm._v(_vm._s(_vm.strokeObj.chineseName)),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }