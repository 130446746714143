var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teitter-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c("div", { staticClass: "title-area" }, [
            _c("div", { staticClass: "title" }, [
              _c(
                "div",
                { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                [_vm._v(" " + _vm._s(_vm.gameInfo.titlePinyin) + " ")]
              ),
              _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                _vm._v(" " + _vm._s(_vm.gameInfo.titleHanzi) + " "),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "scratch-area",
              on: { click: _vm.handleShowAnswer },
            },
            [
              _c(
                "div",
                { staticClass: "scratch-img-area" },
                [
                  _vm._l(_vm.gameList, function (item, index) {
                    return [
                      index === _vm.currentIndex - 1
                        ? _c(
                            "div",
                            { key: index, staticClass: "img-item" },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "el-fade-in" } },
                                [
                                  _c("img", {
                                    class: {
                                      hasPointerCursor:
                                        item.bgImg !== item.answerImg,
                                    },
                                    attrs: {
                                      src: !_vm.isShowAnswer
                                        ? item.bgImg
                                        : item.answerImg,
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
          _c("div", { staticClass: "erase-area" }, [
            _c(
              "div",
              { staticClass: "erase-shape" },
              [
                _c("KidsWhiteBoard", {
                  attrs: {
                    displayColorIndex: _vm.currentColorIndex,
                    cursorStyle: true,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }