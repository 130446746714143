var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "mainCont", class: { isFullScreen: _vm.isFullScreen } },
        [
          _c(
            "div",
            { staticClass: "game-content" },
            [
              _c("Star", {
                attrs: {
                  totalStarNumber: _vm.optionList.length,
                  currentIndex: _vm.starNum,
                },
              }),
              _vm.titleInfo
                ? _c("div", { staticClass: "title-area" }, [
                    _c("div", { staticClass: "title" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title-pinyin pinyin font-pinyin-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "title-hanzi font-hanzi-medium" },
                        [_vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "background-img-area",
                  class: "lesson-" + _vm.lessonNum,
                },
                [
                  _c("img", {
                    staticClass: "bgIamge",
                    class: {
                      isCover: _vm.backgroundSize == "cover",
                      fadeOut: _vm.isFadeOut,
                      fadeIn: _vm.isFadeIn,
                    },
                    attrs: { src: _vm.optionList[_vm.currentIndex].image },
                  }),
                  _vm.isHaveAudio
                    ? _c(
                        "div",
                        {
                          staticClass: "speaker-wrapper",
                          on: { click: _vm.playPinyinAudio },
                        },
                        [
                          _c("AudioPlayer", {
                            attrs: {
                              isPlay: _vm.isStartPlaying,
                              audioUrl: _vm.songUrl,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "bottonCont" }, [
                _c("div", { staticClass: "redBackground" }),
                _c(
                  "div",
                  { staticClass: "bottonsWrapper" },
                  _vm._l(_vm.imgList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "content-words-two",
                        class: {
                          bgcolor: !item.nobgcolor,
                          isHidden:
                            _vm.limitNumberOfVisibleOptions &&
                            !(
                              _vm.selectImageVisibility[item.id - 1] ||
                              item.id - 1 == _vm.currentIndex
                            ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.foundAnswer(item.id)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "font-pinyin-medium pinyin" },
                          [_vm._v(_vm._s(item.pinying))]
                        ),
                        _c("span", { staticClass: "font-hanzi-medium" }, [
                          _vm._v(_vm._s(item.hanzi)),
                        ]),
                        item.bottomImg
                          ? _c("img", {
                              attrs: { src: item.bottomImg, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }