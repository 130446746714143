var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speaking-sentence-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c(
            "div",
            {
              staticClass: "title-area",
              class: { hasMultiLine: _vm.titleInfo[1].pinyin },
            },
            [
              _vm.titleInfo[0].pinyin
                ? _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo[0].pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo[0].hanzi) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo[1].pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "title-hanzi font-hanzi-medium" },
                      [_vm._v(" " + _vm._s(_vm.titleInfo[1].hanzi) + " ")]
                    ),
                  ])
                : _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-pinyin pinyin",
                        class: [
                          _vm.titleLength == "long"
                            ? "font-pinyin-small"
                            : "font-pinyin-medium",
                        ],
                      },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "title-hanzi",
                        class: [
                          _vm.titleLength == "long"
                            ? "font-hanzi-small"
                            : "font-hanzi-medium",
                        ],
                      },
                      [_vm._v(" " + _vm._s(_vm.titleInfo.hanzi) + " ")]
                    ),
                  ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "bgImg-area" },
            [
              _vm._l(_vm.gameList, function (item, index) {
                return [
                  index + 1 === _vm.currentIndex
                    ? _c(
                        "div",
                        { key: index, staticClass: "img-box" },
                        [
                          _c("transition", { attrs: { name: "el-fade-in" } }, [
                            _c("img", { attrs: { src: item.bgImg, alt: "" } }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { class: "bubble-area-" + this.currentIndex },
            [
              _vm._l(_vm.gameList, function (item, index) {
                return [
                  _vm.currentIndex === index + 1
                    ? _c("img", {
                        key: index,
                        attrs: {
                          src: _vm.isShowBubble
                            ? item.bubbleImg
                            : _vm.questionBubbleImg,
                          alt: "",
                        },
                        on: {
                          "~click": function ($event) {
                            return _vm.handleClickImg.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }