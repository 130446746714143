var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speaking-by-turntable-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.optionsList.length,
              currentIndex: _vm.showWordsIndex,
            },
          }),
          _c("div", { staticClass: "backGroundImg-area" }, [
            _c("img", { attrs: { src: _vm.gameInfo.bgImg, alt: "" } }),
          ]),
          _vm.gameInfo.titlePinyin
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.gameInfo.titlePinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(" " + _vm._s(_vm.gameInfo.titleHanzi) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "turntable-area" }, [
            _c("div", { staticClass: "turntable-box" }, [
              _c("div", { staticClass: "turntable-img-area" }, [
                _c("img", {
                  staticClass: "turntable-img",
                  attrs: { src: _vm.gameInfo.turntableImg, alt: "" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "needle-area",
                    class: "lesson-" + _vm.lessonNO,
                  },
                  [
                    _c("img", {
                      staticClass: "glow-red",
                      class: "rotateAnimation-" + _vm.clickIndex,
                      attrs: { src: _vm.gameInfo.needleImg, alt: "" },
                      on: { click: _vm.handlerClickNeedle },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "options-area",
                    class: "lesson-" + _vm.lessonNO,
                  },
                  [
                    _vm._l(_vm.optionsList, function (item, index) {
                      return [
                        _vm.showWordsIndex > item.index
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass: "option-item",
                                class: "option-" + index,
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "option-pinyin pinyin" },
                                  [_vm._v(_vm._s(item.pinyin))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "option-hanzi font-hanzi-medium",
                                  },
                                  [_vm._v(_vm._s(item.hanzi))]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }