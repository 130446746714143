<template>
  <div class="train-game-container">
    <div class="train-game-content">
      <Star :totalStarNumber="detailImg.length" :currentIndex="currentStep" />
      <div
        class="right-gules"
        v-if="titleInfo && Object.keys(titleInfo).length"
      >
        <div class="pinyin font-pinyin-medium stroke-pinyin">
          {{ titleInfo.strokePinyin }}
        </div>
        <div class="target-stroke-hanzi">
          {{ titleInfo.strokeHanzi }}
        </div>
      </div>

      <div class="header-img-box" ref="trainBox">
        <!-- 头部灯背景 -->
        <div class="header-img-content">
          <!-- v-show="currentStep === 0" -->
          <img :src="headerBgImg" alt="" />
          <!-- 笔顺部分 -->
          <div class="hanzi-tarin-content" ref="strokeTarinContent">
            <div
              class="typeface-picture"
              v-for="(item, index) in detailImg"
              :key="index + 'hanziStroke'"
              ref="strokeImg"
            >
              <div class="block" @click="clickedStroke(item.index, index)">
                <img :src="item.img" alt="" />
              </div>
              <div>
                <div
                  v-if="shadowImg"
                  :class="[
                    { fadeout: currentCloudIndex > index },
                    Number(componentProps.bgImgAndShadowImg) === 1
                      ? 'cloud'
                      : 'cloud1'
                  ]"
                >
                  <img :src="shadowImg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import Star from '@/components/Course/GamePage/Star'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    Star
  },
  mounted() {
    // this.initData()
    this.$bus.$on(
      'clickRightStrokeArea',
      ({ strokeIndex, hanziIndex, singleStrokeIndex }) => {
        this.clickedStroke(strokeIndex, hanziIndex, singleStrokeIndex, 1)
      }
    )
    this.getScrollWidth()
  },
  beforeDestroy() {
    this.$bus.$off('clickRightStrokeArea')
  },
  watch: {
    clickedStrokeValue: {
      handler(value) {
        const data = {
          clickType: 20013,
          data: { value: this.strokeTrainInfo },
          text: 'FindStrokeTrainGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          detailImg: [],
          titleZh: '',
          titlePy: '',
          // shadowImg: '',
          // defaultImg: ''
          bgImgAndShadowImg: ''
        }
      }
    }
  },
  data() {
    return {
      localData: {
        detailImg: [
          {
            img: require('@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/1-baozi.svg')
          },
          {
            img: require('@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/2-jiaozi.svg')
          },
          {
            img: require('@/assets/img/16-GAMES/G69-culture-train/l2-lesson-02/3-kuaizi.svg')
          }
        ],
        titleInfo: {
          strokePinyin: 'Zhōngguó zǎofàn',
          strokeHanzi: '中国早饭'
        },
        headerBgImg: require('@/assets/img/16-GAMES/G16-dynamo-machine/0-bulb-on.svg'),
        shadowImg: require('@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg'),
        lightIndex: [1, 2, 3]
      },
      detailImg: [],
      titleInfo: {},
      headerBgImg: '',
      // viewShadowImg: '',
      shadowImg: '',
      lightIndex: [],
      scrollDom: null,
      distance: 0,
      currentStep: 0,
      currentLigthIndex: 0,
      instructionsDisplay: true,
      currentCloudIndex: 0,
      strokeTrainInfo: {},
      // success_audio_sound: require('@/assets/audio/correct_answer.mp3'),
      isLastStep: false,
      clickedStrokeValue: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 0
          }
        ]
      }
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps, 'componentProps')
      if (this.componentProps.titlePy || this.componentProps.titleZh) {
        this.titleInfo = {
          strokePinyin: this.componentProps.titlePy,
          strokeHanzi: this.componentProps.titleZh
        }
      }
      if (Number(this.componentProps.bgImgAndShadowImg) === 1) {
        this.shadowImg = ''
        this.headerBgImg = ''
        this.shadowImg =
          'https://static.nihaocafe.com/kb/95e3d66e-357e-47db-9f45-681735c73ffc.svg'
        this.headerBgImg =
          'https://static.nihaocafe.com/kb/a667dcab-7e5f-42db-a02f-e79304d2f8e5.svg'
        // console.log(this.headerBgImg,'222');
      } else if (Number(this.componentProps.bgImgAndShadowImg) === 2) {
        this.shadowImg = ''
        this.headerBgImg = ''
        this.shadowImg =
          'https://static.nihaocafe.com/kb/401e2af0-3607-4a99-93a9-911e0754fc96.svg'
        this.headerBgImg =
          'https://static.nihaocafe.com/kb/6c21b88a-6c3c-4adb-91ec-c5852b07fdf0.svg'
      }
      this.detailImg = []
      // this.headerBgImg = []
      this.lightIndex = []
      if (
        this.componentProps.detailImg &&
        this.componentProps.detailImg.length
      ) {
        this.componentProps.detailImg.forEach((item, index) => {
          this.detailImg.push({
            img: item.value,
            index
          })
          // this.headerBgImg.push(item[0].value)
          this.lightIndex.push(index + 1)
        })
      }
      // if (this.componentProps.defaultImg) {
      //   this.headerBgImg.unshift(this.componentProps.defaultImg)
      // }
    },
    getScrollWidth() {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.trainBox
      })
    },
    clickedStroke(strokeIndex, hanziIndex, singleStrokeIndex, type) {
      // console.log(strokeIndex,hanziIndex,type,this.currentStep,'000');
      // return
      if (hanziIndex !== this.currentStep) return

      if (type != 1) {
        this.clickedStrokeValue++
      }
      // playCorrectSound(true, false);currentCloudIndex
      // let ref = 'stroke_' + strokeIndex
      this.currentStep++
      if (this.lightIndex.includes(this.currentStep)) {
        if (this.currentStep === 1) {
          this.instructionsDisplay = false
        }
        this.trainScroll(hanziIndex)
      }
      this.strokeTrainInfo = { strokeIndex, hanziIndex, singleStrokeIndex }
    },
    // 自动移动
    trainScroll(index) {
      const indexs = this.lightIndex.slice(0, -2)
      const element = this.$refs.strokeImg[index]
      const rect = element.getBoundingClientRect()
      const style = window.getComputedStyle(element)
      const marginLeft = parseFloat(style.marginLeft)
      // console.log(rect, marginLeft)
      const totalWidth = rect.width + marginLeft

      // return
      if (indexs.includes(index) || index === 0) {

        playCorrectSound(true, false)
        this.$nextTick(() => {
          const timer = setInterval(() => {
            this.distance += 2
            this.scrollDom.scrollLeft = this.distance
            if (this.scrollDom.scrollLeft >= totalWidth * index) {
              clearInterval(timer)
              this.currentCloudIndex++
            }
          }, 10)
        })
      } else {
        playCorrectSound()
        startConfetti()
        const timer = setInterval(() => {
            this.distance += 2
            this.scrollDom.scrollLeft = this.distance
            if (this.scrollDom.scrollLeft >= this.scrollDom.scrollWidth - this.scrollDom.clientWidth) {
              clearInterval(timer)
              this.currentCloudIndex++
            }
          }, 10)
        this.isLastStep = true
      }
      // console.log(indexs,index,this.currentCloudIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.train-game-container {
  width: 100%;
  height: 100%;
  position: relative;
  .train-game-content {
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/img/03-Backgrounds/background-practice.svg');
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .right-gules {
      width: auto;
      height: auto;
      padding: 10px 30px 10px 30px;
      background-color: #cd4c3f;
      position: absolute;
      right: 0;
      top: 15%;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      z-index: 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;

      .stroke-pinyin {
        flex: 3;
        display: flex;
        align-items: center;
      }
      .target-stroke-hanzi {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 45px;
      }
    }
    .header-img-box {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      border-radius: 58px;
      position: relative;
      .header-img-content {
        height: 100%;
        width: 100%;
        position: absolute;
        img {
          display: inline-block;
          height: 35%;
          cursor: pointer;
        }
        .hanzi-tarin-content {
          height: 63%;
          display: flex;
          align-items: center;
          width: 100%;
          .typeface-picture {
            // width: 16%;
            width: 30%;
            height: 75%;
            margin-left: 23%;
            background-color: #fff;
            border-radius: 30px;
            position: relative;
            border-right: 10px solid #2258a7;
            border-bottom: 10px solid #2258a7;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            font-family: 'STHeitiSCPinyin-Light';
            .block {
              width: 80%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              img {
                height: 100%;
                min-width: 290px;
              }
              .stroke-block {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 100%;
                }
                .red-one {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .heng-one {
                    position: absolute;
                  }
                  .heng-two {
                    position: absolute;
                  }
                  .heng-three {
                    position: absolute;
                  }
                }
              }
            }
            span {
              color: #cd4c3f;
              margin-top: 2%;
            }
            .cloud {
              width: 100%;
              position: absolute;
              bottom: 15%;
              left: -10%;
              pointer-events: none;
              img {
                width: 120%;
              }
            }
            .cloud1 {
              width: 82%;
              position: absolute;
              bottom: -9%;
              left: 5%;
              pointer-events: none;
              img {
                width: 120%;
              }
            }
            .instructions {
              width: 100%;
              position: absolute;
              bottom: 15%;
              left: 50%;
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }

  .fadeout {
    color: white;
    -webkit-animation: fadeout 0.5s ease-in forwards;
    -moz-animation: fadeout 0.5s ease-in forwards;
    animation: fadeout 0.5s ease-in forwards;
  }
}
</style>
