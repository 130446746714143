<template>
  <div class="game-listen-container">
    <div class="listen-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      />

      <div class="choose-img-area" v-if="isImgPage">
        <div class="img-area">
          <div class="img-content" v-if="allImgList && allImgList.length">
            <span
              v-for="(item, index) in allImgList[currentIndex]"
              :key="index + 'img'"
              class="img-item"
              :class="{
                active: index === chooseImgIndex,
                isCorrect:
                  item.id === answerList[currentIndex] &&
                  index === chooseImgIndex
              }"
            >
              <img
                :src="item.bgImg"
                alt=""
                @click="handleChooseImg(item.id, index)"
              />
            </span>
          </div>
        </div>
        <div class="trumpet-area-right">
          <span class="trumpet-area" @click.stop="playPinyinAudio">
            <AudioPlayer
              :isPlay="isStartPlaying"
              :audioUrl="songUrl"
              :title="
                chooseHanziList[currentIndex] &&
                chooseHanziList[currentIndex].wordsHanzi
              "
            />
          </span>
        </div>
      </div>

      <!-- page  chooose hanzi -->
      <div class="choose-hanzi-area" v-else>
        <div
          class="answer-area"
          v-if="chooseHanziList && chooseHanziList.length"
        >
          <div class="answer-img">
            <img :src="chooseHanziList[currentIndex].bgImg" alt="" />
          </div>
          <div class="answer-hanzi-area" v-if="!isShowAllWords">
            <div
              class="hanzi-item"
              v-for="(item, index) in chooseHanziList[currentIndex]
                .hanziRightList"
              :key="index + 'right'"
              :class="{
                active: insertIndex === index,
                hasInserted: rightHanziIndexArr.indexOf(index + 1) > -1
              }"
            >
              <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>
          <div class="answer-right-hanzi-area" v-else>
            <div class="right-hanzi-box">
              <span class="pinyin font-pinyin-medium">{{
                chooseHanziList[currentIndex].wordsPinyin
              }}</span>
              <span class="font-hanzi-medium">{{
                chooseHanziList[currentIndex].wordsHanzi
              }}</span>
            </div>
          </div>
        </div>

        <div
          class="choose-hanzi-area"
          v-if="chooseHanziList && chooseHanziList.length"
        >
          <div
            class="hanzi-item"
            v-for="(item, index) in chooseHanziList[currentIndex]
              .hanziChooseList"
            :key="index + 'choose'"
            :class="{ dispear: choosedHanziArr.indexOf(index) > -1 }"
            @click="handleChooseHanzi(item.id, index)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from '@/utils/tools'
import AudioPlayer from '@/components/Course/GamePage/AudioPlayer'
import SceneSwitchThumbnails from '../GameComponent/SceneSwitchThumbnails.vue'
import { templatePreviewMixin } from './templatePreviewMixin.js'
export default {
  mixins: [templatePreviewMixin],
  components: {
    SceneSwitchThumbnails,
    AudioPlayer
  },
  props: {
    componentProps: {
      type: Object,
      default: () => {
        return {
          listenChoosePicList: []
        }
      }
    }
  },
  mounted() {
    // this.initData()
    this.$bus.$on('clickChoosedImg', ({ id, index }) => {
      this.handleChooseImg(id, index, true)
    })
    this.$bus.$on('clickChoosedHanzi', ({ id, index }) => {
      this.handleChooseHanzi(id, index, true)
    })
    this.$bus.$on('chooseImgGameClickAudio', () => {
      this.playPinyinAudio('socket', true)
    })
    this.$bus.$on('chooseImgGameClickMenu', ({ index }) => {
      this.changeTheNumbers(index, true)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickChoosedImg')
    this.$bus.$off('clickChoosedHanzi')
    this.$bus.$off('chooseImgGameClickAudio')
    this.$bus.$off('chooseImgGameClickMenu')
  },
  watch: {
    hanziSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003002,
          data: { value },
          text: 'ListenThenChooseWordsGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    imgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003001,
          data: { value },
          text: 'ListenThenChooseWordsGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003003,
          data: { value },
          text: 'ListenThenChooseWordsGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003004,
          data: { value },
          text: 'ListenThenChooseWordsGame点击socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    componentProps: {
      handler(value) {
        const { isTemplatePrview } = value
        if (isTemplatePrview) return
        this.initData()
      },
      deep: true
    }
  },
  computed: {
    imgAllList() {
      let imgArr = []
      for (let i = 1; i <= 16; i++) {
        imgArr.push({
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-${i}.svg`),
          id: i
        })
      }
      return [
        imgArr.slice(0, 4),
        imgArr.slice(4, 8),
        imgArr.slice(8, 12),
        imgArr.slice(12, 16)
      ]
    }
  },
  data() {
    return {
      allImgList: [],
      answerList: [],
      chooseHanziList: [],
      localData: {
        answerList: [2, 7, 12, 15],
        buttonList: [],
        chooseHanziList: [
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-2.svg`),
            wordsPinyin: 'nǐ hǎo',
            wordsHanzi: '你好',
            audioSrc: '',
            hanziRightList: [
              {
                pinyin: 'nǐ',
                hanzi: '你',
                id: 0
              },
              {
                pinyin: 'hǎo',
                hanzi: '好',
                id: 1
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'hǎo',
                hanzi: '好',
                id: 1
              },
              {
                pinyin: 'nǐ',
                hanzi: '你',
                id: 0
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 2
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-7.svg`),
            wordsPinyin: 'zàijiàn',
            wordsHanzi: '再见',
            audioSrc: '',
            hanziRightList: [
              {
                pinyin: 'zài',
                hanzi: '再',
                id: 0
              },
              {
                pinyin: 'jiàn',
                hanzi: '见',
                id: 1
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'zài',
                hanzi: '再',
                id: 0
              },
              {
                pinyin: 'nǐ',
                hanzi: '你',
                id: 2
              },
              {
                pinyin: 'jiàn',
                hanzi: '见',
                id: 1
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-12.svg`),
            wordsPinyin: 'Lǎoshī hǎo',
            wordsHanzi: '老师好',
            audioSrc: '',
            hanziRightList: [
              {
                pinyin: 'lǎo',
                hanzi: '老',
                id: 0
              },
              {
                pinyin: 'shī',
                hanzi: '师',
                id: 1
              },
              {
                pinyin: 'hǎo',
                hanzi: '好',
                id: 2
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'shī',
                hanzi: '师',
                id: 1
              },
              {
                pinyin: 'hǎo',
                hanzi: '好',
                id: 2
              },
              {
                pinyin: 'lǎo',
                hanzi: '老',
                id: 0
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 3
              }
            ]
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-15.svg`),
            wordsPinyin: 'Wǒ shì Dōngdōng.',
            audioSrc: '',
            wordsHanzi: '我是冬冬。',
            hanziRightList: [
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 0
              },
              {
                pinyin: 'shì',
                hanzi: '是',
                id: 1
              },
              {
                pinyin: 'dōng',
                hanzi: '冬',
                id: 2
              },
              {
                pinyin: 'dōng',
                hanzi: '冬',
                id: 2
              }
            ],
            hanziChooseList: [
              {
                pinyin: 'shì',
                hanzi: '是',
                id: 1
              },
              {
                pinyin: 'dōng',
                hanzi: '冬',
                id: 2
              },
              {
                pinyin: 'wǔ',
                hanzi: '五',
                id: 3
              },
              {
                pinyin: 'wǒ',
                hanzi: '我',
                id: 0
              },
              {
                pinyin: 'dōng',
                hanzi: '冬',
                id: 2
              }
            ]
          }
        ],
        allImgList: [
          [
            {
              id: 1,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-1.svg`)
            },
            {
              id: 2,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-2.svg`)
            },
            {
              id: 3,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-3.svg`)
            },
            {
              id: 4,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-4.svg`)
            }
          ],
          [
            {
              id: 5,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-5.svg`)
            },
            {
              id: 6,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-6.svg`)
            },
            {
              id: 7,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-7.svg`)
            },
            {
              id: 8,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-8.svg`)
            }
          ],
          [
            {
              id: 9,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-9.svg`)
            },
            {
              id: 10,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-10.svg`)
            },
            {
              id: 11,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-11.svg`)
            },
            {
              id: 12,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-12.svg`)
            }
          ],
          [
            {
              id: 13,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-13.svg`)
            },
            {
              id: 14,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-14.svg`)
            },
            {
              id: 15,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-15.svg`)
            },
            {
              id: 16,
              bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-16.svg`)
            }
          ]
        ],
        bottomCornerButtonList: {
          settings: {
            showNextOnLastStep: true
          },
          buttons: [
            {
              type: 'look',
              startNumberRange: 0,
              endNumberRange: 100
            }
          ]
        },
        buttonList: [
          {
            image: require('@/assets/img/01-Menu/menu-listen-1.svg'),
            startNumberRange: 0,
            endNumberRange: 0
          },
          {
            image: require('@/assets/img/01-Menu/menu-listen-2.svg'),
            startNumberRange: 1,
            endNumberRange: 1
          },
          {
            image: require('@/assets/img/01-Menu/menu-listen-3.svg'),
            startNumberRange: 2,
            endNumberRange: 2
          },
          {
            image: require('@/assets/img/01-Menu/menu-listen-1.svg'),
            startNumberRange: 3,
            endNumberRange: 3
          }
        ]
      },
      isStartPlaying: null,
      songUrl: null,
      isShowAllWords: false,
      rightHanziIndexArr: [],
      lockIndex: 0,
      choosedHanziArr: [],
      insertIndex: 0,
      chooseImgIndex: null,
      isImgPage: true,
      imgSocketInfo: {},
      hanziSocketInfo: {},
      audioSocketInfo: {},
      clickMenuSocketInfo: {},

      currentIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true
        },
        buttons: [
          {
            type: 'look',
            startNumberRange: 0,
            endNumberRange: 100
          }
        ]
      },
      buttonList: [],
      buttonStyle: '',
      imageList2: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/1.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/2.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/3.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/4.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/5.svg')
        }
      ],
      imageList1: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/blue-white-question.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/blue-red-question.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/blue-yellow-question.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/green-white-question.svg')
        }
      ],
      imageList3: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/green-loudspeaker.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/yellow-loudspeaker.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/white-loudspeaker.svg')
        }
      ],
      imageList4: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/du-blue.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/du-green.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/du-pink.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/du-ricewhite.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/du-white.svg')
        }
      ],
      imageList5: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/jing-blue.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/jing-green.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/jing-pink.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/jing-ricewhite.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/jing-white.svg')
        }
      ],
      imageList6: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/shuo-blue.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/shuo-green.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/shuo-pink.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/shuo-ricewhite.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/shuo-white.svg')
        }
      ],
      imageList7: [
        {
          id: 1,
          image: require('@/assets/img/FixedPicture/ting-blue.svg')
        },
        {
          id: 2,
          image: require('@/assets/img/FixedPicture/ting-green.svg')
        },
        {
          id: 3,
          image: require('@/assets/img/FixedPicture/ting-pink.svg')
        },
        {
          id: 4,
          image: require('@/assets/img/FixedPicture/ting-ricewhite.svg')
        },
        {
          id: 5,
          image: require('@/assets/img/FixedPicture/ting-white.svg')
        }
      ]
    }
  },
  methods: {
    initData() {
      // console.log(this.componentProps.listenChoosePicList, '00000----------')
      this.buttonList = []
      this.chooseHanziList = []
      this.allImgList = []
      const list = []
      const btnNumber = Number(this.componentProps.buttonStyle)
      this.answerList = []
      if (
        this.componentProps.listenChoosePicList &&
        this.componentProps.listenChoosePicList.length
      ) {
        this.componentProps.listenChoosePicList.forEach((item, index) => {
          this.buttonList.push({
            image:
              btnNumber === 1
                ? this.imageList1[index % this.imageList1.length].image
                : btnNumber === 2
                ? this.imageList2[index % this.imageList2.length].image
                : btnNumber === 3
                ? this.imageList3[index % this.imageList3.length].image
                : btnNumber === 4
                ? this.imageList4[index % this.imageList4.length].image
                : btnNumber === 5
                ? this.imageList5[index % this.imageList5.length].image
                : btnNumber === 6
                ? this.imageList6[index % this.imageList6.length].image
                : btnNumber === 7
                ? this.imageList7[index % this.imageList7.length].image
                : require('@/assets/img/16-GAMES/G04-photo-studio/lesson-48/menu-2.svg'),
            startNumberRange: index,
            endNumberRange: index
          })
          list.push(...item[0].value)
          this.chooseHanziList.push({
            bgImg: item[1].value,
            audioSrc: item[2].value,
            wordsPinyin: item[3].value,
            wordsHanzi: item[4].value,
            hanziChooseList:
              item[5].value && item[5].value.length
                ? item[5].value.map((ele, eleIndex) => {
                    return {
                      pinyin: ele[1].value,
                      hanzi: ele[0].value,
                      id: eleIndex
                    }
                  })
                : [],
            hanziRightList:
              item[5].value && item[5].value.length
                ? item[5].value.filter((ele, eleIndex) => ele[2].value === 0)
                : []
          })
        })
        this.chooseHanziList.forEach((item) => {
          item.hanziRightList = item.hanziRightList.map((ele) => {
            return {
              pinyin: ele[1].value,
              hanzi: ele[0].value,
              id: item.hanziChooseList.find((i) => i.hanzi === ele[0].value).id
            }
          })
        })
        this.allImgList = this.componentProps.listenChoosePicList.map(
          (item) => {
            const list = []
            if (item[0].value && item[0].value.length) {
              item[0].value.forEach((ele, eleIndex) => {
                list.push({
                  id: eleIndex,
                  bgImg: ele.value
                })
              })
            }
            return list
          }
        )
        this.allImgList.forEach((item) => {
          if (item && item.length) {
            item.forEach((ele) => {
              ele.id = list.findIndex((i) => i.value === ele.bgImg) + 1
            })
          }
        })
        this.answerList = this.chooseHanziList.map(
          (item) => list.findIndex((i) => i.value === item.bgImg) + 1
        )
      }
    },
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index, 'index')

      this.currentIndex = index
      this.isImgPage = true
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random()
        }
      }
      this.initGame()
    },
    initGame() {
      this.rightHanziIndexArr = []
      this.lockIndex = 0
      this.choosedHanziArr = []
      this.insertIndex = 0
      this.chooseImgIndex = null
      this.isShowAllWords = false
    },
    handleChooseImg(id, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.imgSocketInfo = {
          id,
          index,
          value: Math.random()
        }
      }
      this.$emit('handleCheckImgEvent', id, this.currentIndex)
      this.chooseImgIndex = index
      if (id === this.answerList[this.currentIndex]) {
        playCorrectSound(true, false)
        setTimeout(() => {
          this.isImgPage = false
        }, 1000)
      } else {
        playCorrectSound(false)
      }
    },
    handleChooseHanzi(id, index, isFromSocket = false) {
      // console.log(id, index, isFromSocket)
      if (!isFromSocket) {
        this.hanziSocketInfo = {
          id,
          index,
          value: Math.random()
        }
      }

      if (
        id ===
        this.chooseHanziList[this.currentIndex].hanziRightList[this.lockIndex]
          .id
      ) {
        this.choosedHanziArr.push(index)
        setTimeout(() => {
          this.lockIndex++
          this.insertIndex++

          this.rightHanziIndexArr.push(this.lockIndex)
          this.checkToNext()
          // console.log(this.rightHanziIndexArr)
        }, 10)
      }
    },
    checkToNext() {
      if (
        this.lockIndex >=
        this.chooseHanziList[this.currentIndex].hanziRightList.length
      ) {
        this.isShowAllWords = true
        this.nextPart()
      }
    },
    playPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random()
        }
      }
      this.isStartPlaying = Math.random()
      this.songUrl = this.chooseHanziList[this.currentIndex].audioSrc
    },
    nextPart() {
      setTimeout(() => {
        if (this.currentIndex === this.answerList.length - 1) {
          startConfetti()
          playCorrectSound()
          this.isLastStep = true
        } else {
          playCorrectSound(true, false)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.game-listen-container {
  width: 100%;
  height: 100%;
  .listen-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;

    .choose-img-area {
      height: 100%;
      display: flex;
      align-items: center;
      .img-area {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        .img-content {
          width: 80%;
          height: 70%;
          display: flex;
          flex-wrap: wrap;
          .img-item {
            width: 48%;
            height: 48%;
            margin-right: 2%;
            margin-bottom: 2%;
            display: inline-block;
            box-sizing: border-box;
            border: 10px solid #fff;
            cursor: pointer;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .active {
            border-color: #ce4c3f;
          }
          .isCorrect {
            border-color: #214e96;
          }
        }
      }
      .trumpet-area-right {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        .trumpet-area {
          width: 100px;
          height: 100px;
          background-color: #cd4c3f;
          border-radius: 50%;
          img {
            width: 30%;
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }
    .choose-hanzi-area {
      width: 100%;
      height: 100%;
      .answer-area {
        height: 70%;
        display: flex;
        align-items: center;
        .answer-img {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          img {
            width: 70%;
            border: 10px solid #fff;
            box-sizing: border-box;
            transform: rotate(-10deg);
          }
        }
        .answer-hanzi-area {
          flex: 2;
          height: 40%;
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
          .hanzi-item {
            width: 17%;
            height: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 20px;
            border: 5px dashed #cfc8b0;
            border-radius: 20px;
            color: #f7f4e0;
            background: transparent;
            color: #fff;
            display: flex;
            flex-direction: column;
            padding: 20px 20px;
            span {
              visibility: hidden;
              display: inline-block;
            }
          }
          .active {
            border: 5px dashed #ce4c3f;
          }
          .hasInserted {
            border: none;
            background: #224e96;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px 20px;
            span {
              visibility: visible;
            }
          }
        }
        .answer-right-hanzi-area {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          .right-hanzi-box {
            border-radius: 40px;
            width: 40%;
            background: #224e96;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 5% 15%;
            span {
              display: inline-block;
              color: #fff;
            }
          }
        }
      }
      .choose-hanzi-area {
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        .hanzi-item {
          box-sizing: border-box;
          width: 11%;
          height: 60%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 20px;
          background-color: #cd4c3f;
          border-bottom: 8px solid #ac3d32;
          border-radius: 20px;
          color: #fff;
          justify-content: center;
          align-items: center;

          margin: 0 8px;
          background-color: #ce524c;
          border-bottom: 12px solid #a53d32;
          border-top: 12px solid #d87065;
          border-radius: 30px;
          cursor: pointer;
          span {
            display: inline-block;
          }
        }
        .dispear {
          opacity: 0;
        }
        .hanzi-item:hover {
          background-color: #912016;
          border-top: 8px solid #6e0c03;
          border-bottom: none;
        }
      }
    }
  }
}
</style>
