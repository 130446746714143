var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drag-game layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage, randomClick: _vm.changeOrder },
      }),
      _c("div", { staticClass: "drag-game-content" }, [
        _c("div", { staticClass: "picture-envelope" }, [
          _c(
            "div",
            { staticClass: "content-picture-switching" },
            _vm._l(_vm.envelopeList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lesson-one",
                  class:
                    ({
                      notShow: _vm.showIndex === item.key,
                    },
                    "lesson" + item.key),
                },
                [
                  _c("img", {
                    staticClass: "switchImg",
                    class:
                      ({
                        dispear: item.isDispear,
                        "drag-img-envelope-4": _vm.dragNum === 4,
                      },
                      "switchImg" + item.key),
                    attrs: { src: item.picture, alt: "", draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        $event.stopPropagation()
                        return _vm.drag($event, item)
                      },
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.drop($event)
                      },
                      dragend: _vm.handleDragend,
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "picture-mailbox" }, [
          _c(
            "div",
            { staticClass: "content-picture-switching" },
            _vm._l(_vm.mailboxList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lesson-one",
                  attrs: { draggable: "true" },
                  on: {
                    drop: function ($event) {
                      $event.stopPropagation()
                      return _vm.drop($event, item)
                    },
                    dragover: function ($event) {
                      $event.stopPropagation()
                      return _vm.allowDrop($event)
                    },
                  },
                },
                [
                  _c("img", {
                    class: { "drag-img-mailbox-4": _vm.dragNum === 4 },
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
                      alt: "",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "word", class: "word" + item.value },
                    [
                      _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }