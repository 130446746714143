<template>
  <div class="open-box-to-find-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="background-area">
        <img :src="bgImg" />
        <template v-for="(item, index) in optionList">
          <transition name="el-fade-in" :key="index + 'bg'">
            <img :src="item.bgImg" class="bg-img-item" v-if="item.isShowImg" />
          </transition>
        </template>
      </div>

      <div
        class="click-area"
        v-for="(item, index) in optionList"
        :key="index + 'click'"
        v-show="idList.indexOf(item.id) === -1"
      >
        <div :class="'click-area-' + item.id" @click="handleClickImg(item)" />
      </div>

      <div class="sentence-area" v-if="isShowSentence">
        <div
          class="title"
          v-for="(item, index) in optionList"
          :key="index + 'sentence'"
          v-show="currentId === item.id"
        >
          <div class="pinyin font-pinyin-medium">
            {{ item.centencePinyin }}
          </div>
          <div class="font-hanzi-medium">
            {{ item.sentenceHanzi }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  name: "OpenBoxToFindGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: true,
    },
    optionList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      idList: [],
      currentId: 0,
      isShowMask: false,
      isShowSentence: false,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },

      idSocketInfo: {},
    };
  },
  components: {
    PageButton,
  },
  mounted() {
    this.$bus.$on("clickImgToGetId", ({ item }) => {
      this.handleClickImg(item, true);
    });
  },
  watch: {
    idSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20063,
          data: { value },
          text: "OpenBoxToFindGame点击图片 socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.idSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      this.currentId = item.id;
      this.$emit("showBoxImg", item.id);
      setTimeout(() => {
        this.isShowSentence = true;
        if (item.isTrue) {
          playCorrectSound(true, false);
        } else {
          playCorrectSound(false, false);
        }
      }, 500);
      this.idList.push(item.id);
      if (this.idList.length === this.optionList.length) {
        setTimeout(() => {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound(true);
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.open-box-to-find-game-container {
  background: #fff;
  border-radius: 48px;
  .game-content {
    width: 100%;
    height: 100%;
    // background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .click-area {
      .click-area-1 {
        display: block;
        position: absolute;
        // background-color: black;
        width: 16%;
        height: 31%;
        top: 44%;
        left: 9%;
        z-index: 99;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-2 {
        display: block;
        position: absolute;
        //  background-color: black;
        z-index: 99;
        width: 19%;
        height: 15%;
        top: 44%;
        left: 27%;
        &:hover {
          cursor: pointer;
        }
      }

      .click-area-3 {
        display: block;
        position: absolute;
        //   background-color: black;
        z-index: 99;
        width: 19%;
        height: 14%;
        top: 60%;
        left: 27%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-4 {
        display: block;
        position: absolute;
        //  background-color: black;
        z-index: 99;
        width: 15%;
        height: 30%;
        top: 44%;
        right: 37%;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .title-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 30%;
      height: 17%;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .sentence-area {
      z-index: 10;
      position: absolute;
      left: 30%;
      bottom: 4%;
      width: 40%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      color: #fff;
    }
  }
}
</style>
