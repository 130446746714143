var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pinyin-move-game-container layout-border",
      class: "lesson" + _vm.lessonNum,
    },
    [
      _c(
        "div",
        { staticClass: "move-game-box position-relative" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
          }),
          _c("div", { staticClass: "move-game-area" }, [
            _c(
              "div",
              {
                ref: "moveGameBox",
                staticClass: "move-game-content",
                on: { click: _vm.handleClickBgImg },
              },
              [
                _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
                _vm.frontImg
                  ? _c("img", {
                      staticClass: "second-img",
                      attrs: { src: _vm.frontImg, alt: "" },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    ref: "student",
                    staticClass: "student-img",
                    style: { width: _vm.scrollWidth + "px", height: "100%" },
                  },
                  [
                    _c("div", { staticClass: "object-wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass: "move-wrapper",
                          class: "step-" + _vm.step,
                        },
                        [
                          _c("div", {
                            staticClass: "flame",
                            class: { flameIsVisible: _vm.isMoving },
                          }),
                          _vm.studentImg
                            ? _c("img", {
                                staticClass: "move-block",
                                attrs: { src: _vm.studentImg, alt: "" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.textToShow && _vm.textToShow[_vm.step].pinyin
                  ? _c(
                      "div",
                      {
                        staticClass: "bottom-sentence-area",
                        class: {
                          fadeIn: _vm.fadeInText,
                          isHidden: _vm.textIsHidden,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "hanzi-item",
                            class: { blueBG: _vm.isBlueBackground },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pinyin sentence-hanzi font-pinyin-medium",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.textToShow[_vm.step].pinyin) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "sentence-hanzi font-hanzi-medium",
                              },
                              [_vm._v(_vm._s(_vm.textToShow[_vm.step].hanzi))]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }