var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SELECTCAMERA" },
    [
      _c("NewImg", {
        staticClass: "icon_close",
        attrs: { src: require("@/assets/img/19-Dashboard/icon-plus-blue.png") },
        nativeOn: {
          click: function ($event) {
            return _vm.close()
          },
        },
      }),
      _c("div", { staticClass: "content_video", attrs: { id: "modal_local" } }),
      _c("p", { staticClass: "title font_two fontWeight_medium" }, [
        _vm._v(" Are you sure you want to change your camera? "),
      ]),
      _c(
        "div",
        { staticClass: "font_one fontWeight_regular switchCamera" },
        [
          _vm._v(" Current camera: "),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.showSelectFun($event)
                },
              },
            },
            [_vm._v(_vm._s(_vm.selectCamera.value))]
          ),
          _c("NewSelect2", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSelect,
                expression: "showSelect",
              },
            ],
            ref: "newSelect",
            attrs: { listData: _vm.cameraList },
            on: { selectClick: _vm.selectClick },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "buttonGroup" }, [
        _c(
          "div",
          {
            staticClass: "customButton gray_blue font_two fontWeight_bold",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_vm._v(" CANCEL ")]
        ),
        _c(
          "div",
          {
            staticClass: "customButton blue_white font_two fontWeight_bold",
            on: {
              click: function ($event) {
                return _vm.confirm()
              },
            },
          },
          [_vm._v(" CONFIRM ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }