export function CanvasPaint() {
    this.init = function (canvas, height, width) {
        this.outerParams = {
            rect: {},
            circle: {},
            line: {},
            text: {}
        }
        this.isLine = false,
            this.isRect = false,
            this.isCircle = false,
            this.isText = false,
            this.lock = false; //鼠标是否在被拖动
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d'); //canvas对象

        this.w = width; //画布的宽
        this.h = height; //画布的高

        // this.w = this.canvas.width; //画布的宽
        // this.h = this.canvas.height; //画布的高  	
        this.touch = ("createTouch" in document); //判定是否为手持设备
        this.StartEvent = this.touch ? "touchstart" : "mousedown";
        this.MoveEvent = this.touch ? "touchmove" : "mousemove";
        this.EndEvent = this.touch ? "touchend" : "mouseup";
        this.clickDrag = [];
        this.lineX = [];
        this.lineY = [];

        this.beginPoint = {};
        this.stopPoint = {};
        this.storage = {};
        this.rect = {};

        this.status = {
            lineArr: [],
            circleArr: [],
            rectArr: [],
            textArr: []
        };

        this.nextDrawAry = [],
            this.middleAry = [],
            this.preDrawAry = [],

            // 空绘图表面进栈
            this.middleAry.push(this.ctx.getImageData(
                0,
                0,
                this.w,
                this.h
            ));

        this.bind()
    }
    this.chooseRect = function () {
        this.isLine = false;
        this.isRect = true;
        this.isCircle = false;
        this.isText = false

    }
    this.chooseCircle = function () {
        this.isLine = false;
        this.isRect = false;
        this.isCircle = true;
        this.isText = false

    }
    this.chooseLine = function () {
        this.isLine = true;
        this.isRect = false;
        this.isCircle = false;
        this.isText = false

    }
    this.chooseText = function () {
        this.isLine = false;
        this.isRect = false;
        this.isCircle = false;
        this.isText = true
    }
    this.bind = function () {
        let t = this;
        // console.log(t);
        this.canvas['on' + t.StartEvent] = function (e) {
            const preData = t.ctx.getImageData(
                0,
                0,
                t.w,
                t.h
            );
            // 当前绘图表面进栈
            t.preDrawAry.push(preData);

            let touch = t.touch ? e.touches[0] : e;
            //记录点击的坐标点
            t.lock = true;
            let _x = touch.offsetX;
            let _y = touch.offsetY;
            if (t.isRect) {
                t.rect.x = _x;
                t.rect.y = _y;
                // t.ctx.shadowColor = t.outerParams.rect.color
            } else if (t.isCircle) {
                t.storage.x = _x;
                t.storage.y = _y;
                // t.ctx.shadowColor = t.outerParams.circle.color
            } else if (t.isLine) {
                // t.ctx.shadowColor = t.outerParams.line.color
                t.movePoint(_x, _y);
                t.drawPoint(t.lineX, t.lineY, t.clickDrag, t.outerParams.line.lineWidth, t.outerParams.line.color);
            } else if (t.isText) {
                // 画一个textarea
                t.ctx.font = `${t.outerParams.text.fontSize}px bold 黑体`
                // 设置颜色
                t.ctx.fillStyle = t.outerParams.text.color;
                // 设置水平对齐方式
                t.ctx.textAlign = "center";
                // 设置垂直对齐方式
                t.ctx.textBaseline = "middle";
                // 绘制文字（参数：要写的字，x坐标，y坐标）
                t.ctx.fillText(`${t.outerParams.text.content}`, _x, _y);
                t.status.textArr.push({
                    x: _x,
                    y: _y,
                    textContent: t.outerParams.text.content
                })
            }

        }

        this.canvas['on' + t.MoveEvent] = function (e) {

            if (t.lock) {
                if (t.isRect) {
                    t.rect.width = Math.abs(t.rect.x - e.offsetX)
                    t.rect.height = Math.abs(t.rect.y - e.offsetY)
                    if (t.rect.x > e.offsetX) {
                        t.rect.realX = e.offsetX
                    } else {
                        t.rect.realX = t.rect.x
                    }
                    if (t.rect.y > e.offsetY) {
                        t.rect.realY = e.offsetY
                    } else {
                        t.rect.realY = t.rect.y
                    }
                    t.clear();
                    t.redrawAll();
                    t.drawRect(t.rect.realX, t.rect.realY, t.rect.width, t.rect.height, t.outerParams.rect.radius, t.outerParams.rect.color, t.outerParams.rect.lineWidth);
                } else if (t.isCircle) {
                    let pointX, pointY
                    if (t.storage.x > e.offsetX) {
                        pointX = t.storage.x - Math.abs(t.storage.x - e.offsetX) / 2;
                    } else {
                        pointX = Math.abs(t.storage.x - e.offsetX) / 2 + t.storage.x;
                    }
                    if (t.storage.y > e.offsetY) {
                        pointY = t.storage.y - Math.abs(t.storage.y - e.offsetY) / 2;
                    } else {
                        pointY = Math.abs(t.storage.y - e.offsetY) / 2 + t.storage.y;
                    }
                    let lineX = Math.abs(t.storage.x - e.offsetX) / 2;
                    let lineY = Math.abs(t.storage.y - e.offsetY) / 2
                    t.clear();
                    t.redrawAll();
                    t.drawEllipse(pointX, pointY, lineX, lineY, t.outerParams.circle.lineWidth, t.outerParams.circle.color);
                } else if (t.isLine) {
                    t.movePoint(e.offsetX, e.offsetY, true);
                    t.drawPoint(t.lineX, t.lineY, t.clickDrag, t.lineWidth, t.outerParams.line.color);
                }
            }
        }

        this.canvas['on' + t.EndEvent] = function (e) {
            if (t.isRect) {
                t.status.rectArr.push({
                    realX: t.rect.realX,
                    realY: t.rect.realY,
                    width: t.rect.width,
                    height: t.rect.height,
                    radius: t.outerParams.rect.radius,
                    color: t.outerParams.rect.color,
                    lineWidth: t.outerParams.rect.lineWidth
                });
                t.rect = {};
            } else if (t.isCircle) {
                let pointX, pointY
                if (t.storage.x > e.offsetX) {
                    pointX = t.storage.x - Math.abs(t.storage.x - e.offsetX) / 2;
                } else {
                    pointX = Math.abs(t.storage.x - e.offsetX) / 2 + t.storage.x;
                }
                if (t.storage.y > e.offsetY) {
                    pointY = t.storage.y - Math.abs(t.storage.y - e.offsetY) / 2;
                } else {
                    pointY = Math.abs(t.storage.y - e.offsetY) / 2 + t.storage.y;
                }
                let lineX = Math.abs(t.storage.x - e.offsetX) / 2;
                let lineY = Math.abs(t.storage.y - e.offsetY) / 2;
                t.status.circleArr.push({
                    x: pointX,
                    y: pointY,
                    a: lineX,
                    b: lineY,
                    color: t.outerParams.circle.color,
                    lineWidth: t.outerParams.circle.lineWidth
                });
                t.storage = {};
            } else if (t.isLine) {
                t.status.lineArr.push({
                    x: t.lineX,
                    y: t.lineY,
                    clickDrag: t.clickDrag,
                    lineWidth: t.outerParams.line.lineWidth,
                    color: t.outerParams.line.color
                })
                t.lineX = [];
                t.lineY = [];
                t.clickDrag = [];
                // console.log(t.status.lineArr);
            }

            const preData = t.ctx.getImageData(
                0,
                0,
                t.w,
                t.h
            );
            if (!t.nextDrawAry.length) {
                // 当前绘图表面进栈
                // console.log("无下一张图");
                t.middleAry.push(preData);
            } else {
                // console.log("有下一张图");
                t.middleAry = [];
                t.middleAry = t.middleAry.concat(t.preDrawAry);
                t.middleAry.push(preData);
                t.nextDrawAry = [];
            }


            t.lock = false;
        }
    }
    this.drawRect = function (realX, realY, width, height, radius, color, lineWidth) {
        this.createRect(realX, realY, width, height, radius, color, 'stroke', lineWidth)
    }
    this.movePoint = function (x, y) {
            this.lineX.push(x);
            this.lineY.push(y);
            this.clickDrag.push(y);
        },
        // 画线
        this.drawPoint = function (x, y, clickDrag, lineWidth, color) {

            for (let i = 0; i < x.length; i++) //循环数组
            {
                this.ctx.beginPath();

                if (clickDrag[i] && i) {
                    this.ctx.moveTo(x[i - 1], y[i - 1]);
                } else {
                    this.ctx.moveTo(x[i] - 1, y[i]);
                }
                this.ctx.lineWidth = lineWidth;
                this.ctx.strokeStyle = color;
                this.ctx.shadowBlur = 2
                this.ctx.shadowColor = this.outerParams.line.color
                this.ctx.lineTo(x[i], y[i]);
                this.ctx.closePath();
                this.ctx.stroke();
            }
        },

        // 画矩形
        this.createRect = function (x, y, width, height, radius, color, type, lineWidth) { //绘制圆

            this.ctx.beginPath();
            this.ctx.shadowBlur = 2
            this.ctx.shadowColor = this.outerParams.rect.color
            this.ctx.moveTo(x, y + radius);
            this.ctx.lineTo(x, y + height - radius);
            this.ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
            this.ctx.lineTo(x + width - radius, y + height);
            this.ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
            this.ctx.lineTo(x + width, y + radius);
            this.ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
            this.ctx.lineTo(x + radius, y);
            this.ctx.quadraticCurveTo(x, y, x, y + radius);
            this.ctx[type + 'Style'] = color;
            this.ctx.lineWidth = lineWidth;
            this.ctx.closePath();
            this.ctx[type]();
        },
        // 画圆形
        this.drawEllipse = function (x, y, a, b, lineWidth, color) {

            this.ctx.beginPath();
            this.ctx.shadowBlur = 2
            this.ctx.shadowColor = this.outerParams.circle.color
            this.ctx.ellipse(x, y, a, b, 0, 0, 2 * Math.PI);
            this.ctx.lineWidth = lineWidth;
            this.ctx.fillStyle = 'rgba(0,0,0,0)';
            this.ctx.strokeStyle = color;
            this.ctx.fill();
            this.ctx.stroke();
        },
        this.clear = function () {
            this.ctx.clearRect(0, 0, this.w, this.h); //清除画布，左上角为起点
            // this.status = {
            //     lineArr: [],
            //     circleArr: [],
            //     rectArr: [],
            // };
        }
    this.redrawAll = function () {
        let t = this;
        if (this.status.rectArr.length > 0) {
            this.status.rectArr.forEach(function (val) {
                // t.ctx.shadowBlur = 2
                // t.ctx.shadowColor = t.outerParams.rect.color
                t.drawRect(val.realX, val.realY, val.width, val.height, val.radius, val.color, val.lineWidth)
            })
        }
        if (this.status.circleArr.length > 0) {
            this.status.circleArr.forEach(function (val) {
                // t.ctx.shadowBlur = 2
                // t.ctx.shadowColor = t.outerParams.circle.color
                t.drawEllipse(val.x, val.y, val.a, val.b, val.lineWidth, val.color)
            })

        }
        if (this.status.lineArr.length > 0) {
            this.status.lineArr.forEach(function (val) {
                // t.ctx.shadowBlur = 2
                // t.ctx.shadowColor = t.outerParams.line.color
                t.drawPoint(val.x, val.y, val.clickDrag, val.lineWidth, val.color);
            })
        }
        if (this.status.textArr.length > 0) {
            console.log(this.status.textArr);
            this.status.textArr.forEach(function (val) {
                console.log(val);
                t.ctx.fillText(`${val.textContent}`, val.x, val.y);
            })
        }



    }
    this.animation = function (lineObj) {
        let t = this
        console.log(lineObj);
        if (t.status.lineArr.length) {
            let i = 0
            let timer = setInterval(() => {
                let line = {
                    x: lineObj.x[i],
                    y: lineObj.y[i],
                }
                let nextLine = {
                    x: lineObj.x[i + 1],
                    y: lineObj.y[i + 1],
                }
                t.clear()
                t.ctx.moveTo(line.x, line.y);
                t.ctx.lineTo(nextLine.x, nextLine.y);
                t.ctx.stroke();
                i++;
                if (i > lineObj.clickDrag.length - 1) {
                    clearInterval(timer);
                }
            }, 5);
        }
    }
    this.controlCanvas = function (action) {
        const t = this
        // console.log(this.preDrawAry.length, this.middleAry, this.nextDrawAry, );
        switch (action) {
            case "prev":
                if (t.preDrawAry.length) {
                    // console.log(this.preDrawAry);
                    const popData = t.preDrawAry.pop();
                    const midData = t.middleAry[t.preDrawAry.length + 1];
                    t.nextDrawAry.push(midData);
                    t.ctx.putImageData(popData, 0, 0);
                }
                break;
            case "next":
                if (t.nextDrawAry.length) {
                    // console.log(t.nextDrawAry);
                    const popData = t.nextDrawAry.pop();
                    const midData =
                        t.middleAry[
                            t.middleAry.length - t.nextDrawAry.length - 2
                        ];
                    t.preDrawAry.push(midData);
                    t.ctx.putImageData(popData, 0, 0);
                }
                break;
            case "clear":
                t.ctx.clearRect(0, 0, t.w, t.h);
                t.preDrawAry = [];
                t.nextDrawAry = [];
                t.middleAry = [t.middleAry[0]];
                t.status = {
                    lineArr: [],
                    circleArr: [],
                    rectArr: [],
                };
                break;
        }
    }
}