var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "match-the-cards-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "match-the-cards-game-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c(
            "div",
            { staticClass: "objectsCont" },
            _vm._l(_vm.imgList[_vm.numBers - 1], function (item, index) {
              return _c("div", { key: index, staticClass: "objects" }, [
                _c(
                  "div",
                  {
                    staticClass: "card",
                    class: {
                      isFinishMatchOne: item.type === 1,
                      isFinishMatchTwo: item.type === 2,
                      isFinishMatchThree: item.type === 3,
                      cardIsActive: item.cardActive == true,
                      style1: index % 2 == 0,
                      style2: index % 2 != 0,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.foundAnswer(index, item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "objectImage",
                      attrs: { src: item.image },
                    }),
                  ]
                ),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMatchDisplay === true,
                  expression: "isMatchDisplay === true",
                },
              ],
              staticClass: "matchDisplay",
            },
            [
              _c("div", { staticClass: "objectDisplaycont" }, [
                _c("div", { staticClass: "objects" }, [
                  _c("div", { staticClass: "card style1" }, [
                    _c("img", {
                      staticClass: "objectImage",
                      attrs: { src: _vm.objectDisplayFirst },
                    }),
                  ]),
                  _c("div", { staticClass: "card style2" }, [
                    _c("img", {
                      staticClass: "objectImage",
                      attrs: { src: _vm.objectDisplaySecond },
                    }),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }