var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawing-page-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
        nativeOn: {
          click: function ($event) {
            return _vm.handleWrite.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "page-content", class: "lesson-" + _vm.lessonNo },
        [
          _c(
            "div",
            { staticClass: "img-area", class: "lesson-" + _vm.lessonNo },
            [_c("img", { attrs: { src: _vm.bgImg, alt: "" } })]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }