<template>
  <div class="template-view-container">
    <!-- <keep-alive> -->
    <component
      :is="currentComponent"
      :componentProps="pageInfo.propsInfo"
      v-if="currentComponent"
    ></component>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      templateName: '',
      currentComponent: null
    }
  },
  async mounted() {
    // console.log(this.pageInfo.templateField)
    await this.loadComponent(this.pageInfo.templateField)
  },
  methods: {
    loadComponent(componentName) {
      return new Promise((resolve) => {
        require(['./GameAndPage/' + componentName], (component) => {
          resolve(component.default || component)
        })
      }).then((Component) => {
        this.currentComponent = Component
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template-view-container::v-deep {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .font-pinyin-large,
  .font-pinyin-xlarge {
    font-size: 24px !important;
  }
  .font-hanzi-large,
  .font-hanzi-xlarge {
    font-size: 35px !important;
  }
  .font-english-large {
    font-size: 24px;
  }
  .course-cover-container {
    border-radius: 20px;
  }
  .font28 {
    font-size: 24px !important;
  }
  .course-syllabus-container {
    .font-hanzi-medium {
      font-size: 20px !important;
    }
    .syllabus-content {
      .title {
        height: 40px !important;
        line-height: 40px;
        font-size: 20px !important;
      }
    }
  }
}
</style>
