var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "teitter-game-container",
      on: { click: _vm.handleClickScreen },
    },
    [
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("SceneSwitchThumbnails", {
            key: _vm.key,
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c("div", { staticClass: "back-ground-img-area" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/03-Backgrounds/background-practice.svg"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "phone-area",
                class: { coursePhone: _vm.$route.params.lessonId },
              },
              [
                _c(
                  "div",
                  { staticClass: "phone-detail" },
                  [
                    _c("img", {
                      staticClass: "phone",
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G60-phone/phone1.svg"),
                        alt: "",
                      },
                    }),
                    _c("transition", { attrs: { name: "el-fade-in" } }, [
                      _c("div", { staticClass: "img-detail" }, [
                        [1, 2, 3].indexOf(_vm.currentStepIndex) > -1
                          ? _c("img", {
                              attrs: {
                                src: _vm.gameList[_vm.currentIndex].clickImg,
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _c("div", {
              staticClass: "heart",
              on: {
                click: function ($event) {
                  _vm.isShowHeart = true
                },
              },
            }),
            _vm.isShowHeart
              ? _c("img", {
                  staticClass: "heart",
                  attrs: {
                    src: require("@/assets/img/16-GAMES/G60-phone/heart.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "twitter-area" }, [
            _c(
              "div",
              { staticClass: "twitter-img-area" },
              [
                _vm._l(_vm.gameList, function (item, index) {
                  return [
                    index === _vm.currentIndex
                      ? _c("div", { key: index, staticClass: "img-item" }, [
                          _c("div", { staticClass: "page-title" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-text txt-py font-pinyin-medium",
                              },
                              [_vm._v(_vm._s(item.titlePy))]
                            ),
                            _c(
                              "span",
                              { staticClass: "txt-hz font-hanzi-medium" },
                              [_vm._v(_vm._s(item.titleZh))]
                            ),
                          ]),
                          [2, 3].indexOf(_vm.currentStepIndex) > -1
                            ? _c("div", { staticClass: "text" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-text txt-py font-pinyin-medium1",
                                  },
                                  [_vm._v(_vm._s(item.pinyin))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-hanzi-medium1" },
                                  [_vm._v(_vm._s(item.hanzi))]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "click-area" }, [
            _c("div", {
              staticClass: "heart-shape",
              on: { click: _vm.handleClickHeart },
            }),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }