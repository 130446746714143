var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "syllabus chapter-syllabusone" }, [
    _c("div", { staticClass: "syllabus-box" }, [
      _c("img", {
        staticClass: "bg-img",
        attrs: {
          src: require("@/assets/img/03-Backgrounds/background-syllabus.svg"),
          alt: "",
        },
      }),
      _vm.vowelsToLearn
        ? _c("div", { staticClass: "vowels-to-learn" }, [
            _c("span", { staticClass: "vowels-content" }, [
              _vm._v(" " + _vm._s(_vm.vowelsToLearn) + " "),
            ]),
          ])
        : _vm._e(),
      _vm.isPageA
        ? _c(
            "div",
            { ref: "syllabusBox", staticClass: "syllabus-content" },
            _vm._l(_vm.wordList, function (item, listIndex) {
              return _c(
                "div",
                { key: listIndex + "i-can", staticClass: "i-can-item" },
                [
                  _c("div", { staticClass: "title font-lesson-subtitles" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  !item.isUse
                    ? _c(
                        "div",
                        {
                          staticClass: "content-common",
                          class: { "has-multiple-lines": item.content[0][0] },
                        },
                        _vm._l(item.content, function (ele, index) {
                          return _c(
                            "div",
                            {
                              key: index + "0",
                              staticClass: "word-item",
                              class: { "is-own-line": ele.fullWidth },
                            },
                            [
                              _c("Word", {
                                attrs: { wordsObj: ele, pageType: 1 },
                                on: {
                                  hoverTHisWords: function ($event) {
                                    return _vm.getWordsId(
                                      listIndex,
                                      index,
                                      undefined,
                                      true
                                    )
                                  },
                                  notHoverTHisWords: function ($event) {
                                    return _vm.getWordsId(
                                      listIndex,
                                      index,
                                      undefined,
                                      false
                                    )
                                  },
                                  clickToPlayWords: function ($event) {
                                    return _vm.handlePlay(
                                      listIndex,
                                      index,
                                      undefined
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        {
                          ref: "syllabusBox",
                          refInFor: true,
                          staticClass: "content-use",
                        },
                        _vm._l(
                          item.contentList,
                          function (ele, contentListIndex) {
                            return _c(
                              "div",
                              {
                                key: contentListIndex + "single",
                                staticClass: "word-item",
                              },
                              _vm._l(ele, function (element, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index + "single-one",
                                    staticClass: "use-single",
                                  },
                                  [
                                    _c("Word", {
                                      attrs: { wordsObj: element, pageType: 1 },
                                      on: {
                                        hoverTHisWords: function ($event) {
                                          return _vm.getWordsId(
                                            1,
                                            contentListIndex,
                                            index,
                                            true
                                          )
                                        },
                                        notHoverTHisWords: function ($event) {
                                          return _vm.getWordsId(
                                            1,
                                            contentListIndex,
                                            index,
                                            false
                                          )
                                        },
                                        clickToPlayWords: function ($event) {
                                          return _vm.handlePlay(
                                            1,
                                            contentListIndex,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          }
                        ),
                        0
                      ),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            { ref: "syllabusBox", staticClass: "syllabus-content" },
            _vm._l(_vm.wordList, function (item, listIndex) {
              return _c(
                "div",
                { key: listIndex + "i-can", staticClass: "i-can-item" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  !item.isReview
                    ? _c(
                        "div",
                        { staticClass: "content-common" },
                        _vm._l(item.content, function (ele, index) {
                          return _c(
                            "div",
                            { key: index + "0", staticClass: "word-item" },
                            [
                              _c("Word", {
                                attrs: { wordsObj: ele, pageType: 0 },
                                on: {
                                  hoverTHisWords: function ($event) {
                                    return _vm.getWordsId(
                                      listIndex,
                                      index,
                                      undefined,
                                      true
                                    )
                                  },
                                  notHoverTHisWords: function ($event) {
                                    return _vm.getWordsId(
                                      listIndex,
                                      index,
                                      undefined,
                                      false
                                    )
                                  },
                                  clickToPlayWords: function ($event) {
                                    return _vm.handlePlay(
                                      listIndex,
                                      index,
                                      undefined
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "content-use" },
                        _vm._l(
                          item.contentList,
                          function (ele, contentListIndex) {
                            return _c(
                              "div",
                              {
                                key: contentListIndex + "single",
                                staticClass: "word-item",
                              },
                              _vm._l(ele, function (element, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index + "single-one",
                                    staticClass: "use-single",
                                  },
                                  [
                                    _c("Word", {
                                      attrs: { wordsObj: element, pageType: 0 },
                                      on: {
                                        hoverTHisWords: function ($event) {
                                          return _vm.getWordsId(
                                            0,
                                            contentListIndex,
                                            index,
                                            true
                                          )
                                        },
                                        notHoverTHisWords: function ($event) {
                                          return _vm.getWordsId(
                                            0,
                                            contentListIndex,
                                            index,
                                            false
                                          )
                                        },
                                        clickToPlayWords: function ($event) {
                                          return _vm.handlePlay(
                                            0,
                                            contentListIndex,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          }
                        ),
                        0
                      ),
                ]
              )
            }),
            0
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }